<template>
	<div>
		<div class="alert alert-info media mb-2">
			<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-3" />
			<div class="media-body">
				<b>{{ $t('car-wizard.enter-car-details') }}</b><br/><br/>
				<div class="text-justify">{{ $t('car-wizard.car-details-description') }}</div>
			</div>
		</div>

		<div style="overflow: hidden;" class="d-flex">
			<button @click="navigateBackward()" class="btn btn-outline-secondary">
				<font-awesome-icon icon="chevron-left"/>
				{{ $t('wizard.prev') }}
			</button>

			<button @click="navigateForward" class="btn btn-outline-dark ml-auto mr-0 wizard-next-btn" v-if="nextButtonVisible">
				{{ $t(nextButtonText) }}
				<font-awesome-icon icon="chevron-right" class="ml-1"/>
			</button>
		</div>

		<CarView :msgs="msgs" @onSaved="onSaved()" @loaded="carsLoaded" />
	</div>
</template>

<script>
import CarView from "../CarView"

export default {
	name: "CarsWizardView",
	components: { CarView },
	data() {
		return {
			app: null,
			onBoardingDetails: null,
			routeDestination: '/',
			nextButtonVisible: false,
			nextButtonText: 'wizard.startUsing',
			hasCars: false,
			canSkipCarAddition: true
		}
	},
	props: {
		msgs: Object,
		loggedInUserData: Object,
		onBoardingDetailsProp: Object
	},
	methods: {
		carsLoaded(cars) {
			this.hasCars = cars && cars.length > 0
			this.recalcNextButtonData()
		},
		onSaved() {
			this.recalcNextButtonData()
			setTimeout(() => this.app.scrollUp(), 1000)
		},
		navigateForward() {
			if(this.canSkipCarAddition && !this.hasCars) {
				this.$rest.skipCarAddition()
			}

			if(this.routeDestination === '/') {
				this.app.onWizardFinished()
			}
			else {
				this.$router.push(this.routeDestination)
			}
		},
		navigateBackward() {
			this.$router.go(-1)
		},
		recalcNextButtonData() {
			this.routeDestination = '/'
			this.nextButtonText = 'wizard.startUsing'
			this.nextButtonVisible = false

			if(this.onBoardingDetails) {
				this.nextButtonVisible = this.onBoardingDetails.userHasCar || this.onBoardingDetails.userSetCar || this.hasCars

				if(this.$store.getters.getSettings.wizardCards && !this.onBoardingDetails.userHasCreditCard && !this.onBoardingDetails.creditCardAdditionSkipped) {
					this.routeDestination = '/cards-wizard'
					this.nextButtonText = 'wizard.next'
				}

				if(this.canSkipCarAddition && !this.hasCars && this.$store.getters.getSettings.wizardCarsOptional) {
					this.nextButtonVisible = true
					this.nextButtonText = 'wizard.skipAddCar'
				}
			}
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		this.onBoardingDetails = this.onBoardingDetailsProp
		this.recalcNextButtonData()
	},
	mounted() {
		this.app.keepInWizard = true
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}
}
</script>
