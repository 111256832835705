<template>
	<div class="mainmenu">
		<div class="d-flex logos">
			<div>
				<img alt="Cards" src="@/assets/logo.svg" class="logo charging" />
			</div>
			<div>
				<img alt="Cards" src="@/assets/parking_logo.svg" class="logo parking" />
			</div>
		</div>
		<div class="row mainmenu-cards">
			<div class="col-sm-6 col-lg-3 d-flex">
				<div class="mainmenu-card charging" @click="selectMode('charging')">
					<img alt="Cards" src="@/assets/logo_onlyicon_white.svg" class="img-fluid" />
					<h5>{{$t('mainMenuView.charge')}}</h5>
					<p class="m-0">{{$t('mainMenuView.chargeDesc')}}</p>
				</div>
			</div>
			<div class="col-sm-6 col-lg-3 d-flex">
				<div class="mainmenu-card parking" @click="selectMode('parking')">
					<img alt="Cards" src="@/assets/parking_logo_icon_white.svg" class="img-fluid" />
					<h5>{{$t('mainMenuView.parking')}}</h5>
					<p class="m-0"> {{$t('mainMenuView.parkingDesc')}} </p>
				</div>
			</div>
			<div class="col-sm-6 col-lg-3 d-flex">
				<div class="mainmenu-card both" @click="selectMode('both')">
					<div class="d-flex " style="gap: .5rem;">
						<img alt="Cards" src="@/assets/logo_onlyicon_white.svg" class="img-fluid" />
						<img alt="Cards" src="@/assets/parking_logo_icon_white.svg" class="img-fluid" />
					</div>
					<h5>{{$t('mainMenuView.both')}}</h5>
					<p class="m-0">
						{{$t('mainMenuView.bothDesc')}}
					</p>
				</div>
			</div>
			<div class="col-sm-6 col-lg-3 d-flex">
				<div class="mainmenu-card webshop" @click="selectMode('webshop')">
					<img alt="Cards" src="@/assets/webshop.svg" class="img-fluid" />
					<h5>{{$t('mainMenuView.webshop')}}</h5>
					<p class="m-0">
						{{$t('mainMenuView.webshopDesc')}}
					</p>
				</div>
			</div>
		</div>
		<div class="locale-changer">
			<button class="btn " @click="changeLang('en')" v-if="i18n.locale === 'hu'">
				<img src="@/assets/flags/en.png" class="w-6 " alt=""/>
				English
			</button>
			<button class="btn " @click="changeLang('hu')" v-if="i18n.locale === 'en'">
				<img src="@/assets/flags/hu.png" class="w-6 " alt=""/>
				Magyar
			</button>
		</div>
	</div>
</template>

<style scoped>

.logos{
	margin-top: 1rem;
	margin-bottom: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
	justify-content: center;
	align-items:center;
}

.logos div {
	display: flex;
	justify-content: center;
	align-items: center;
}
.logo.charging {
	height: 80px;
}

.logo.parking {
	height: 60px;
}

@media (max-width: 768px) {
	.logo.charging {
		height: 60px;
	}

	.logo.parking {
		height: 40px;
	}
}

.locale-changer {
	display: flex;
	gap: 1rem;
	justify-content: center;
	margin-top: 1rem;
}

.locale-changer button {
	gap: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	background-color: #f8f9fa;
	border: 1px solid #cacaca;
}

.locale-changer button img {
	width: 1.5rem;
}

.mainmenu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1rem 0;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 99999;
	background: white;
	overflow-y: auto;
}

.mainmenu-cards {
	width: 100%;
	max-width: 1100px;
	justify-content: center;
}

.mainmenu-card {
flex-grow: 1;
margin: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 2rem 1rem;
	border-radius: 0.5rem;
	background-color: #f8f9fa;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	transition: all 0.1s;
	color: #fff;
	text-align: center;
	cursor: pointer;
}

.mainmenu-card:hover{
	scale: 1.02;
}

.mainmenu-card.charging {
	background-color: rgb(8, 182, 71);
}

.mainmenu-card.parking {
	background-color: #0d92bb;
}

.mainmenu-card.both{
	background: rgb(13,146,187);
	background: linear-gradient(45deg, rgba(13,146,187,1) 0%,	rgba(8,182,71,1) 100%);
}

.mainmenu-card.webshop {
	background-color: #262aab;
}

.mainmenu-card img {
	height: 100%;
	max-height: 60px;
	margin-bottom: 1rem;
}


.mainmenu-card h5 {
	font-size: 1.5rem;
	margin-bottom: 0.5rem;
}

.mainmenu-card p {
	font-size: 1rem;
	text-align: center;
}



</style>

<script>
import i18n from "@/i18n"

export default {
	name: 'MainMenuView',
	emits: ['selectMode'],
	computed:{
		i18n() {
			return i18n
		}
	},
	methods: {
		changeLang(lang) {
			this.$i18n.locale = lang
			localStorage.setItem('Language', lang)

			if(this.$route.meta) {
				if(this.$route.meta.title) {
					document.title = this.$t('pageTitle.base') + ' - ' + this.$t(this.$route.meta.title)
				}
				else {
					document.title = this.$t('pageTitle.base')
				}
			}

			if(lang == 'hu') {
				if(this.$route.name == 'privacy-statement') {
					this.$router.replace('/privacy-statement-hu')
				}
				else if(this.$route.name == 'gtc') {
					this.$router.replace('/gtc-hu')
				}
			}
			else {
				if(this.$route.name == 'privacy-statement-hu') {
					this.$router.replace('/privacy-statement')
				}
				else if(this.$route.name == 'gtc-hu') {
					this.$router.replace('/gtc')
				}
			}
		},
		selectMode(mode) {
			if(mode === 'webshop') {
				window.location.href = 'https://freecharger.hu/webshop/'
				return
			}

			const showPois = localStorage.getItem('showPois')
			if(showPois === undefined || showPois === null) {
				localStorage.setItem('showPois', '1')
			}

			if(mode === 'charging') {
				localStorage.setItem('showChargers', '1')
				localStorage.setItem('showGates', '0')
			}

			else if(mode === 'parking') {
				localStorage.setItem('showChargers', '0')
				localStorage.setItem('showGates', '1')
			}

			else if(mode === 'both') {
				localStorage.setItem('showChargers', '1')
				localStorage.setItem('showGates', '1')
			}

			//console.log(mode)
			this.$emit('selectMode', mode)
		}
	},
}
</script>
