<template>
	<div>
		<div>

			<h3>{{ $t('welcome-wizard.welcome-header') }}</h3>
			<p class="text-justify">{{ $t('welcome-wizard.welcome-desc') }}</p>
			<div>{{ $t('welcome-wizard.mandatory-data') }}

				<div class="my-2" v-if="!onBoardingDetails.userHasPhoneNumber">
					<span class="step-number">1</span>
					{{ $t('welcome-wizard.your-phone') }}
				</div>

				<div class="my-2" v-if="!onBoardingDetails.userHasBillingData">
					<span class="step-number">{{ counter2 }}</span>
					{{ $t('welcome-wizard.your-billing-info') }}
				</div>

				<div class="my-2" v-if="!onBoardingDetails.userHasCar && !onBoardingDetails.carAdditionSkipped">
					<span class="step-number">{{ counter3 }}</span>
					{{ $t('welcome-wizard.your-car-info') }} <template v-if="$store.getters.getSettings.wizardCarsOptional">({{ $t('welcome-wizard.optional') }})</template>
				</div>

				<div class="my-2" v-if="$store.getters.getSettings.wizardCards && !onBoardingDetails.userHasCreditCard && !onBoardingDetails.creditCardAdditionSkipped">
					<span class="step-number">{{ counter4 }}</span>
					{{ $t('welcome-wizard.your-credit-card-info') }} ({{ $t('welcome-wizard.optional') }})
				</div>

				<i18n
					path="welcome-wizard.statement-1"
					tag="small"
				>
					<template v-slot:link>
						<router-link  to="privacy-statement-wizard" @click="showPrivacyStatement()">
							{{ $t('welcome-wizard.our-privacy-statement') }}
						</router-link>
					</template>
				</i18n>
				<small class="d-block">{{ $t('welcome-wizard.statement-2') }}</small>
				<small class="d-block">{{ $t('welcome-wizard.statement-3') }}</small>
			</div>
		</div>

		<div class="text-center mx-auto px-2 mt-4">
			<button
				@click="navigateForward()"
				class="btn btn-dark w-100 btn-main text-center"
				style="max-width: 480px;"
			>
				<span style="font-size: 1.5rem">
					<font-awesome-icon icon="chevron-right" :fixed-width="true" class="mr-2"/>
					{{ $t('wizard.start') }}
				</span>
			</button>
		</div>
	</div>
</template>

<script>

export default {
	name: "WelcomeWizardView",
	props: {
		onBoardingDetailsProp: Object
	},
	data() {
		return {
			app: null,
			routeDestination: '/',
			counter2: 2,
			counter3: 3,
			counter4: 4
		}
	},
	methods: {
		navigateForward() {
			if(this.routeDestination === '/') {
				this.app.onWizardFinished()
			}
			else {
				this.$router.push(this.routeDestination)
			}
		},
		recalcNextButtonData() {
			if(!this.onBoardingDetails.userHasPhoneNumber) {
				this.routeDestination = '/profile-wizard'
			}
			else if(!this.onBoardingDetails.userHasBillingData) {
				this.routeDestination = '/billing-wizard'
				this.counter2--
				this.counter3--
				this.counter4--
			}
			else if(!this.onBoardingDetails.userHasCar && !this.onBoardingDetails.carAdditionSkipped) {
				this.routeDestination = '/cars-wizard'
				this.counter3 -= 2
				this.counter4 -= 2
			}
			else if(this.$store.getters.getSettings.wizardCards && !this.onBoardingDetails.userHasCreditCard && !this.onBoardingDetails.creditCardAdditionSkipped) {
				this.routeDestination = '/cards-wizard'
				this.counter4 -= 3
			}
			else {
				this.routeDestination = '/'
			}
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		this.recalcNextButtonData()
	},
	mounted() {
		this.app.keepInWizard = true
	},
	computed: {
		onBoardingDetails() {
			return this.onBoardingDetailsProp
		}
	},
	watch: {
		onBoardingDetails() {
			this.recalcNextButtonData()
		}
	}
}

</script>
