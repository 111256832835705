<template>
	<div class="border mx-auto mt-4" style="border-radius: 10px; max-width: 600px;">
		<h2 class="header-common pt-3">{{ $t('cards.startCardRegistrationButton') }}</h2>
		<div class="alert alert-info" style="border-radius: 0;">
			{{ $t('cards.creditCardAcceptMsg_1') }}
			<a href="#/privacy-statement" target="_blank">{{ $t('cards.creditCardAcceptMsg_link') }}</a>
			{{ $t('cards.creditCardAcceptMsg_2') }}
		</div>
		<div class="text-center">
			<a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
				<img alt="simplepay logo" src="../assets/simplepay/simplepay_bankcard_logos_left_482x40.png" class="img-fluid"/>
			</a>
		</div>
		<div class="form-group buttonRow text-center register-button-row my-0">
			<button @click="startCardRegistration()" class="btn btn-success mb-0">
				<font-awesome-icon icon="plus"/>
				{{ $t('cards.startCardRegistrationButton') }}
			</button>
		</div>
	</div>

</template>

<style>
.register-button-row > button{
	margin-bottom: 10px;
}

.register-button-row {
	padding: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
</style>

<script>

export default {
	name: 'CardRegistrationForm',
	props: {
		wizard: Boolean
	},
	methods: {
		startCardRegistration(){
			this.$rest.registerCreditCard(
				this.wizard,
				url => {
					window.location.href = url
				}
			)
		}
	}
}
</script>
