<template>
	<div>
		<div
			class="alert alert-component"
			:class="{
				'alert-info': message.severity == 'info',
				'alert-warning': message.severity == 'warn',
				'alert-danger': message.severity == 'error'
			}"
			v-for="message in msgs[forComponent]"
			:message="message"
			:key="message.id"
		>
			{{ message.text }}
		</div>
	</div>
</template>

<script>

export default {
	name: 'ComponentMessage',
	props:  {
		msgs: Object,
		forComponent: String
	}
}
</script>

<style>
	.alert.alert-component {
		padding: 3px 8px;
	}
</style>