<template>
	<div class="text-center charge-history-header">
		<h1>{{ $t('invoices.title') }}</h1>
		<div class="filter-container">
			<div class="col-12 form-group">
				<label for="billingDatas">{{ $t('invoices.billingDatas') }}</label>
				<select id="billingDatas" v-model="selectedBillingData" class="form-control">
					<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData" :selected="selectedBillingData && billingData.id == selectedBillingData.id">
						{{ billingData.labelName }}
					</option>
				</select>
				<ComponentMessage :msgs="msgs" forComponent="vehicles"/>
			</div>
		</div>

		<div class="my-5 auto-margin text-center" v-if="selectedBillingData">

			<div v-for="invoice in invoiceList" :key="invoice.id" class="my-4">
				<a
					class="charge-card-container pdf-info"
					:onclick="invoice.path ? '' : 'return false;'"
					:href="invoice.path ? $rest.baseUrl + '/file?type=invoice&name=' + invoice.path + '&token=' + ls.getItem('loggedInUserToken') : 'javascript:void(0)'"
					target="_blank"
				>
					<div class="card ">
						<div class="card-body">
							<div class="card-text">
								<div class="charge-card-text-area text-left">
									<div class="row my-2">
										<div class="col-7"><b>{{ $t('invoices-view.create-date') }}</b></div>
										<div class="col-5"> {{ invoice.createdAt }}</div>
									</div>
								</div>
							</div>
							<div class="card-text">
								<div class="charge-card-text-area text-left">
									<div class="row my-2">
										<div class="col-7"><b>{{ $t('invoices-view.order-date') }}</b></div>
										<div class="col-5"> {{ invoice.invoiceIdentifier }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a>

			</div>

		</div>
	</div>
</template>

<script>

export default {
	name: 'InvoicesView',
	props: {
		msgs: Object
	},
	data() {
		return {
			billingDatas: [],
			selectedBillingData: null,
			label: "",
			invoiceList: [],
			ls: window.localStorage
		}
	},
	watch: {
		selectedBillingData(newBillingData) {
			if(newBillingData) {
				this.getInvoices()
			}
		}
	},
	methods: {
		getInvoices() {
			this.$rest.getInvoicesOfBillingdata(this.selectedBillingData.id, responseData => {
				this.invoiceList = responseData
			})
		},
		getBillingDatasOfUser() {
			this.$rest.listBillingDataForCurrentUser((responseData) => {
				this.billingDatas = this.setBillingLabelName(responseData)
				if(this.billingDatas && this.billingDatas.length > 0) {
					this.selectedBillingData = this.billingDatas[0]
				}
			})
		},
		setBillingLabelName(billingDatas) {
			const billingDatasWithLabelName = []
			billingDatas.forEach(billingData => {
				const labelName = billingData.payerName + ' - ' + billingData.address.full
				billingData = {...billingData, labelName}
				billingDatasWithLabelName.push(billingData)
			})
			return billingDatasWithLabelName
		}
	},
	beforeMount() {
		this.getBillingDatasOfUser()
	}
}
</script>

<style scoped>
.filter-container {
  margin-top: 40px;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.pdf-info {
  display: block;
  text-decoration: none;
  color: #000000;
}
</style>
