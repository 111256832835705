<template>
	<div>

		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="chevron-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h3 class="text-center">{{ $t('openSource.title') }}</h3>

		<div class="text-center">
			<table class="table table-striped mt-4">
				<tbody>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/animateCss-license')">
							Animate.css
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/axios-license')">
							Axios
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/flicking-plugins-license')">
							Flicking plugins
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/font-license')">
							Font Awesome Free
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="openPopup('https://opensource.google/docs/thirdparty/licenses/')">
							Google
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/apache-license')">
							googleapis
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/jquery-license')">
							Jquery
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/apache-license')">
							js-markerclustererplus
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/popper-license')">
							Popper.js
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/url-search-params-polyfill-license')">
							Url search params polyfill
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/vue-license')">
							Vue.js
						</a></td>
					</tr>
					<tr>
						<td><a class="stretched-link" @click="navigateTo('/vuex-license')">
							Vuex
						</a></td>
					</tr>
				</tbody>
			</table>

		</div>
	</div>
</template>

<script>
export default {
	name: "OpenSourceSoftwareView",
	data() {
		return {
			showBackLink: history.length > 1,
			showFontAwesomeLicence: false
		}
	},
	methods: {
		openPopup(link) {
			window.open(link)
		},
		navigateTo(link) {
			this.$router.push(link)
		}
	},
}
</script>

<style scoped>
.table td {
  position: relative;
}
</style>
