<template>
	<div class="alert alert-danger mt-2 mx-1" v-if="priceSettings.parkingEnabled">
		<div class="media align-items-center">
			<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
			<div class="media-body">
				<b>{{ $t('charger-view.parking-info-1') }}</b>
			</div>
		</div>
		<div class="mt-2" v-html="$t('charger-view.parking-info-2', [priceSettings.minutesAfterParkingStart, priceSettings.unitParkingGross])"></div>
		<div class="mt-2" v-html="$t('charger-view.parking-info-3', [priceSettings.parkingToHoldGross])"></div>
	</div>
</template>

<script>
export default {
	name: 'ParkingAlertPanel',
	props: {
		priceSettings: Object
	}
}
</script>

<style scoped>
</style>
