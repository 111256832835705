<template>
	<div class="border mt-3 p-3">

		<div class="alert alert-warning form-group text-center">
			<div>
				{{ $t('casual-charging.messageLogin') }}
			</div>
			<div class="mt-2">
				<router-link to="/login" class="btn btn-success">
					<font-awesome-icon icon="sign-in-alt"/>
					{{ $t('index.continueToLogin') }}
				</router-link>
			</div>
		</div>

		<h3 class="text-center">{{ $t('casual-charging.header') }}</h3>

		<div class="form-group text-center">
			{{ $t('casual-charging.messageProvideData') }}
		</div>

		<div class="form-group text-center">
			<div class="mb-1">
				<small>{{ $t('casual-charging.messagePoints') }}</small>
			</div>
			<label>{{ $t('casualSurvey.wantToCollectPoints') }}</label>
			<div>
				<button @click="setCollectPoints(true)" class="btn btn-success mr-2" :class="{ 'btn-inactive': collectPoints === false }">
					<font-awesome-icon icon="check"/>
					{{ $t('yes') }}
				</button>
				<button @click="setCollectPoints(false)" class="btn btn-danger" :class="{ 'btn-inactive': collectPoints === true }">
					<font-awesome-icon icon="times"/>
					{{ $t('no') }}
				</button>
			</div>
		</div>

		<div class="form-group text-center mt-2" v-if="collectPointsAnswered">
			<div class="mb-1">
				<small>{{ $t('casual-charging.messageInvoice') }}</small>
			</div>
			<label>{{ $t('casualSurvey.wantToGetInvoice') }}</label>
			<div>
				<button @click="setGettingInvoice(true)" class="btn btn-success	mr-2" :class="{ 'btn-inactive': gettingInvoice === false }">
					<font-awesome-icon icon="check"/>
					{{ $t('yes') }}
				</button>
				<button @click="setGettingInvoice(false)" class="btn btn-danger" :class="{ 'btn-inactive': gettingInvoice === true }">
					<font-awesome-icon icon="times"/>
					{{ $t('no') }}
				</button>
			</div>
			<a name="step2"></a>
		</div>

		<div class="form-group text-center mt-2" v-if="collectPointsAnswered && gettingInvoicePointsAnswered">
			<ParkingAlertPanel :priceSettings="priceSettings" />

			<div v-if="!priceSettings.freeCharging">
				<PaymentSummaryCard
					:data="paymentSummary"
					:price-settings="priceSettings"
				/>

				<div class="form-group">
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-1') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-2') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-3') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-4') }}
					</div>
					<div class="blockquote-footer step-list" v-if="priceSettings.convenienceFeeGross > 0">
						<b>{{ $t('charger-view.amount-disclaimer-5', [priceSettings.convenienceFeeGross]) }}</b>
					</div>
					<div class="blockquote-footer step-list">
						<b>{{ $t('charger-view.amount-disclaimer-6', [priceSettings.unitChargingGross]) }}</b>
					</div>
				</div>
			</div>

			<ParkingCodeInput class="my-3" @code="$e => parkCode = $e" v-if="charger.locationHasParking" style="margin-left: -21px; margin-right: -21px;" />

			<label>{{ $t('casualSurvey.addEmailAddress') }}</label>
			<EmailInput
				id="email"
				v-model="email"
				:msgs="msgs"
				class="form-control email-input"
				maxlength="255"
				:placeholder="$t('casualSurvey.email')"
				:required="true"
			/>
			<ComponentMessage :msgs="msgs" forComponent="email" />

			<a name="step3"></a>

			<button @click="sendEmail()" class="btn btn-success mt-3" style="margin: auto">
				<font-awesome-icon icon="play" />
				{{ $t('casual-charging.buttonStart') }}
			</button>
		</div>

	</div>
</template>

<script>
import * as utils from '@/plugins/utils.js'
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue'
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue'
import ParkingCodeInput from "@/components/ParkingCodeInput.vue"

export default {
	name: "CasualChargingSurvey",
	props: {
		msgs: Object,
		priceSettings: Object,
		charger: Object
	},
	data() {
		return {
			app: null,
			collectPointsAnswered: false,
			collectPoints: null,
			gettingInvoicePointsAnswered: false,
			gettingInvoice: null,
			chargerId: null,
			email: null,
			parkCode: '',
			paymentSummary: {
				reservedEnergy: this.calculateKwFromPrice(5000),
				time: null,
				amount: 5000
			}
		}
	},
	components: {
		ParkingCodeInput,
		ParkingAlertPanel,
		PaymentSummaryCard
	},
	methods: {
		onCode(code) {
			this.parkCode = code
		},
		setCollectPoints(wantsToCollect) {
			this.collectPointsAnswered = true
			this.collectPoints = wantsToCollect
			if(wantsToCollect) {
				this.$router.push({ name: 'register', params: { chargerId: this.chargerId } })
			}
			else {
				utils.scrollToAnchor('step2')
			}
		},
		setGettingInvoice(wantToGetInvoice) {
			this.gettingInvoice = wantToGetInvoice
			this.gettingInvoicePointsAnswered = true
			if(wantToGetInvoice) {
				this.$router.push({ name: 'register', arams: { chargerId: this.chargerId } })
			}
			else {
				utils.scrollToAnchor('step3')
			}
		},
		sendEmail() {
			this.$rest.startCasualCharging(
				this.chargerId,
				this.email,
				this.parkCode,
				(responseData) => {
					if(responseData.result) {
						if(responseData.extraData) {
							localStorage.setItem('loggedInUserId', responseData.extraData.data.user.id)
							localStorage.setItem('loggedInEmail', responseData.extraData.data.user.email)
							localStorage.setItem('loggedInUserToken', responseData.extraData.data.token)
							this.app.setLoggedInUserData(responseData.extraData.data.user)
						}
						if(responseData.data) {
							window.location.href = responseData.data
						}
						else {
							this.$router.push("/charge-history")
						}
					}
				},
				(error) => {
					if(error && error.response && error.response.data) {
						this.showErrorMessage(error)
					}
					else {
						alert(this.$t('casual-charging.errorStart'))
					}
				}
			)
		},
		showErrorMessage(error) {
			if(error.response.data.responseCode === 'CHARGER_NOT_ACTIVE') {
				alert(this.$t('charger-view.charger-not-available'))
			}
			else if(error.response.data.responseCode === "SIMPLEPAY_INVALID_PASSWORD") {
				alert(this.$t('charger-view.wrong-secret-code'))
			}
			else if(error.response.data.responseCode === "NO_ACTIVE_TARIFF") {
				alert(this.$t('charger-view.no-tariff'))
			}
			else {
				alert(this.$t('charger-view.unsuccessful-start'))
			}
		},
		calculateKwFromPrice(price) {
			const kw = (price - this.priceSettings.convenienceFeeGross) / this.priceSettings.unitChargingGross
			return kw.toFixed(2)
		},
	},
	created() {
		this.app = this.$root.$children[0]
		this.chargerId = this.$route.params.chargerId
	}
}
</script>

<style scoped>
.btn-inactive {
	opacity: 0.5;
}

.email-input {
	border: 2px solid var(--mode-color);
	background-color: #c2edd2;
	max-width: 320px;
	margin: auto;
}

.email-input::placeholder {
	color: #0b6b2e;
	opacity: 0.75;
}

.email-input:-ms-input-placeholder {
	color: #0b6b2e;
}

.email-input::-ms-input-placeholder {
	color: #0b6b2e;
}
</style>

<style>
.card {
	background: transparent;
}
</style>
