<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="chevron-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1 v-if="!modal">
			Általános szerződési feltételek
		</h1>

		<p>
			A <b>Ready 2 Grow Kft.</b> (a továbbiakban: „<b>Szolgáltató</b>”) <b>FreeCharger</b> elnevezésű okostelefon Alkalmazásban (a továbbiakban: „<b>Alkalmazás</b>”) regisztráló felhasználó (a továbbiakban: „<b>Felhasználó</b>”) valamint a böngészőből elérhető eseti töltést használó (a továbbiakban: „<b>Eseti Felhasználó</b>”) a jelen Általános Szerződési Feltételekben foglaltakat elfogadja, magára nézve kötelezőnek ismeri el.
		</p>

		<p>
			Amennyiben a Felhasználó nem járul hozzá a jelen Általános Szerződési Feltételek (a továbbiakban: „<b>ÁSZF</b>”) Alkalmazásához, úgy az Alkalmazás, a böngészőből elérhető eseti töltés és az azokhoz kapcsolódó töltési és egyéb szolgáltatások nem használhatók és nem vehetők igénybe.
		</p>

		<h2>
			Szolgáltató adatai:
		</h2>

		<p>
			A <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> Weboldal, ahol a böngészőből elérhető eseti töltés elvégezhető, valamint az Alkalmazás tulajdonosa és üzemeltetője a Szolgáltató.
		</p>

		<p>
			Név: Ready 2 Grow Kft.<br/>
			Székhely: 2100 Gödöllő, Remsey krt. 8.<br/>
			Cégjegyzékszám: 13-09-177610<br/>
			Adószám: 25397130-2-13
		</p>

		<p>
			Telefonos Ügyfélszolgálat elérhetősége: <a href="tel:+36203678407">+36 20 367 84 07</a><br/>
			Az Ready 2 Grow Kft. honlapjának címe: <a href="https://r2g.hu" target="_blank">https://r2g.hu</a><br/>
			Az Ready 2 Grow Kft. e-mail címe: <a href="mailto:info@r2g.hu">info@r2g.hu</a>
		</p>

		<p>
			Az eseti töltés weblap címe: <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> (email cím: <a href="mailto:info@freecharger.hu">info@freecharger.hu</a>)
		</p>

		<h2>
			A szolgáltatás leírása
		</h2>

		<h3>
			Általános információk
		</h3>

		<p>
			Jelen ÁSZF kizárólag <b>FreeCharger</b> Weboldal (továbbiakban Weboldal), valamint a <b>FreeCharger Alkalmazás</b> (továbbiakban <b>Alkalmazás</b>) és a Szolgáltató vagy roaming partnerei által üzemeltetett töltők tekintetében alkalmazandó. A jelen ÁSZF elfogadásával és az Alkalmazásban történő regisztrációval, valamint a jelen ÁSZF egyéb vonatkozó rendelkezéseinek figyelembevételével, a Felhasználó a következő szolgáltatásokat veheti igénybe (a továbbiakban: <b>Szolgáltatás</b>):
		</p>

		<p>
			<b>Alkalmazás információs szolgáltatásai</b>: töltőtérkép, fizetési és számlázási adatok, a felhasználó gépjárművének adatai, korábbi töltések adatai, kiállított töltési nyugták és gyűjtőszámlák, töltések után gyűjtött pontegyenleg, pontbeváltási lehetőségek
		</p>

		<p>
			<b>A Szolgáltató vagy roaming megállapodások alapján</b> a Szolgáltató szerződött partnerei által üzemeltetett, a Felhasználók általi forgalom részére megnyitott elektromos gépjármű (a továbbiakban: <b>EV</b>) töltő berendezéseken történő töltés (a továbbiakban <b>Töltés</b>); fizetési, számlázási szolgáltatások. A Szolgáltató vagy roaming partnerei által üzemeltetett töltők listája folyamatosan frissül az Alkalmazás és a Weboldal térképén és a töltők listájában.
		</p>

		<p>
			<b>Eseti Töltés</b>: a Szolgáltató azon EV töltő berendezéseken ahol elektromobilitási szolgáltatást nyújt biztosítja az Alkalmazásban történő regisztráció nélküli úgynevezett eseti töltés elérhetőségét. Amennyiben az Eseti Felhasználó úgy dönt, hogy eseti töltés (a továbbiakban: <b>Eseti Töltés</b>) keretében szeretne töltést igénybe venni, úgy arra a Weboldal (<a href="https://freecharger.hu">https://freecharger.hu</a>) megnyitásával van lehetősége.
		</p>

		<p>
			<b>A töltőpontok jellemzői:</b> A Szolgáltatás alá tartozó - nem roaming partner által üzemeltetett töltőket - azok könnyebb azonosíthatósága érdekében - a Szolgáltató „<b>FreeCharger</b>” arculattal látja el. A berendezésen található egy egyszerű használati útmutató valamint megtalálható a töltő egyedi azonosítását biztosító QR kód illetve (annak bevezetése után) az IDRO azonosító.</p>

		<p>
			A Szolgáltatás az adott elektromos töltőberendezés fizikai elérhetőségét biztosító helyszín nyitvatartási idejéhez igazodva a nyitvatartási idő legalább 90%-ában érhető el, kivéve az elháríthatatlan külső ok esetét (vis-maior).
		</p>

		<p>
			A Szolgáltatás, vagy annak bármely része nyújtására a Szolgáltató, vagy valamely kereskedelmi partnere jogosult. A Szolgáltatást, vagy annak bármely részét a Szolgáltató vagy a kereskedelmi partnere a saját nevében és javára, vagy a saját nevében, de valamely harmadik személy javára is nyújthatja.
		</p>

		<p>
			Szolgáltató fenntartja a jogot, hogy a technikai feltételeket megváltoztassa, amennyiben ez a technikai fejlődés miatt, vagy a Szolgáltatás, vagy a Szolgáltatás biztonsága érdekében a Szolgáltató megítélése szerint szükséges.
		</p>

		<h3>
			A pénzügyi szolgáltatók
		</h3>

		<p>
			A Szolgáltató különböző, tőle független, de vele szerződéses kapcsolatban álló bankokon, pénzügyi szolgáltatókonn, fizetési rendszert üzemeltetőkön, pénzintézeteken, illetve egyéb pénzügyi közvetítőkönn (a továbbiakban: „<b>Pénzügyi Szolgáltatók</b>”) keresztül biztosít fizetési szolgáltatásokat a Szolgáltatás keretén belül a Felhasználó részére.
		</p>

		<p>
			<b>A szolgáltatás használata során igénybe vehető pénzügyi szolgáltatók:</b>
		</p>

		<p>
			<ul>
				<li><b>Simple Applikáció és Rendszer</b>:<br/>
					üzemeltetője, az <b>OTP Mobil Kft.</b> Cg. 01-09-174466; székhely: 1143 Budapest, Hungária krt. 17-19.; a továbbiakban: <b>Simple</b></li>
			</ul>
		</p>

		<p>
			A fizetési szolgáltatások segítségével a Felhasználó fizetéseket tud kezdeményezni a Szolgáltató javára a Felhasználó által igénybe vett Szolgáltatások tekintetében. A Felhasználó ezen fizetési szolgáltatások használatához az adott Pénzügyi Szolgáltatók jelen ÁSZF-től különálló szerződését is, az adott Pénzügyi Szolgáltatók által meghatározottak szerint, el kell, hogy fogadja.
		</p>

		<p>
			A Pénzügyi Szolgáltatók által nyújtott szolgáltatások kizárólag a Felhasználó kényelmét szolgálják és nem tekinthetők a Szolgáltató által képviselt, jóváhagyott vagy támogatott szolgáltatásoknak. Felhasználó a Pénzügyi Szolgáltatók által nyújtott szolgáltatásokat kizárólag a saját belátása szerint, saját felelősségére veheti igénybe. Felhasználó tudomásul veszi, hogy a Pénzügyi Szolgáltatók által nyújtott szolgáltatások használata nem tartozik a jelen ÁSZF hatálya alá. Amennyiben a Felhasználó igénybe vesz ilyen szolgáltatást, abban az esetben a Felhasználó felelőssége áttekinteni, értelmezni és elfogadni a szolgáltatás használati feltételeit és szabályzatait.
		</p>

		<p>
			A Szolgáltató semmilyen felelősséget nem vállal a Pénzügyi Szolgáltatók által nyújtott szolgáltatások használatáért. A Pénzügyi Szolgáltatók által nyújtott szolgáltatások használatára nem vonatkozik a Szolgáltató adatkezelési tájékoztatója sem. A Pénzügyi Szolgáltatók által nyújtott szolgáltatások használatára - beleértve a Szolgáltató honlapján vagy Alkalmazásában található, a Pénzügyi Szolgáltatók által üzemeltetett Weboldalra, felületre irányító hivatkozáson (linken) elérhető szolgáltatásokat is - az adott szolgáltatás saját használati feltételei és adatvédelmi nyilatkozata vonatkozik.
		</p>

		<h2>
			Regisztráció a szolgáltatás igénybevételére
		</h2>

		<h3>
			A Szolgáltató Alkalmazása
		</h3>

		<p>
			A Szolgáltató által biztosított szolgáltatások elérhetők a FreeCharger, iOS és Android operációs rendszerekre fejlesztett applikációján (a használata regisztrációhoz kötött) valamint a FreeCharger weboldalán (<a href="https://freecharger.hu">https://freecharger.hu</a>) keresztül egyaránt. Az Alkalmazást bárki letöltheti, abban bárki regisztrálhat. A gépjármű töltés megkezdésének feltétele, hogy a Felhasználó az Alkalmazásban személyes felhasználói fiókot hozzon létre. A regisztráció egy vagy több számlázási cím rögzítésével lehetséges. A regisztráció díjmentes. A regisztráció megerősítésére e-mailben kerül sor. A Szolgáltató törekszik arra, hogy folyamatos és fennakadás nélküli szolgáltatást nyújtson a Felhasználó részére, de nem tudja garantálni, hogy a Szolgáltatás minden esetben elérhető, folyamatos, időszerű vagy hibátlan lesz. A Felhasználó az Alkalmazásba belépést Google vagy Facebook fiókja segítségével is megteheti.
		</p>

		<h3>
			A Felhasználó és a Szolgáltató között a szerződés
		</h3>

		<ul>
			<li>a Felhasználónak a Szolgáltató Alkalmazásának mobiltelefonra történő letöltésével és az Alkalmazásban történt sikeres regisztrációjával jön létre, vagy</li>
			<li>a Weboldalon kezdeményezett szintén regisztrációhoz kötött Eseti Töltéssel jön létre.</li>
		</ul>

		<h3>
			A szerződéskötés technikai lépései:
		</h3>

		<h4>
			Az Alkalmazásban:
		</h4>

		<ul>
			<li>az Alkalmazás letöltése App Store-ból vagy Google Play áruházból </li>
			<li>az Alkalmazás telepítése</li>
			<li>a főképernyőn a belépés gombra kattintás</li>
			<li>email cím, jelszó megadása és megerősítése. A jelszónak legalább 8 karakterből kell állnia, tartalmaznia kell kis- és nagybetűt valamint számot</li>
			<li>a bejelentkezés a felhasználó már meglevő Facebook vagy Google fiókjával is elérhető, ebben az esetben a regisztráció előző lépése kihagyható</li>
			<li>az adatkezelési tájékoztató és az általános szerződési feltételek letöltése, elolvasása;</li>
			<li>az általános szerződési feltételek és az adatkezelési tájékoztató mellett lévő gomb átkapcsolása azok megértésének és tudomásul vételének megerősítéseként;</li>
		</ul>

		<h4>
			Eseti Töltés esetén:
		</h4>

		<ul>
			<li>a <a href="https://freecharger.hu">https://freecharger.hu</a> weboldal megnyitása</li>
			<li>a töltés indítása gombra kattintás</li>
			<li>a főképernyőn a belépés gombra kattintás</li>
			<li>email cím, jelszó megadása és megerősítése. A jelszónak legalább 8 karakterből kell állnia, tartalmaznia kell kis- és nagybetűt valamint számot</li>
			<li>a bejelentkezés a felhasználó már meglevő Facebook vagy Google fiókjával is elérhető, ebben az esetben a regisztráció előző lépése kihagyható</li>
			<li>az adatkezelési tájékoztató és az általános szerződési feltételek letöltése, elolvasása;</li>
			<li>az általános szerződési feltételek és az adatkezelési tájékoztató mellett lévő gomb átkapcsolása azok megértésének és tudomásul vételének megerősítéseként;</li>
		</ul>

		<p>
			A Felhasználó az online regisztrációval elfogadja, hogy a Szolgáltató online módon biztosítja a jelen ÁSZF-ben meghatározott szolgáltatásokat és azokra vonatkozó bármely információt a Szolgáltató a Felhasználó által megadott regisztrációs e-mail címre továbbítja. Amennyiben a Szolgáltató az ÁSZF módosításáról határoz, a módosítás tartalmát a Felhasználó regisztrált email címére küldi meg vagy az Alkalmazásban jelzi az ÁSZF módosítását, a hatályos verzió elhelyezésével. A Felhasználó a rendszer használatával ÁSZF módosításait is elfogadja.
		</p>

		<p>
			A Szolgáltató fenntartja magának a jogot, hogy a regisztrációt külön indokolás nélkül visszautasítsa, vagy bármikor átmenetileg, vagy véglegesen visszavonja, különösen az alábbi esetekben:
		</p>

		<ul>
			<li>regisztráció során valótlan vagy hiányos adatok megadása esetén.</li>
			<li>korábban regisztrált Felhasználó, akinek szerződését a Szolgáltató szerződésszegés miatt korábban felmondta.</li>
		</ul>

		<p>
			A Felhasználó köteles gondoskodni az általa regisztrált online elérhetőség (e-mail cím) működéséről (fenntartásáról). Helytelen vagy működésképtelen online elérhetőség (e-mail cím) megadásából eredő bármilyen kárért a Szolgáltató nem vállal felelősséget, a Felhasználó a Szolgáltatóval szemben fennálló kötelezettségekért köteles helytállni.
		</p>

		<p>
			A Szolgáltató tájékoztatja és felhívja a Felhasználó figyelmét arra, hogy rendszeresen módosítsa jelszavát, és azt senkinek ne adja át, mások által hozzáférhető helyen ne tárolja. A jelszó biztonságos megőrzése, továbbá az aktuális, a Felhasználó értesítésére alkalmas online elérhetőség (e-mail cím) megadása az Alkalmazás használata esetén is kötelező, és a Felhasználó érdekkörébe tartozó esemény, melyek elmulasztásából fakadó károkért a Szolgáltató nem felel, és melynek elmulasztása nem mentesíti a Felhasználót a Szolgáltató felé keletkezett kötelezettségei teljesítése alól.
		</p>

		<p>
			A Felhasználó köteles az adataiban bekövetkezett változás esetén az alkalmazás illetve a weboldal Profil és Számlázási adatok menüpontjaiban eltárolt adatait módosítani, azokat az adatokat naprakészen tartani.
		</p>

		<h3>
			Elfelejtett jelszó
		</h3>

		<p>
			A Felhasználó az Alkalmazásba történő bejelentkezését megelőzően, az „Elfelejtett jelszó” funkció segítségével új jelszót igényelhet. A rendszer automatikus üzenetet küld a regisztrációkor megadott e-mail címre, mely tartalmaz egy Weboldalra, felületre irányító hivatkozást (linket). Erre a linkre kattintva tud a Felhasználó új jelszót megadni. A sikertelen jelszóváltoztatás esetén a Felhasználó a Szolgáltató Ügyfélszolgálatához, <a href="mailto:info@freecharger.hu">info@freecharger.hu</a> email címen fordulhat segítségért.
		</p>

		<h3>
			Profiladatok
		</h3>

		<p>
			A Felhasználó a Profil menüpontban tudja megváltoztatni a telefonszámát az email címét valamint a belépéshez használt jelszavát. A Profil menüpontban van lehetőség a Profil törlésére is. A profil törlésével felhasználói fiók megszűnik, és minden személyes adat visszavonhatatlanul törlésre kerül. Amennyiben a Felhasználó már töltött és sikeres fizetést kezdeményezett az alkalmazásban vagy a weboldalon keresztül, úgy ezen Felhasználó számlázási adatai a hatályos jogszabályok szerint, adóügyi okokból továbbra is tárolásra kerülnek a jogszabályban előírt határidőig. A törlés után a törölt felhasználói fiókkal már nem lehet bejelentkezni.
		</p>

		<h3>
			Jelszómódosítás
		</h3>

		<p>
			Amennyiben a Felhasználó módosítani kívánja a jelszavát, új jelszó megadására az Alkalmazásban vagy a weboldalon történt belépés után van lehetősége. A sikeres jelszóváltoztatásról a rendszer automatikus visszajelzést ad. A sikertelen jelszóváltoztatás esetén a Felhasználó a Szolgáltató Ügyfélszolgálatához fordulhat segítségért.
		</p>

		<p>
			A regisztráció folyamán a Felhasználó köteles a hozzá köthető, valós adatokat megadni. A Szolgáltató, amennyiben tudomására jut, hogy a Felhasználó által megadott adatok köre nem valós elemeket (is) tartalmaz, jogosult a hozzáférést részlegesen, vagy teljes egészében korlátozni, illetve az Alkalmazás felhasználásából kizárni.
		</p>

		<h2>
			Gépjárműtöltő használatának informatikai és műszaki feltételei, Töltés igénybevétele
		</h2>

		<h3>
			Informatikai feltételek
		</h3>

		<p>
			A Töltés megkezdésére
		</p>

		<ul>
			<li>Eseti Töltés esetén, a töltőberendezésen található QR kód beolvasása, vagy böngészőből a Weboldal megnyitása után kerülhet sor, amelyhez okostelefon és internetkapcsolat egyidejűleg szükséges, vagy</li>
			<li>a Felhasználó azonosítása után kerülhet sor, amelyhez okostelefon, az Alkalmazás, érvényes regisztráció és internetkapcsolat egyidejűleg szükséges</li>
		</ul>

		<h3>
			Gépjármű műszaki feltételek
		</h3>

		<p>
			A Töltés elektromos meghajtásra képes, plug-in hibrid rendszerű vagy teljesen elektromos gépjármű által vehető igénybe. A Felhasználónak rendelkeznie kell műszakilag megfelelő töltőkábellel (amennyiben a kiválasztott töltőoszlop nem rendelkezik beépített töltőkábellel). A töltőoszlopon egy vagy két, egymástól függetlenül használható, Type 2 típusú csatlakozó található. Az alkalmazott töltési mód: Mode 3.
		</p>

		<p>
			A rögzített kábellel rendelkező töltő oszlopokon használt CCS Combo vagy CHAdeMO csatlakozás. Az alkalmazott töltési mód: Mode 4.
		</p>

		<p>
			A Felhasználó köteles meggyőződni arról, hogy a jármű műszaki állapota - különös tekintettel az élet és vagyonvédelmi szempontokra - megfelelő legyen.
		</p>

		<p>
			Csatlakozónként a töltési teljesítmény legfeljebb: gyorstöltő esetén 22 kW, villámtöltő esetén az Alkalmazásban vagy a Weboldalon publikált értékű lehet. A tényleges töltési teljesítmény függ a csatlakoztatott gépjármű és a használt töltőkábel típusától és az akkumulátor hőmérsékletétől és töltöttségétől.
		</p>

		<p>
			A Felhasználó köteles meggyőződni arról, hogy az elektromos jármű megfelel a vonatkozó töltési szabványoknak (IEC 62196, IEC 61851), a töltőkábel rendelkezik gyártói tanúsítvánnyal és sérülésmentes. A nem megfelelő töltőkábel miatt keletkező mindennemű kárért a Felhasználó felelős.
		</p>

		<p>
			A Felhasználó ellenőrizni köteles továbbá, hogy a gépjármű Töltése megfelelő oldalon és megfelelő betűjelen indul-e el, valamint megfelelően befejeződik-e. A töltés elindulását, valamint befejeződését mindig az autó és a töltő közötti kommunikáció határozza meg, amely adott esetben a start/indítás, illetve a stop/leállítás gomb megnyomását követően néhány másodpercet igénybe vehet.
		</p>

		<p>
			A Töltés megkezdéséhez a Felhasználó köteles ellenőrizni a töltő kijelzőjén vagy a töltőn egyéb módon elhelyezett leíráson látható információt. A töltés akkor indul, ha a Felhasználó az azonosítást követően az utasításoknak megfelelően csatlakoztatta a járművet a töltőállomáshoz. A Felhasználó köteles meggyőződni arról, hogy a jármű kompatibilis a töltőállomásokkal és hogy az adott állomáson tölthető.
		</p>

		<h2>
			Felhasználó kötelezettségei
		</h2>

		<p>
			A Felhasználó saját érdekében köteles a regisztrációs jelszavát megőrizni és azt titokban tartani. A Felhasználó köteles haladéktalanul tájékoztatni a Szolgáltatót, ha gyanítja, hogy harmadik személy hozzáférést szerzett vagy megismerte a jelszavát.
		</p>

		<p>
			A töltőhelyen történő parkolásra a vonatkozó közlekedési és magán-, vagy közúthasználati rendelkezések és szabályok irányadóak. Amennyiben a töltőhely a helyi szabályok szerint parkolási díj fizetésre kötelezett területen található, amelyre vonatkozóan a helyi rendeletek illetve a töltőhely területének tulajdonosa vagy bérlője által hozott egyedi szabályozások szerint elektromos gépjárművek számára felmentést nem engednek, a Felhasználó parkolási díjat közvetlenül a parkolási társaság vagy az applikáción keresztül a helyszín tulajdonosa vagy bérlője részére köteles megfizetni. Ennek elmulasztásából eredően Szolgáltató nem tartozik felelősséggel.
		</p>

		<p>
			Azokról a helyszínekről amelyek esetében - jellemzően a töltési folyamat befejezése után - parkolási díjat szükséges megfizetni a Szolgáltató a Felhasználót tájékoztatja még a töltés megkezdése előtt. A parkolási időszak indítása egyedileg szabályozott, jellemzően a töltés befejezését követő 15-30 perc türelmi időszak után történik meg. Ilyen esetekben a türelemi idő lejárta után indul a parkolási időszak, amelynek díja Ft/óra egységben van meghatározva, azonban az elszámolása perc alapú. A töltés befejezése utáni parkolási díj köteles helyszíneken a töltés megkezdése előtt maximált parkolási díj kerül a fizetésre használt bankkártyán befoglalásra, azonban terhelés csak a parkolóhelyen eltöltött idővel arányosan történik, amelynek összegéről a Felhasználó a töltőkábel lecsatlakoztatása után az Alkalmazásban vagy a weboldalon, illetve az elektromobilitási szolgáltatásról szóló számlán vagy nyugtán kap tájékoztatást.
		</p>

		<p>
			A Szolgáltatás informatikai tartalmát szerzői jog, védjegy és/vagy egyéb szellemi tulajdonra vonatkozó oltalmi jogok védik.
		</p>

		<p>
			Az Alkalmazásra, mint számítógépi programalkotásban megnyilvánuló szerzői műre vonatkozó valamennyi személyhez fűződő és vagyoni szerzői jog jogosultja a Szolgáltató. A Szolgáltató a jelen ÁSZF elfogadásával feljogosítja a Felhasználót az Alkalmazás nem kizárólagos, korlátozott használatára.
		</p>

		<p>
			A Felhasználó a használatra kizárólag abban a mértékben jogosult, amely minimálisan elegendő a jelen ÁSZF-ből eredő jogainak érvényesítéséhez és kötelezettségeinek teljesítéséhez.
		</p>

		<p>
			A Felhasználó nem jogosult az Alkalmazásban átdolgozást, módosítást végrehajtani, és nem jogosult azt másolni, többszörözni, értékesíteni, bármely jogcímen átruházni, kódjait visszafejteni.
		</p>

		<p>
			Jogtalan programhasználat esetén a Szolgáltatónak kártérítési igénye keletkezik, és jogosulttá válik a szerzői jogról szóló 1999. évi LXXVI. törvényben meghatározott polgári jogi, valamint a büntetőjogi következmények érvényesítése iránt a megfelelő eljárások megindítására.
		</p>

		<h2>
			Szolgáltató kötelezettségei
		</h2>

		<p>
			Szolgáltató a Felhasználó Töltési tranzakcióit közel valós időben jeleníti meg a Felhasználó felhasználói fiókjában, amelyet a Szolgáltató az Alkalmazásban és a weboldalon tesz elérhetővé a regisztrált Felhasználó részére. Az egyes időszakokra vonatkozó, töltési tranzakció jelentések bármikor megtekinthetők.
		</p>

		<p>
			A Szolgáltató nem felel azokért a károkért, amelyek abból fakadnak, hogy a Felhasználó nem rendeltetésszerűen használja az Alkalmazást, a Szolgáltatást, vagy az ezek igénybevételéhez szükséges eszközöket, illetve nem kellő gondossággal választja ki a megfelelő biztonságos környezetet, amelyben a Szolgáltatásokat igénybe veszi. A Felhasználó számítástechnikai rendszerében (ideértve bármilyen okostelefont is) történő vírus- és egyéb hasonló fertőzésekből eredő károkért a Szolgáltató nem tartozik felelősséggel.
		</p>

		<p>
			A Szolgáltató nem felel a Pénzügyi Szolgáltatók vagy bármely harmadik személy által a szolgáltatásnyújtása körében végzett tevékenység jogszerűségéért és a Felhasználó, a Pénzügyi Szolgáltatók és egyéb harmadik személy között létrejött szerződés, vagy szerződésen kívüli jogviszony teljesítéséért. Kizárólag a Pénzügyi Szolgáltatók vagy egyéb harmadik személy felelős a tevékenysége tekintetében a jogszabályoknak való megfelelésért és egyéb, a tevékenységére vonatkozó szabályoknak, szabványoknak megfelelő tevékenység végzéséért, az ahhoz szükséges hatósági engedélyek beszerzéséért, bejelentések megtételéért.
		</p>

		<p>
			A Szolgáltató nem felelős az olyan károkért, amely a Szolgáltató által el nem hárítható ok, vagy nem a Szolgáltató érdekkörébe tartozó ok miatt következik be, így különösen a távközlési vonalak, az internetes kapcsolat, az adatátviteli rendszerek vagy a postai szolgáltatások hibáiért.
		</p>

		<p>
			A Szolgáltató nem felelős az olyan károkért, amelyek olyan ok miatt következnek be, amelynek az elhárítására, vagy befolyásolására a Szolgáltatónak nincs lehetősége, így különösen az erőhatalomból (vis maior), belföldi vagy külföldi hatóság rendelkezéséből, vagy a Szolgáltató működésének megzavarásából eredő károkért, így abban az esetben sem felel a Szolgáltató az ilyen ok miatt bekövetkező károkért, amennyiben ezek miatt a Szolgáltató ideiglenesen beszünteti vagy korlátozza az Alkalmazást, a Szolgáltatást, vagy a Szolgáltatás elérését, illetőleg nyújtását.
		</p>

		<p>
			A Szolgáltató nem felel a Felhasználó által elszenvedett azon kárért, amely abból ered, hogy a Pénzügyi Szolgáltatók vagy valamely harmadik személy a Szolgáltatás nyújtása, lebonyolítása során hibázik vagy mulasztást követ el. A Felhasználó és a Pénzügyi Szolgáltatók vagy valamely harmadik személy között felmerülő bármely vita esetén a Felhasználó Szolgáltatóval szemben fennálló felelősségét vagy egyéb kötelezettségét nem érinti ezen vita, vagy ellenkövetelés, vagy beszámítási jog, amely jogát a Felhasználó kizárólag a Pénzügyi Szolgáltatókal vagy valamely harmadik személlyel szemben gyakorolhatja.
		</p>

		<p>
			A Szolgáltató nem felelős az olyan eseményekért, körülményekért, károkért és egyéb következményekért, amelynek oka:
		</p>

		<ul>
			<li>a Felhasználó hibája;</li>
			<li>a Felhasználó mobileszközének meghibásodása vagy az Alkalmazás kezelésére való alkalmatlansága;</li>
			<li>a Felhasználó mobileszközén lévő operációs rendszer, illetőleg a Felhasználó által igénybe vett kommunikációs kapcsolat hibája;</li>
			<li>az Alkalmazás rendeltetésellenes vagy egyébként a jelen ÁSZF-be ütköző használata;</li>
			<li>az Alkalmazás által bizonyíthatóan kiküldött automatikus (tájékoztató célú) rendszerüzenetek Felhasználó levelezőrendszerébe történő meg nem érkezése.</li>
		</ul>

		<p>
			A Felhasználó jelszavának biztonságos tárolásáért, illetve az ebből eredő károkért Szolgáltató semmilyen felelősséget nem vállal.
		</p>

		<p>
			A Szolgáltató a fentieken felül az Alkalmazás pontosságáért, megbízhatóságáért, működéséért, teljességéért, adott célra vagy elvárásoknak való megfelelőségéért, alkalmasságáért és az ezek hiányából, az Alkalmazás használatából felmerülő és azokból eredő közvetlen vagy közvetett károkért vagy egyéb jogkövetkezményekért egyéb szavatosságot vagy más kötelezettséget nem vállal. A Szolgáltató nem felel továbbá a rajta kívül álló okkal (például az internetes hálózatban keletkezett technikai meghibásodással vagy áramkimaradással) összefüggésben keletkezett hibákért és ezek következményeiért.
		</p>

		<p>
			A jelen ÁSZF elfogadásával a felek a Ptk. 6:152. §-ban foglaltakra tekintettel megállapodnak, hogy a - szándékosan, továbbá az emberi életben, testi épségben, egészségben okozott károk kivételével - Szolgáltató a Weboldal, valamint az Alkalmazás használata kapcsán a Felhasználónak okozott károkért legfeljebb az eseménnyel érintett Szolgáltatás ellenértékének összege erejéig felel. Felek megállapodnak továbbá, hogy Szolgáltató felelősségét kizárólag a Szerződésben meghatározott tevékenységgel közvetlen összefüggésben keletkezett károkra korlátozzák, így a közvetett, előre nem látható, következményes károkra, valamint harmadik fél által szolgáltatott szoftverek használatával összefüggésben keletkező károkra nem terjed ki.
		</p>

		<h2>
			Panaszkezelés és a Szolgáltató fizetési tranzakció kivizsgálása
		</h2>

		<p>
			Amennyiben a Felhasználó panaszt kíván benyújtani, az érintett vásárlási tranzakció megjelenésétől számított általános elévülési időn belül teheti meg. A panaszban pontosan le kell írni, hogy milyen okból vitatja a tranzakció jellemzőit.
		</p>

		<p>
			A hibás Töltési díjjal kapcsolatos panaszokat a Szolgáltató kezeli és vizsgálja ki. Ha a Szolgáltató a panaszt jogosnak tartja, köteles az érintett összeget késlekedés nélkül megtéríteni a Felhasználó részére. Ha a panasz elutasítására kerül sor, a Szolgáltató köteles a Felhasználót a vizsgálat eredményéről indokolással együtt értesíteni.
		</p>

		<p>
			Amennyiben a Szolgáltató nem tudja a Felhasználó számára kielégítően megoldani a jelzett problémát és a Szolgáltató termékét vagy szolgáltatásait online vásárolta meg:
		</p>

		<p>
			Az Európai Bizottság létrehozott egy online békéltető platformot (online dispute resolution platform vagy ODR platform), amely kifejezetten azt a célt szolgálja, hogy segítsen az Európai Unióban állandó lakhellyel rendelkező vásárlók panaszainak megoldásában, amennyiben a vásárlók nem tudják rendezni nézeteltérésüket azzal az Európai Unióban bejegyzett kereskedővel vagy szolgáltatóval, akitől online vásároltak valamilyen terméket vagy szolgáltatást. A panaszát az Európai Unió bármelyik hivatalos nyelvén benyújthatja az ODR platformon keresztül online.
		</p>

		<p>
			Az ODR platformot a következő webcímen érheti el: <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>
		</p>

		<p>
			A bejelentéshez szükséges a Szolgáltató e-mail címe (<a href="mailto:info@freecharger.hu">info@freecharger.hu</a>), a Weboldalának címe (<a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a>) és a bejegyzett székhelyének címe (2100 Gödöllő, Remsey krt. 8.).
		</p>

		<h2>
			Szolgáltatás díja
		</h2>

		<p>
			A Szolgáltató által üzemeltetett töltők használatának hatályos díjszabása a Szolgáltató honlapján, és az Alkalmazásban ismerhető meg. Szolgáltató fenntartja magának a jogot, hogy - a Felhasználók előzetes, a Szolgáltató honlapján vagy az Alkalmazáson keresztül történő tájékoztatását követően - a Szolgáltatás díját bármikor egyoldalúan módosítsa.
		</p>

		<p>
			Felhasználó kötelessége, hogy a hatályos és alkalmazandó díjszabást - a töltési szolgáltatásról, illetve bizonyos töltési pontokon a parkolási díjról - a Töltés megkezdésekor ellenőrizze.
		</p>

		<h2>
			Fizetés és számlázás
		</h2>

		<p>
			Töltés csak akkor indítható, amennyiben a Felhasználó az applikációban vagy az eseti töltésre szolgáló weboldalon számlázási címet és gépjárművet társított a felhasználói fiókjához.
		</p>

		<p>
			A töltés megkezdésekor a fenti adatok megadása után a Felhasználónak meg kell határoznia, az indítandó töltés maximális idejét vagy a töltésre fordítandó maximális összeget. Ebben a lépésben a Felhasználó megadhatja a töltés kWh-ban számolt mennyiségét, esetlegesen pedig azt, hogy a töltés mikor fejeződjön be.
		</p>

		<p>
			A megadott adatok szerint a rendszer kiszámolja a töltésére felhasználandó maximális összeget, amely összeg a Felhasználó kártyáján befoglalásra kerül. A töltés befejeződése után a kártyaterhelés értéke ez, az ebben a lépésben meghatározott összeg lehet maximálisan.
		</p>

		<p>
			Az elszámolás alapja: Az elektromos gépjármű által felvett töltési teljesítmény
		</p>

		<p>
			A Bankkártyás fizetés elfogadásával a Felhasználó beleegyezik és hozzájárul ahhoz, hogy a Bankkártyáját a Szolgáltató a Töltés befejeztével beterhelje az igénybe vett Szolgáltatás díjával.
		</p>

		<p>
			Amennyiben a Töltés megkezdése előtt a Szolgáltatás díjának foglalása a Felhasználó Bankkártyán sikertelen lesz, úgy a töltési szolgáltatás elindítására nem lesz lehetőség.
		</p>

		<p>
			A Töltés befejeztével a Felhasználó automatikus töltésösszesítő e-mailt kap, illetve korábbi töltéseit a Weboldal és az Alkalmazás „Töltéseim” menüpontjában is ellenőrizheti.
		</p>

		<p>
			A Felhasználó minden hét hétfőjén elektronikus gyűjtőszámlát kap a regisztrált e-mail címére az előző heti időszakban történt Töltésekről (amennyiben történt töltés ).
		</p>

		<p>
			A Felhasználó amennyiben bármilyen technikai problémát tapasztal a Szolgáltatáshoz kapcsolódó fizetés és/vagy számlázás során, azt haladéktalanul, de lehetőség szerint legfeljebb 5 napon belül jelezheti a Szolgáltató felé a probléma orvoslása és a körülmények minél hatékonyabb feltárása érdekében.
		</p>

		<h2>
			Felmondás Felhasználó részéről
		</h2>

		<p>
			A jelen ÁSZF Felhasználó által bármikor, azonnali hatállyal felmondható a regisztráció törlésével egyidejűleg.
		</p>

		<p>
			A regisztráció törlésével egyidejűleg a Felhasználó Alkalmazás profilja automatikusan törlésre kerül. A már teljesült Töltési tranzakciók nyugtáit és számláit Szolgáltató az általános elévülési idő leteltéig jogosult tárolni.
		</p>

		<h2>
			Felmondás a Szolgáltató részéről
		</h2>

		<p>
			A Szolgáltató jogosult indokolási kötelezettség mellett azonnali hatállyal felmondani a jelen megállapodást, ha a Felhasználó (i) felszólítás ellenére sem teljesíti a fizetési kötelezettségeit vagy (ii) a jelen megállapodásba ütköző módon használja a Szolgáltatást. A Szolgáltató akkor is felmondhatja a jelen megállapodást, ha a Szolgáltató alapos okkal gyanítja, hogy a Felhasználó nem megfelelően használja a Szolgáltatást.
		</p>

		<p>
			A Szolgáltató rendes felmondással, egy (1) hónapos felmondási idővel felmondhatja a jelen megállapodást, amely a fennálló előfizetés lejártakor lép hatályba (amennyiben alkalmazandó).
		</p>

		<h2>
			Személyes adatok kezelése, biztonság
		</h2>

		<p>
			A Szolgáltató jogosult a Felhasználó által az Eseti Töltés során vagy az Alkalmazásban történő regisztráció, vagy azt követően bármikor történt módosítás során megadott személyes adatokat tárolni, feldolgozni, és a Szolgáltatással kapcsolatban felhasználni a Szolgáltató Adatkezelési tájékoztatójában meghatározottak szerint. A Felhasználó köteles minden tőle elvárhatót megtenni személyes adatainak, különösen a Szolgáltató által nyújtott szolgáltatások igénybevételéhez a Felhasználónév, e-mail cím, illetve mindkét online felület esetén a jelszó védelme érdekében. A Felhasználó tartozik felelősséggel minden olyan eseményért, tevékenységért, mulasztásért, mely Felhasználónevének, illetve jelszavának felhasználásával valósult meg.
		</p>

		<p>
			Az adatkapcsolat biztonságáért a Felhasználó által az Alkalmazás csatlakozáshoz használt mobiltelefonja és a Szolgáltató szervere között a böngészők által nyújtott standard SSL (Secure Socket Layer) technológia felel. A Felhasználó által használt Alkalmazás az SSL segítségével a Felhasználó adatait az elküldés előtt titkosítja. Így azok kódolt formában jutnak el a Szolgáltatóhoz, ezáltal illetéktelen személyek számára nem értelmezhetők.
		</p>

		<p>
			Felhasználó a jelen ÁSZF elfogadásával tudomásul veszi, hogy az Ready 2 Grow Kft. (székhelye: 2100 Gödöllő, Remsey krt. 8.) adatkezelő által a <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> illetőleg a <b>FreeCharger</b> elnevezésű okostelefon Alkalmazása felhasználói adatbázisában tárolt alábbi személyes adatai átadásra kerülnek a KBOSS.hu Kft. (1031 Budapest, Záhony utca 7.), mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: email cím és számlázási adatok.
		</p>

		<p>
			Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a szamlazz.hu Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg: <a href="https://www.szamlazz.hu/adatvedelem/" target="_blank">https://www.szamlazz.hu/adatvedelem/</a>
		</p>

		<p>
			Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, az OTP Mobil Szolgáltató Kft. üzemeltetésében levő SimplePay által biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Felhasználó (vásárló) által a regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül. Az ismétlődő fizetés ún. „eseti hozzájárulásos” típusa minden tranzakció esetében a Felhasználó (vevő) eseti jóváhagyásával történik, tehát, a Felhasználó (vevő) valamennyi jövőbeni fizetésnél jóvá kell, hogy hagyja a tranzakciót.
		</p>

		<p>
			A sikeres fizetés tényéről a Felhasználó (vevő) minden esetben a hagyományos bankkártyás fizetéssel megegyező csatornákon keresztül értesítést kap.
		</p>

		<p>
			<b>Figyelem(!):</b> a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően történik. A bankkártyaadatokhoz a Szolgáltató nem fér hozzá.
		</p>

		<p>
			A Szolgáltató által tévesen vagy jogtalanul kezdeményezett Ismétlődő fizetéses tranzakciókért közvetlenül a Szolgáltató felel, Szolgáltató fizetési szolgáltatójával (Simplepay) szemben bármilyen igényérvényesítés kizárt.
		</p>

		<p>
			Felhasználó a rendszer használatának megkezdése után kijelenti, hogy a jelen pont szerinti tájékoztatót elolvasta, annak tartalmát tudomásul veszi és elfogadja.
		</p>

		<h2>
			Egyéb rendelkezések
		</h2>

		<p>
			A jelen szerződés a Szolgáltató és a Felhasználó között létrejött, írásba foglalt szerződésnek minősül. A Szolgáltató rögzíti a jelen szerződést, a jelen szerződés és esetleges módosításai a későbbiekben is hozzáférhetőek a Szolgáltató honlapján. A jelen szerződés hivatalos nyelve a magyar. Jelen ÁSZF-et Szolgáltató jogosult egyoldalúan módosítani. A módosítást a Szolgáltató köteles annak hatályba lépését megelőző 15 nappal a honlapján közzétenni. Az ÁSZF mindenkor hatályos verzióját a Szolgáltató honlapján teszi közzé.
		</p>

		<p>
			14.2. A jelen ÁSZF bármely rendelkezésének érvénytelensége nem érinti az egyéb rendelkezéseinek érvényességét és hatályát, a részleges érvénytelensége esetén a szerződés többi része változatlan formában hatályban marad.
		</p>

		<p>
			14.3. A jelen ÁSZF-ben nem szabályozott kérdésekben Magyarország jogszabályai, különösen a polgári törvénykönyvről szóló 2013. évi V. törvény, az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény, valamint a gépjárműtöltésre vonatkozó hatályos jogszabályok - így különösen a közúti közlekedésről szóló 1988. évi I. törvény, valamint az elektromobilitás szolgáltatás egyes kérdéseiről szóló 243/2019. (X. 22.) Korm. rendelet rendelkezései - az irányadóak.
		</p>
	</div>
</template>

<script>
export default {
	name: "GtcView",
	data() {
		return {
			showBackLink: history.length > 1 && !this.modal
		}
	},
	props: {
		modal: Boolean
	},
	methods: { scrollTo(refName) {
		var element = this.$refs[refName]
		var top = element.offsetTop
		window.scrollTo(0, top) 
	}
	}
}
</script>

<style scoped>
h1 {
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 30px;
}

h2 {
	margin-top: 2rem;
	text-transform: uppercase;
}

h3 {
	margin-top: 1rem;
}

h4 {
	font-size: 1.2rem;
}

table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>