<template>
	<div>

		<div class="alert alert-info mb-2">
			<div class="media">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-3" />
				<div class="media-body">
					<b>{{ $t('profile-wizard.enter-phonenumber') }}</b>
				</div>
			</div>
			<div class="text-justify mt-2">{{ $t('profile-wizard.phone-number-description') }}</div>
		</div>

		<div class="d-flex">
			<button @click="navigateBackward()" class="btn btn-outline-secondary">
				<font-awesome-icon icon="chevron-left"/>
				{{ $t('wizard.prev') }}
			</button>

			<button @click="navigateForward()" class="btn btn-outline-dark ml-auto mr-0 wizard-next-btn" v-if="nextButtonVisible">
				{{ $t(nextButtonText) }}
				<font-awesome-icon icon="chevron-right" class="ml-1"/>
			</button>
		</div>

		<ProfileView
			:wizardMode="true"
			:msgs="msgs"
			@reloadUser="reloadUser"
		/>
	</div>
</template>

<script>
import ProfileView from "../ProfileView"

export default {
	name: "ProfileWizardView",
	components: { ProfileView },
	props: {
		onBoardingDetailsProp: Object,
		msgs: Object,
		loggedInUserData: Object
	},
	data() {
		return {
			app: null,
			onBoardingDetails: null,
			nextButtonVisible: false,
			nextButtonText: 'wizard.startUsing',
			routeDestination: '/'
		}
	},
	methods: {
		reloadUser() {
			this.$emit('reloadUser', (loggedInUserData) => {
				if(this.onBoardingDetails) {
					this.onBoardingDetails.userSetPhoneNumber = true
					this.recalcNextButtonData()
					this.navigateForward()
				}
			})
		},
		navigateForward() {
			if(this.routeDestination === '/') {
				this.app.onWizardFinished()
			}
			else {
				this.$router.push(this.routeDestination)
			}
		},
		navigateBackward() {
			this.$router.go(-1)
		},
		recalcNextButtonData() {
			this.routeDestination = '/'
			this.nextButtonText = 'wizard.startUsing'
			this.nextButtonVisible = false

			if(this.onBoardingDetails) {
				this.nextButtonVisible = this.onBoardingDetails.userHasPhoneNumber || this.onBoardingDetails.userSetPhoneNumber

				if(!this.onBoardingDetails.userHasBillingData) {
					this.routeDestination = '/billing-wizard'
					this.nextButtonText = 'wizard.next'
				}
				else if(!this.onBoardingDetails.userHasCar) {
					this.routeDestination = '/cars-wizard'
					this.nextButtonText = 'wizard.next'
				}
				else if(!this.onBoardingDetails.userHasCreditCard) {
					this.routeDestination = '/cards-wizard'
					this.nextButtonText = 'wizard.next'
				}
			}
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		this.onBoardingDetails = this.onBoardingDetailsProp
		this.recalcNextButtonData()
	},
	mounted() {
		this.app.keepInWizard = true
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	},
}
</script>
