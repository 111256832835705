<template>
	<button @click="$emit('click')" class="btn-dark w-100 btn-main media align-items-center">
		<font-awesome-icon :icon="icon" size="lg" :fixed-width="true" class="mr-2"/>
		<span class="media-body">
			{{ title }}
		</span>
	</button>
</template>

<script>

export default {
	name: 'IndexButton',
	props: {
		icon: String,
		title: String
	},
	emits: ['click']
}
</script>