<template>
	<router-link tag="button" :to="to" class="btn-dark py w-100 btn-main media align-items-center">
		<font-awesome-icon :icon="icon" size="lg" :fixed-width="true" class="mr-2"/>
		<span class="media-body">
			{{ title }}
		</span>
	</router-link>
</template>

<script>

export default {
	name: 'IndexButton',
	props: {
		icon: String,
		title: String,
		to: String
	},
	emits: ['click']
}
</script>