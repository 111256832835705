<template>
	<div>
		<div id="mainButtons" v-if="$route.name === 'index'">

			<div class="row mx-sm-auto justify-content-center" style="max-width: 600px;" >

				<div class="col-6 pl-3 pr-1 d-flex flex-column">
					<IndexButton icon="map-marked-alt" :title="$t('index-view.start-at-near-charger-short')" @click="startCharge" class="mb-2 mx-auto flex-grow-1" style="max-width: 300px;" />
				</div>

				<div class="col-6 pr-3 pl-1 d-flex flex-column">
					<IndexLink icon="charging-station" :title="$t('index.wantsCharger-short')" to="charger-search/charger" class="mb-2 mx-auto flex-grow-1" style="max-width: 300px;" />
				</div>

			</div>
			<div class="row mx-sm-auto justify-content-center" style="max-width: 600px;">

				<div class="col-6 pl-3 pr-1 d-flex flex-column" v-if="showQR">
					<IndexButton icon="qrcode" :title="$t('index-view.qr-code-start-short')" @click="startChargeQR" class="mb-2 mx-auto flex-grow-1" style="max-width: 300px;" />
				</div>

				<div class="col-6 pr-3 pl-1 d-flex flex-column">
					<IndexLink icon="shopping-cart" :title="$t('index.wantsService')" to="charger-search/service" class="mb-2 mx-auto flex-grow-1" style="max-width: 300px;" />
				</div>

			</div>

			<router-link to="map" tag="button" class="expandButton d-flex align-items-center justify-content-center" v-if="$route.name == 'index'">
				<font-awesome-icon icon="expand" size="lg" />
			</router-link>

		</div>

		<router-link to="/" tag="button" class="shrinkButton" v-if="$route.name == 'map' || $route.name == 'map-lat-long' || $route.name == 'map-lat-long-route'">
			<font-awesome-icon icon="compress" size="lg" class="mx-1" /> {{ $t('map-view.menu') }}
		</router-link>

		<LocationPanel
			:defaultTab="locationPanelTab"
			v-if="isShowLocationPanel"
			@hide="hideLocationPanel"
			:locationDataWithChargers="locationDataWithChargers"
			:locationParkingLots="locationParkingLots"
			:locationDataWithPois="locationDataWithPois"
			:loggedInUserData="loggedInUserData"
		/>
	</div>
</template>

<script>
import LocationPanel from '@/components/LocationPanel.vue'
import IndexButton from "@/components/IndexButton"
import IndexLink from "@/components/IndexLink"
import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter'

export default {
	name: 'MapView',
	props: {
		loggedInUserData: Object
	},
	components: {
		LocationPanel,
		IndexButton,
		IndexLink
	},
	data() {
		return {
			app: null,
			isShowLocationPanel: false,
			locationDataWithChargers: null,
			locationDataWithPois: null,
			locationParkingLots: null,
			showQR: 'ios' === window.platform || 'android' === window.platform,
			showStartChargingSubMenu: false,
			timer: null,
			locationPanelTab: 'charger'
		}
	},
	methods: {
		showLocationPanel(locationWithPois, locationsWithChargers, locationParkingLots, tab) {
			if(this.isShowLocationPanel) {
				GlobalEventEmitter.$emit('refreshLocationPanelCharger')
			}
			else {
				this.isShowLocationPanel = true
			}
			this.locationParkingLots = locationParkingLots
			this.locationDataWithChargers = locationsWithChargers
			this.locationDataWithPois = locationWithPois
			this.locationPanelTab = tab
			this.killTimer()
			this.timer = setTimeout(() => {
				this.app.$refs.googleMapView.downloadAndShowLocationDetails(locationsWithChargers.id, tab)
			}, 5000)
		},

		hideLocationPanel() {
			this.isShowLocationPanel = false
			this.killTimer()
		},

		killTimer() {
			if(!this.timer) {
				return
			}
			clearTimeout(this.timer)
			this.timer = null
		},

		startCharge() {
			this.app.getLocation(async (position) => {
				this.$rest.closestAvailableLocationId(position.coords.latitude, position.coords.longitude, result => {
					if(result) {
						this.app.$refs.googleMapView.showLocationPanel(result)
					}
					else {
						alert(this.$t('map-view.error-no-free-charger'))
					}
				})
			}, (thereIsGeoLocationSupport) => {
				alert(this.$t('map-view.error-no-location'))
				this.$router.push('/charger-search/charger')
			})
		},

		startChargeQR() {
			window.loadAppLink('freecharger://qr-code-reader')
		}
	},
	computed: {
		userData() {
			return this.$store.state.loggedInUserDataState
		},
		mode() {
			return this.$store.getters.getMode
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	mounted() {
		this.$rest.findLocations((responseData) => {
			const mapDataToLoad = {
				locations: [],
				latitude: null,
				longitude: null,
				findroute: false
			}
			if(this.$route.params.lat && this.$route.params.lng) {
				mapDataToLoad.latitude = this.$route.params.lat
				mapDataToLoad.longitude = this.$route.params.lng
			}
			else {
				mapDataToLoad.latitude = null
				mapDataToLoad.longitude = null
			}
			if(this.$route.params.findroute) {
				mapDataToLoad.findroute = true
			}
			else {
				mapDataToLoad.findroute = false
			}
			mapDataToLoad.locations = responseData

			this.app.mapDataToLoad = mapDataToLoad
		})
	},
	beforeDestroy() {
		this.killTimer()
	}
}
</script>

<style>
.btn-dark.btn-main {
    word-break: keep-all;
    text-align: left;
    padding: 0.5rem 0.5rem 0.5rem 0.6rem;
    background-color: #3370a3;
    border: none;
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    min-height: 54px;
}

.btn-dark.btn-main:hover,
.btn-dark.btn-main:active {
    background: #245a87 !important;
}

@media (min-width: 600px) {
    .btn-dark.btn-main {
        text-align: center;
        padding: 1rem 3rem;
    }
}

.footer-inner {
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    margin-bottom: 10px;
}

#mainButtons {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: #FFFFFF;
    padding: 10px 10px 0 10px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.75);
}

.expandButton {
    position: absolute;
    right: 9px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    width: 40px;
    height: 40px;
    color: #000000;
}

.myPositionButton {
    position: absolute;
    bottom: 205px;
    right: 9px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    width: 42px;
    height: 42px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.noGpsWarning {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 1rem;
	background-color: #fff3cd;
	color: #ac6316;
	text-align: center;
}

.shrinkButton {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    height: 40px;
    white-space: nowrap;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    padding-left: 6px;
    padding-right: 8px;
    color: #000000;
}

.hide-map {
	pointer-events: none;
	position: absolute;
	z-index: -9999;
}

.hide-map .button-div,
.hide-map .myPositionButton,
.hide-map .noGpsWarning {
	display: none;
}

</style>
