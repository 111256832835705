<template>
	<div class="sidemenu">
		<ul class="sidemenu-list">
			<template v-if="!wizardView && (!loggedInUserData || !loggedInUserData.casualUser)">
				<SideMenuItem :title="$t('pageTitle.map')" to="/" @click="$emit('click')" icon="home" />
				<template v-if="!loggedInUserData || !loggedInUserData.id">
					<SideMenuItem :title="$t('menu.login')" to="/login" @click="$emit('click')" icon="sign-in-alt" />
					<SideMenuItem :title="$t('menu.register')" to="/register" @click="$emit('click')" icon="user-plus" />
					<SideMenuItem :title="$t('menu.forgottenPassword')" to="/forgotten-password" @click="$emit('click')" icon="key" />
				</template>
				<template v-else>
					<SideMenuItem :title="$t('menu.billing')" to="/billing" @click="$emit('click')" icon="address-book" />
					<!--SideMenuItem :title="$t('menu.map')" to="/map" @click="$emit('click')" icon="map-marker-alt"/-->
					<SideMenuItem :title="$t('menu.me')" to="/me" @click="$emit('click')" icon="user" />
					<SideMenuItem :title="$t('menu.chargeHistory')" to="/charge-history" @click="$emit('click')" icon="charging-station"  />
					<SideMenuItem :title="$t('menu.parkingHistory')" to="/parking-history" @click="$emit('click')" icon="parking" />
					<SideMenuItem :title="$t('menu.cards')" to="/cards" @click="$emit('click')" icon="credit-card" />
					<SideMenuItem :title="$t('menu.cars')" to="/cars" @click="$emit('click')" icon="car" />
					<SideMenuItem :title="$t('menu.coupons')" to="/coupons" @click="$emit('click')" icon="gift" />
					<!--SideMenuItem v-if="isServiceProvider() && !isIos" :title="$t('menu.couponRedeem')" to="/coupon-redeem" @click="$emit('click')" icon="qrcode" /-->
					<SideMenuItem :title="$t('menu.invoices')" to="/invoices" @click="$emit('click')" icon="file-invoice" />
					<!--li class="sidemenu-item" v-if="isServiceProvider() && isIos">
						<a href="javascript:void(0)" @click="startChargeQR(); $emit('click');">
							<font-awesome-icon icon="qrcode" class="fa-lg fa-fw mr-2"/>
							{{ $t('menu.couponRedeem') }}
						</a>
					</li-->
				</template>
				<SideMenuItem :title="$t('menu.news')" to="/news" @click="$emit('click')" icon="newspaper" />
				<SideMenuItem :title="$t('menu.support')" to="/support" @click="$emit('click')" icon="question-circle" />
				<SideMenuItem :title="$t('menu.parking-surcharge')" to="/parking-surcharge" @click="$emit('click')" >
					<template v-slot:icon>
						<SvgIcon type="mdi" :path="parkingIcon" />
					</template>
				</SideMenuItem>

				<li class="sidemenu-item" v-if="$store.getters.getSettings.welcomeScreen">
					<a href="javascript:void(0)" @click="$emit('showMainMenu'); $emit('click');">
						<SvgIcon type="mdi" :path="iconMainMenu" class="mr-1" />
						{{ $t('menu.showMainMenu') }}
					</a>
				</li>

				<SideMenuItem :title="$t('menu.aboutus')" to="/about-us" @click="$emit('click')" icon="address-card" />
			</template>
			<template v-else>
				<SideMenuItem :title="$t('menu.billing')" to="/billing" @click="$emit('click')" icon="address-book" />
				<!--SideMenuItem :title="$t('menu.map')" to="/map" @click="$emit('click')" icon="map-marker-alt"/-->
				<SideMenuItem :title="$t('menu.me')" to="/me" @click="$emit('click')" icon="user" />
				<SideMenuItem :title="$t('menu.chargeHistory')" to="/charge-history" @click="$emit('click')" icon="charging-station"  />
				<SideMenuItem :title="$t('menu.parkingHistory')" to="/parking-history" @click="$emit('click')" icon="parking" />
				<SideMenuItem :title="$t('menu.cards')" to="/cards" @click="$emit('click')" icon="credit-card" />
				<SideMenuItem :title="$t('menu.cars')" to="/cars" @click="$emit('click')" icon="car" />
				<SideMenuItem :title="$t('menu.coupons')" to="/coupons" @click="$emit('click')" icon="gift" />
				<SideMenuItem v-if="isServiceProvider() && !isIos" :title="$t('menu.couponRedeem')" to="/coupon-redeem" @click="$emit('click')" icon="qrcode" />
				<SideMenuItem :title="$t('menu.invoices')" to="/invoices" @click="$emit('click')" icon="file-invoice" />
			</template>

			<template v-if="!loggedInUserData || !loggedInUserData.casualUser">
				<li class="sidemenu-item" v-if="loggedInUserData && loggedInUserData.id !== null">
					<a href="javascript:void(0)" @click="$emit('logout'); $emit('click');">
						<font-awesome-icon icon="sign-out-alt" class="fa-lg fa-fw mr-2" />
						{{ $t('logout.logoutButton') }}
					</a>
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
import SideMenuItem from '@/components/SideMenuItem.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiBoomGateArrowUpOutline, mdiViewGrid } from '@mdi/js'

export default {
	name: 'SideMenu',
	data() {
		return {
			iconMainMenu: mdiViewGrid
		}
	},
	props: {
		wizardView: Boolean
	},
	methods: {
		isServiceProvider() {
			if(this.loggedInUserData && this.loggedInUserData.types) {
				let res = this.loggedInUserData.types.some(t => t == 'SERVICE_PROVIDER')
				return res
			}
			return false
		},
		startChargeQR() {
			window.loadAppLink('freecharger://qr-code-reader')
		}
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	computed: {
		isIos() {
			return 'ios' === window.platform
		},
		mode() {
			return this.$store.getters.getMode
		},
		parkingIcon() {
			return mdiBoomGateArrowUpOutline
		}
	},
	components: {
		SideMenuItem, SvgIcon
	},
	emits: ['logout', 'click', 'showMainMenu']
}
</script>

<style>
.sidemenu {
	height: 100vh;
	overflow-y: auto;
}

.sidemenu-list {
	padding-left: 0;
	list-style-type: none;
}

.sidemenu-item {
	border-bottom: 1px solid #ccc;
}

.sidemenu-item a {
	color: #000000;
	display: block;
	padding: 20px;
}

.sidemenu-item.active a,
.sidemenu-item:hover a {
	color: #ffffff;
	text-decoration: none;
}

.sidemenu-item.active {
	font-weight: bold;
	box-shadow: inset 0px 0 8px rgba(0, 0, 0, 0.5);
}



.sidemenu-item:hover, .sidemenu-item.active {
  background-color: var(--mode-color) !important;
}



</style>
