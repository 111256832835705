<template>
	<div>

		<div class="alert alert-info mb-2">
			<div class="media">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-3" />
				<div class="media-body">
					<b>{{ $t('billing-wizard.please-enter-billing-details') }}</b>
				</div>
			</div>
			<div class="text-justify mt-2">{{ $t('billing-wizard.billing-details-description') }}</div>
		</div>

		<div class="d-flex">
			<button @click="navigateBackward()" class="btn btn-outline-secondary">
				<font-awesome-icon icon="chevron-left"/>
				{{ $t('wizard.prev') }}
			</button>

			<button @click="navigateForward()" class="btn btn-outline-dark ml-auto mr-0 wizard-next-btn" v-if="nextButtonVisible">
				{{ $t(nextButtonText) }}
				<font-awesome-icon icon="chevron-right" class="ml-1" />
			</button>
		</div>

		<h2 class="header-common">{{ $t('pageTitle.billing') }}</h2>

		<BillingDataView
			:msgs="msgs"
			:loggedInUserData="loggedInUserData"
			@onSaved="onSaved()"
		/>

	</div>
</template>

<script>
import BillingDataView from "../BillingDataView"

export default {
	name: "BillingWizardView",
	components: { BillingDataView },
	props: {
		msgs: Object,
		onBoardingDetailsProp: Object,
		loggedInUserData: Object
	},
	data() {
		return {
			app: null,
			onBoardingDetails: null,
			routeDestination: '/',
			nextButtonVisible: false,
			nextButtonText: 'wizard.startUsing'
		}
	},
	methods: {
		onSaved() {
			this.onBoardingDetails.userSetBillingData = true
			this.recalcNextButtonData()
			setTimeout(() => this.app.scrollUp(), 1000)
		},
		navigateForward() {
			if(this.routeDestination === '/') {
				this.app.onWizardFinished()
			}
			else {
				this.$router.push(this.routeDestination)
			}
		},
		navigateBackward() {
			this.$router.go(-1)
		},
		recalcNextButtonData() {
			this.routeDestination = '/'
			this.nextButtonText = 'wizard.startUsing'
			this.nextButtonVisible = false

			if(this.onBoardingDetails) {
				this.nextButtonVisible = this.onBoardingDetails.userHasBillingData || this.onBoardingDetails.userSetBillingData

				if(!this.onBoardingDetails.userHasCar) {
					this.routeDestination = '/cars-wizard'
					this.nextButtonText = 'wizard.next'
				}
				else if(!this.onBoardingDetails.userHasCreditCard) {
					this.routeDestination = '/cards-wizard'
					this.nextButtonText = 'wizard.next'
				}
			}
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		this.onBoardingDetails = this.onBoardingDetailsProp
		this.recalcNextButtonData()
	},
	mounted() {
		this.app.keepInWizard = true
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}
}
</script>
