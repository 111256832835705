<template>
	<div>

		<div class="text-center">
			<h1 class="my-4" v-if="charge">{{ $t('charge-history.current-charge') }}</h1>
			<ChargeDetailsPanel
				@chargedata="chargeRefresh"
				:shouldRoute=false
			/>
		</div>

		<div class="text-center" v-if="!charge">
			<h2 class="header-common">{{ $t('charge-history.last-charge') }}</h2>
			<charge-card v-if="latestCharge" :data="latestCharge" />

			<div class="mt-3 text-center" v-if="loggedInUserData && loggedInUserData.casualUser">
				<button @click="logoutCasual" class="btn btn-success">
					<font-awesome-icon icon="sign-out-alt"/> {{ $t('charge-history.continueToMap') }}
				</button>
			</div>
		</div>

		<div class="text-center charge-history-header" v-if="loggedInUserData && !loggedInUserData.casualUser">
			<h1>{{ $t('charge-history.history') }}</h1>
		</div>


		<div class="filter-container" v-if="loggedInUserData && !loggedInUserData.casualUser">
			<div class="col-12 form-group">
				<label for="fromDate">{{ $t('charge-history.start-date') }}</label>
				<div class="input-group">
					<date-picker id="fromDate" v-model="fromDate" valueType="format" class="form-control date-input" v-if="!supportsDateInput">
					</date-picker>
					<input id="fromDate" v-model="fromDate" type="date" class="form-control" v-if="supportsDateInput" />
					<button class="btn btn-primary filter-input-button" @click="loadChargeHistory">
						<font-awesome-icon icon="filter"/>
						{{ $t('charge-history.search') }}
					</button>
				</div>
				<small class="d-block">{{ $t('charge-history.search-description') }}</small>
			</div>
			<NoResult v-if="notFoundCharges"></NoResult>
		</div>


		<div class="my-5 auto-margin text-center" v-if="charges">
			<div v-for="charge in charges" :key="charge.id" class="my-4">
				<charge-card :data="charge" />
			</div>
		</div>

	</div>
</template>

<style>
.filter-input-button {
  border-radius: 0 !important;
  padding: 0 10px !important;
}

.filter-input-button>svg {
  margin-right: 0px !important;
}

.auto-margin {
  margin: auto !important;
}

.date-input {
  padding: 0 !important;
  border: none !important;
}

.date-input input {
  height: 38px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.charge-history-header {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.charge-history-header>h1 {
  font-size: 2rem;
}

.car-table>tbody>tr:nth-child(even) {
  background-color: lightblue;
}
</style>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hu'
import 'vue2-datepicker/index.css'
import ChargeCard from "@/components/ChargeCard"
import ChargeDetailsPanel from '@/components/ChargeDetailsPanel.vue'
import NoResult from "../components/NoResult"

export default {
	name: 'ChargeHistoryView',
	components: { NoResult, DatePicker, ChargeCard, ChargeDetailsPanel },
	data() {
		return {
			latestCharge: null,
			fromDate: null,
			charges: [],
			charge: null,
			notFoundCharges: false,
			supportsDateInput: false
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		chargeRefresh(charge) {
			//console.log(charge)
			this.charge = charge
			if(!this.charge) {
				this.loadLatestCharge()
			}
		},
		loadLatestCharge() {
			this.$rest.loadLatestCharge(result => {
				this.latestCharge = result
			})
		},
		loadChargeHistory() {
			this.$rest.loadChargeHistory(this.fromDate, result => {
				this.charges = result
				this.notFoundCharges = result.length < 1
			})
		},
		checkDateInput() {
			var input = document.createElement('input')
			input.setAttribute('type', 'date')

			var notADateValue = 'not-a-date'
			input.setAttribute('value', notADateValue)

			return (input.value !== notADateValue)
		},
		logout() {
			this.$emit('logout')
			this.$router.push('/')
		},
		logoutCasual() {
			this.app.logoutCasualUserIfNeeded(true)
			this.$router.push('/')
		},
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		let date = new Date()
		date.setMonth(date.getMonth() - 1)
		date = new Date(date)

		let month = date.getMonth() + 1
		if(month < 10) {
			month = `0${month}`
		}
		let day = date.getDate()
		if(day < 10) {
			day = `0${day}`
		}
		this.fromDate = `${date.getFullYear()}-${month}-${day}`
		this.loadLatestCharge()

		this.supportsDateInput = ('ios' === window.platform || 'android' === window.platform) && this.checkDateInput()
	}
}
</script>
