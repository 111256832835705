<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="chevron-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1 v-if="!modal">
			Adatkezelési tájékoztató
		</h1>
		<div style="text-align: center;">Hatályos: 2021. március 1-től</div>

		<h2>Tartalom</h2>
		<ol>
			<li><a href="javascript:void(0)" @click="scrollTo('p1')">Adatkezelő adatai</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p2')">A tájékoztató célja</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p3')">Az adatkezelés általános célja</a></li>
			<li>
				<a href="javascript:void(0)" @click="scrollTo('p4')">Kezelt adatok</a>
				<ol>
					<li><a href="javascript:void(0)" @click="scrollTo('p41')">Applikáció letöltése, használata</a></li>
					<li><a href="javascript:void(0)" @click="scrollTo('p42')">Számlázás</a></li>
				</ol>
			</li>
			<li><a href="javascript:void(0)" @click="scrollTo('p5')">Az adatok biztonsága</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p6')">Az adatok továbbítása, átadása, adatfeldolgozó igénybevétele</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p7')">Az érintettek jogai</a></li>
		</ol>

		<div ref="p1"></div>
		<h2>1. Adatkezelő adatai</h2>
		<table>
			<tbody>
				<tr><th>Adatkezelő megnevezése:</th><td style="font-weight: bold;">Ready 2 Grow Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság</td></tr>
				<tr><th>Székhely:</th><td style="font-weight: bold;">2100 Gödöllő, Remsey krt. 8.</td></tr>
				<tr><th>Telefon:</th><td style="font-weight: bold;">+36 30 371 5660</td></tr>
				<tr><th>Email:</th><td style="font-weight: bold;">info@r2g.hu</td></tr>
				<tr><th>Honlap:</th><td style="font-weight: bold;">www.r2g.hu</td></tr>
				<tr><th>Cégjegyzék szám:</th><td style="font-weight: bold;">13-09-177610</td></tr>
				<tr><th>Képviselője:</th><td style="font-weight: bold;">Maka Marianna</td></tr>
			</tbody>
		</table>

		<div ref="p2"></div>
		<h2>2. A tájékoztató célja</h2>
		<p>Jelen adatkezelési tájékoztató célja, hogy az érintettek számára közérthető formában és áttekinthető módon információt biztosítson személyes adataik kezelésével kapcsolatban.</p>
		<p>Az adatkezelés nem tartozik fő tevékenységeink sorába, de annak vitelében különös tekintettel vagyunk a vonatkozó európai uniós és hazai jogi szabályozásra, különösképpen az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) „A természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatkezelési rendelet - GDPR)” valamint a hazai „Az információs önrendelkezési jogról és az információszabadságról” szóló 2011. évi CXII. törvény (Infotv.) előírásaira.</p>
		<p>Szakmai tevékenységünket illetően cégünk elektromos gépjárművek töltéséhez szükséges infrastruktúra kiépítésével, így különösen töltőberendezések telepítésével, valamint ezek kezeléséhez, vezérléséhez szükséges szoftverek, alkalmazások fejlesztésével foglalkozik.</p>

		<div ref="p3"></div>
		<h2>3. Az adatkezelés általános célja</h2>
		<p>Szolgáltatás nyújtása, szoftveres applikáció fejlesztése, illetve ügyfelek általi használata, valamint számviteli bizonylatok kiállítása.</p>

		<div ref="p4"></div>
		<h2>4. Kezelt adatok</h2>

		<div ref="p41"></div>
		<h3>4.1 A <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> weboldal és a FreeCharger applikáció letöltése, használata</h3>
		<p><b>Érintettek:</b> minden természetes személy, aki az applikációt letölti, használja.</p>
		<p><b>Adatkezelés célja:</b> Szolgáltatás nyújtása.</p>

		<table>
			<thead>
				<tr>
					<th>Tevékenység</th>
					<th>Adatfajta</th>
					<th>Jogalap</th>
					<th>Cél</th>
					<th>Őrzési idő</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="4">Applikáció rendelkezésre bocsátása</td>
					<td>név</td>
					<td rowspan="4">Szerződés teljesítése</td>
					<td rowspan="4">Szolgáltatás nyújtása</td>
					<td rowspan="4">A jogviszony megszűnésétől számított 5 évig.</td>
				</tr>
				<tr>
					<td>telefonszám</td>
				</tr>
				<tr>
					<td>e-mail cím</td>
				</tr>
				<tr>
					<td>gépjármű rendszáma</td>
				</tr>
			</tbody>
		</table>

		<p style="font-weight: bold;">Az adatkezelés folyamata:</p>
		<p>Az applikáció letöltésekor az ügyfél által megadott adatokat felhasználjuk, azokat a szerződéses kapcsolat tartama alatt kezeljük, kizárólag a szolgáltatás nyújtása, és kapcsolattartás céljából.</p>
		<p>Az adatok megadása a szerződéskötésnek, a szolgáltatás nyújtásának elengedhetetlen feltétele az ügyfél azonosítás és a kapcsolattartás végett.</p>

		<p style="font-weight: bold;">Az adatok továbbítása, átadása, adatfeldolgozó igénybevétele</p>
		<p>A szolgáltatás ellenértékének megfizetését a Szolgáltató az OTP Mobil Kft. által biztosított SimplePay szolgáltatása keretében valósítja meg.</p>
		<p>Az ügyfél a szolgáltatás használatával tudomásul veszi, hogy a Ready 2 Grow Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság, mint adatkezelő által a FreeCharger mobil applikáció és a <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> weboldal felhasználói adatbázisában tárolt alábbi személyes adatai átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbiak:</p>
		<ul>
			<li>számlázási név</li>
			<li>email címe</li>
			<li>cím</li>
			<li>telefonszám</li>
		</ul>
		<p>Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a <a href="https://simplepay.hu/adatkezelesi-tajekoztatok/" target="_blank">SimplePay Adatkezelési tájékoztatóban</a> tekinthető meg.</p>

		<p style="font-weight: bold;">SimplePay kártya regisztráció</p>
		<p>Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, a SimplePay által biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Vásárló által a regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül.</p>
		<p>Az ismétlődő fizetés ún. „eseti hozzájárulásos” típusa minden tranzakció esetében a Vevő eseti jóváhagyásával történik, tehát, Ön valamennyi jövőbeni fizetésnél jóvá kell, hogy hagyja a tranzakciót.</p>
		<p>A sikeres fizetés tényéről Ön minden esetben a hagyományos bankkártyás fizetéssel megegyező csatornákon keresztül értesítést kap.</p>
		<p>Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat elfogadásával Ön hozzájárul, hogy a sikeres regisztrációs tranzakciót követően a FreeCharger Applikációban és a <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> weboldalon Ön az itt található felhasználói fiókjából kezdeményezett későbbi fizetések a bankkártyaadatok újbóli megadása nélkül menjenek végbe.</p>
		<p style="font-weight: bold;">Figyelem(!): a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően történik.</p>
		<p>A bankkártya adatokhoz sem a Kereskedő, sem a SimplePay nem fér hozzá. A Kereskedő által tévesen vagy jogtalanul kezdeményezett ismétlődő fizetéses tranzakciókért közvetlenül a Kereskedő felel, Kereskedő fizetési szolgáltatójával (SimplePay) szemben bármilyen igényérvényesítés kizárt.</p>
		<p style="font-weight: bold;">A szolgáltatás igénybevételével egyidejűleg az ügyfél nyilatkozik, hogy az adatkezelési tájékoztatót átolvasta, annak tartalmát tudomásul vette és elfogadta.</p>

		<div ref="p42"></div>
		<h3>4.2 Számlázás</h3>
		<p><b>Érintettek:</b> minden felhasználó, aki igénybe veszi a szolgáltatást.</p>
		<p><b>Adatkezelés célja:</b> Számviteli törvény szerinti bizonylat kiállítása.</p>

		<table>
			<thead>
				<tr>
					<th>Tevékenység</th>
					<th>Adatfajta</th>
					<th>Jogalap</th>
					<th>Cél</th>
					<th>Őrzési idő</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">Számlázás</td>
					<td>név</td>
					<td rowspan="2">Jogi kötelezettség teljesítése</td>
					<td rowspan="2">Számviteli törvény szerinti bizonylat kiállítása</td>
					<td rowspan="2">A mindekor hatályos számviteli szabályok szerinti őrzési kötelezettség lejártáig.</td>
				</tr>
				<tr>
					<td>cím</td>
				</tr>
			</tbody>
		</table>

		<p style="font-weight: bold;">Az adatkezelés folyamata:</p>
		<p>A felhasználó ügyfelek nevét és címét, valamint amennyiben ezt a felhasználó igényli és megadja nem természetes személyre vonatkozó számlázási adatait felhasználjuk a számviteli bizonylat kiállításához. Ezeket az iratokat, illetve adatokat a mindekor hatályos számviteli szabályok szerinti őrzési kötelezettség lejártáig őrizzük meg.</p>
		<p>Az adatok megadása a vonatkozó jogszabályok alapján a kötelező, elmulasztása esetén a szolgáltatás nem vehető igénybe.</p>

		<div ref="p5"></div>
		<h2>5. Az adatok biztonsága</h2>

		<p>Technikai és szervezési intézkedésekkel, valamint eljárások kialakításával gondoskodunk az általunk kezelt személyes adatok biztonságáról.</p>
		<p>Az adatokat megfelelő intézkedésekkel védjük a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.</p>
		<p>A személyes adatokhoz csak azok a munkatársaink férnek hozzá, akiknek feladataik ellátásához szükségük van azok megismerésére.</p>
		<p>Az adatok biztonsága érdekében</p>
		<ul>
			<li>az informatikai rendszer tervezése és üzemeltetése során felmérjük és figyelembe vesszük a lehetséges kockázatokat, törekedve azok folyamatos csökkentésére</li>
			<li>figyelemmel kísérjük a felmerülő fenyegetéseket és sérülékenységeket (mint pl. számítógépes vírusok, számítógépes betörések, szolgáltatásmegtagadásra vezető támadások stb.), hogy időben intézkedhessünk azok elkerülése, elhárítása érdekében</li>
			<li>az informatikai eszközöket, valamint a papíron kezelt információkat védjük az illetéktelen fizikai hozzáférés, valamint a környezeti hatások (pl. víz, tűz, elektromos túlfeszültség) ellen</li>
			<li>az informatikai rendszerünk megfigyelésével gondoskodunk a lehetséges problémák, események felderítéséről</li>
			<li>nagy gondot fordítunk a munkatársak információbiztonsági képzésére és a tudatosság növelésére</li>
			<li>az üzemeltetésben résztvevő szolgáltatók kiválasztásánál alapvető szempont a megbízhatóság</li>
		</ul>

		<div ref="p6"></div>
		<h2>6. Az adatok továbbítása, átadása, adatfeldolgozó igénybevétele</h2>
		<p>Az adatok átadására sor kerülhet hatósági megkeresések esetén (pl. NAV ellenőrzés).</p>
		<p>A számlák kezelésével kapcsolatos tevékenységeinkhez vállalkozásunk adatfeldolgozót (KBOSS.hu Kft., 1031 Budapest, Záhony utca 7/C., Cégjegyzékszám: 01-09-303201) vesz igénybe, ugyanis a szolgáltatásnyújtás ellenértékéről szóló számlát harmadik személy által működtetett, online számlázóprogram (<a href="https://www.szamlazz.hu" target="_blank">https://www.szamlazz.hu</a>) segítségével állítjuk ki. A vele megkötött szerződésünk része az adatfeldolgozásról szóló megállapodás, mely biztosítja, hogy eljárása során ugyanolyan körültekintéssel, a vonatkozó jogszabályok megtartásával, kizárólag az utasításunknak megfelelően végzi az adatok kezelését.</p>

		<div ref="p7"></div>
		<h2>7. Az érintettek jogai</h2>
		<p>Minden érintettnek joga van:</p>
		<ul>
			<li>Az őt érintő adatkezelésről annak megkezdését megelőzően <i>tájékoztatást</i> kapjon</li>
			<li><i>Hozzáférjen</i> az őt érintő adatkezeléssel kapcsolatos minden információhoz</li>
			<li>Hibás, pontatlan, hiányos adatainak <i>helyesbítését</i> kérni</li>
			<li>Személyes adatainak <i>törlését</i> (inaktiválását) kérni</li>
			<li>Az adatkezelés <i>korlátozását</i> kérni</li>
			<li><i>Tiltakozni</i> adatainak bizonyos esetekben történő, pl. marketing célú felhasználása ellen</li>
			<li><i>Jogorvoslattal</i> élni az adatkezeléssel szemben</li>
		</ul>

		<p>Ezeket a jogokat az érintettek az alább megadott elérhetőségeink használatával, írásban, illetve előzetes egyeztetés alapján személyesen gyakorolhatják. Minden megkeresésre igyekszünk a lehető legrövidebb időn belül, de legfeljebb 15 munkanapon belül válaszolni.</p>
		<p>Elérhetőségek a joggyakorlás érdekében:</p>
		<ul>
			<li>Postai levélben: Ready 2 Grow Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság, 2100 Gödöllő, Remsey krt. 8.</li>
			<li>E-mailben: <a href="mailto:info@r2g.hu">info@r2g.hu</a></li>
			<li>Személyesen: +36 30 371 5660 telefonszámon egyeztetett módon.</li>
		</ul>

		<p>Telefonon személyes adatokkal kapcsolatos információkat nem áll módunkban kiadni, mivel nem tudjuk azonosítani a hívó felet.</p>
		<p>Jogaik megsértése esetén az érintettek a Nemzeti Adatkezelési és Információszabadság Hatósághoz fordulhatnak</p>
		<ul>
			<li>Cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c</li>
			<li>Telefon: +36 (1) 391-1400</li>
			<li>Fax: +36 (1) 391-1410</li>
			<li>honlap: <a href="http://www.naih.hu" target="_blank">http://www.naih.hu</a></li>
			<li>e-mail: <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a></li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "PrivacyStatementView",
	data() {
		return {
			showBackLink: history.length > 1 && !this.modal
		}
	},
	props: {
		modal: Boolean
	},
	methods: {
		scrollTo(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
h1 {
	text-transform: uppercase;
	text-align: center;
}

h2 {
	margin-top: 2rem;
}

h3 {
	margin-top: 1rem;
}

table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
