<template>
	<div>
		<template v-if="!showWallettAdd">
			<CreditCardsProcessAlert />
			<div class="row">
				<div class="col-lg-12">
					<h2 class="header-common">{{ $t('cards.myCards') }}</h2>

					<CreditCards>
						<WalletView @walletAdd="showWallettAdd = true" />
					</CreditCards>

					<div class="form-group">
						<CardRegistrationForm :wizard="false" />
					</div>
				</div>
			</div>
		</template>

		<WalletAdd :msgs="msgs" v-if="showWallettAdd" @cancel="showWallettAdd = false" />
	</div>
</template>

<script>
import CardRegistrationForm from '@/components/CardRegistrationForm'
import CreditCards from '@/components/CreditCards'
import CreditCardsProcessAlert from '@/components/CreditCardsProcessAlert'
import WalletView from '@/components/WalletView'
import WalletAdd from '@/components/WalletAdd'

export default {
	name: 'CardsView',
	components: {
		CardRegistrationForm,
		CreditCards,
		CreditCardsProcessAlert,
		WalletView,
		WalletAdd
	},
	data() {
		return {
			email: '',
			phone: '',
			password: '',
			password2: '',
			selectedCard: '',
			secret: '',
			showWallettAdd: false
		}
	},
	props: {
		msgs: Object
	},
	
	emits: ['reloadUser']
}
</script>

<style>
.register-button-row > button {
	margin-bottom: 10px;
}

.register-button-row {
	padding: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.card-spinner {
	margin-top: 10px;
}

.change-payment-method-text {
	margin-top: 5px;
	width: auto;
	display: block;
	text-align: center;
}

.btn.card-delete2,
.btn.card-default {
	padding: 10px;
	align-self: end;
	background-color: #ffffff;
}

.btn.card-delete2 > .svg-inline--fa,
.btn.card-default > .svg-inline--fa {
	margin: 0;
	height: 1rem;
	display: block;
}

.card-expiry-cv {
	margin-left: auto;
	margin-right: 0;
	color: #343a40;
	margin-top: 1.5rem;
	font-weight: bold;
}

.card-bottom {
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
}
</style>
