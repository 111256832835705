<template>
	<div class="charge-card-container">
		<div class="card ">
			<div class="card-body">
				<h1 class="card-title my-3">{{ round2(data.consumerEnergyInKw) }} kWh</h1>
				<div class="card-text">
					<div class="charge-card-text-area text-left">
						<div class="row my-2 align-items-center">
							<div class="col-7">
								<b>{{ $t('charge-card.date') }}:</b>
							</div>
							<div class="col-5">{{ data.chargeEnd }}</div>
						</div>

						<div class="row my-2 align-items-center">
							<div class="col-7">
								<b>{{ $t('charge-card.charge-time') }}:</b>
							</div>
							<div class="col-5">{{ data.chargeDurationInMinutes }} perc</div>
						</div>

						<div class="row my-2 align-items-center">
							<div class="col-7">
								<b>{{ (loggedInUserData && loggedInUserData.casualUser) || casualCharge ? $t('charge-card.pointsCouldGet') : $t('charge-card.points') }}:</b>
							</div>
							<div class="col-5">{{ data.earnedPoints }} pont</div>
						</div>

						<div class="row my-2 align-items-center" v-if="data.parkingPrice > 0">
							<div class="col-7">
								<b>{{ $t('charge-card.charge-cost') }}:</b>
							</div>
							<div class="col-5">{{ data.priceOfCharging }} forint</div>
						</div>

						<div class="row my-2 align-items-center" v-if="data.parkingPrice > 0">
							<div class="col-7">
								<b>{{ $t('charge-card.parking-cost') }}:</b>
							</div>
							<div class="col-5">{{ data.parkingPrice }} forint</div>
						</div>

						<div class="row my-2 align-items-center">
							<div class="col-7">
								<b>{{ $t('charge-card.sent-price') }}:</b>
							</div>
							<div class="col-5">{{ data.grossPrice }} forint</div>
						</div>

						<div class="row my-2 align-items-center" v-if="data.car">
							<div class="col-7">
								<b>{{ $t('charge-card.car') }}:</b>
							</div>
							<div class="col-5">{{ data.car }}</div>
						</div>

						<div class="row my-2 align-items-center" v-if="data.licencePlate">
							<div class="col-7">
								<b>{{ $t('charge-card.licence-plate') }}:</b>
							</div>
							<div class="col-5">{{ data.licencePlate }}</div>
						</div>

						<div class="my-4 text-center">
							{{ data.charger }}
						</div>

						<div class="mt-4 mb-2 text-center" v-if="data.chargerId">
							<button @click="startNewCharge(data)" class="btn btn-success">
								<font-awesome-icon icon="bolt" />
								{{ $t('charge-card.startNewCharge') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChargeCard',
	props: ['data', 'casualCharge'],
	data() {
		return {
			app: null
		}
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	methods: {
		round2(num) {
			return +(Math.round(num + 'e+2') + 'e-2')
		},
		startNewCharge(data) {
			this.app.logoutCasualUserIfNeeded(true)
			this.$router.push({ name: 'index', params: { command: 'showLocation', locationId: data.locationId, tab: 'charger' } })
		},
	},
	created() {
		this.app = this.$root.$children[0]
	}
}
</script>

<style scoped>
.charge-card-container {
	max-width: 40rem;
	margin: auto;
	box-shadow: 0 0 3px grey;
}

.charge-card-text-area {
	font-size: 1.2rem;
	max-width: 35rem;
	margin: auto;
}
</style>
