<template>
	<div class="flex-grow-1 d-flex flex-column">
		<div class="flex-grow-1">
			<div class="row" v-if="!deleteProfileVisible">

				<div class="col-lg-12 profile_form-center">
					<h2 class="header-common"> {{ $t('profile.accountInfo') }}</h2>
					<div class="row">
						<div class="col-sm-12 form-group">
							<label for="email">{{ $t('profile.email') }}:</label>
							<EmailInput
								id="email"
								class="form-control mb-2"
								maxlength="255"
								:msgs="msgs"
								:readonly="!usedApple && !usedFacebook && !usedGoogle"
								v-model="email"
							/>
							<h6 v-if="usedApple">{{$t('profile-view.alternative-login', {name: 'Apple'})}}</h6>
							<h6 v-if="usedFacebook">{{$t('profile-view.alternative-login', {name: 'Facebook'})}}</h6>
							<h6 v-if="usedGoogle">{{$t('profile-view.alternative-login', {name: 'Google'})}}</h6>
						</div>



						<div class="col-sm-12 form-group">
							<label for="phone">{{ $t('profile.phone') }}: *</label>
							<PhoneInput
								id="phone"
								v-model="phone"
								:msgs="msgs"
								class="form-control"
								maxlength="255"
								:required="true"
							/>
							<ComponentMessage :msgs="msgs" forComponent="phone"/>
						</div>

						<div class="col-sm-12 form-group">
							<label for="lang">{{ $t('profile-view.language') }}:</label>
							<select id="lang" v-model="lang" class="form-control">
								<option value="hu">Magyar</option>
								<option value="en">English</option>
							</select>
						</div>


						<div class="col-12 form-group media" v-if="email && hasPassword">
							<input
								id="showPasswordChangeFields"
								type="checkbox"
								v-model="passwordChangeVisible"
								class="switch form-check-input"
							/>
							<label for="showPasswordChangeFields media-body" class="form-check-label">{{ $t('profile-view.want-to-change-password') }}</label>
						</div>


						<div class="col-sm-12" v-if="passwordChangeVisible || !hasPassword">
							<h3 class="header-common" v-if="hasPassword">{{ $t('profile-view.change-password') }}</h3>
							<h3 class="header-common" v-else>{{ $t('profile-view.set-password') }}</h3>

							<div class="col-sm-12 form-group" v-if="hasPassword">
								<label for="oldPassword">{{ $t('profile-view.current-password') }}</label>
								<PasswordInput
									id="oldPassword"
									v-model="oldPassword"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
								/>
								<ComponentMessage :msgs="msgs" forComponent="oldPassword"/>
							</div>

							<div class="col-sm-12 form-group">
								<label for="password">{{ $t('profile.password') }}:</label>
								<PasswordInput
									id="password"
									v-model="password"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
								/>
								<ComponentMessage :msgs="msgs" forComponent="password"/>
							</div>

							<div class="col-sm-12 form-group">
								<label for="password2">{{ $t('profile.password2') }}:</label>
								<PasswordInput
									id="password2"
									v-model="password2"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
									:password1="password"
								/>
								<ComponentMessage :msgs="msgs" forComponent="password2"/>
							</div>
							<div class="col-sm-12">
								<div class="form-group text-right">
									<small>{{ $t('profile.mandatoryLabel') }}</small>
								</div>
							</div>
						</div>

						<div class="col-sm-12">
							<div class="form-group buttonRow">
								<button @click="save()" class="btn btn-primary">
									<font-awesome-icon icon="check"/>
									{{ $t('profile.saveButton') }}
								</button>

								<router-link to="/" tag="button" class="btn btn-secondary" v-if="!wizardMode">
									<font-awesome-icon icon="times"/>
									{{ $t('profile.cancelButton') }}
								</router-link>
							</div>
						</div>
					</div>

				</div>


			</div>

			<div class="card" v-if="deleteProfileVisible">
				<div class="card-header">{{ $t('profile-view.delete-profile') }}</div>
				<div class="card-body" v-html="this.$t('profile-view.profile-delete-message')">
				</div>
				<div class="card-footer">
					<button @click="deleteProfileVisible = false" class="btn btn-secondary mr-3">
						<font-awesome-icon icon="times"/>
						{{ $t('cars.cancel') }}
					</button>
					<button @click="deleteProfile()" class="btn btn-danger float-right">
						<font-awesome-icon icon="exclamation-triangle"/>
						{{ $t('profile-view.delete-profile') }}
					</button>
				</div>
			</div>

		</div>

		<div>

			<div class="form-group text-center mt-4" v-if="!wizardMode">
				<button @click="deleteProfileVisible = true" class="btn btn-danger" v-if="!deleteProfileVisible">
					<font-awesome-icon icon="exclamation-triangle"/>
					{{ $t('profile-view.delete-profile') }}
				</button>

			</div>

		</div>

	</div>
</template>

<style>

.profile_form-center {
  max-width: 35rem !important;
  margin: auto;
}
</style>

<script>
export default {
	name: 'ProfileView',

	data() {
		return {
			passwordChangeVisible: false,
			hasPassword: true,
			usedGoogle: false,
			usedFacebook: false,
			usedApple: false,
			deleteProfileVisible: false,
			email: '',
			phone: '',
			password: '',
			password2: '',
			oldPassword: '',
			lang: this.$i18n.locale
		}
	},
	props: {
		msgs: Object,
		loggedInUserData: Object,
		wizardMode: Boolean
	},
	methods: {
		save() {
			this.$i18n.locale = this.lang
			localStorage.setItem('Language', this.lang)

			this.$rest.saveProfile(
				this.email,
				this.phone,
				this.password,
				this.password2,
				this.oldPassword,
				this.lang,
				response => {
					this.$emit("reloadUser")
					if(response.data.messages[0].severity !== "error") {
						this.$emit("logoutWithRest")
					}
				}
			)
		},
		loadUser() {
			this.$rest.getLoggedInUser((user, extra) => {
				this.email = user.email
				this.phone = user.phone
				this.hasPassword = extra.hasPassword
				this.usedApple = extra.usedApple
				this.usedFacebook = extra.usedFacebook
				this.usedGoogle = extra.usedGoogle
			})
		},
		deleteProfile() {
			if(confirm(this.$t('profile-view.profile-delete-accept'))) {
				this.$rest.deleteProfile((success) => {
					if(success) {
						this.$emit('logoutNoRest')
						this.$router.replace('/')
					}
				})
			}
		},
	},
	beforeMount() {
		this.loadUser()
	},
	emits: ['reloadUser', 'logoutNoRest']
}
</script>
