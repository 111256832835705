<template>
	<input
		:id="id"
		type="number"
		:value="text"
		@change="change"
		pattern="[0-9]*"
		@input="$emit('input', $event.target.value)"
		@keypress="isNumber($event)"
	/>
</template>

<script>

export default {
	name: 'NumberInput',
	data() {
		return {
			text: ''
		}
	},
	props: [ 'id', 'value', 'msgs', 'required', 'min', 'max' ],
	mounted() {
		this.text = this.value
	},
	watch: {
		value(v) {
			this.text = v
		}
	},
	methods: {
		input(event) {
			this.text = event.target.value
		},
		change(event) {
			var val = event.target.value.trim()

			if(!val && this.required) {
				event.target.value = val
				this.text = val
				this.$emit('change', val)
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}])
				return
			}
      
			if(val) {
				if(this.max !== null && this.max !== undefined) val = Math.min(val, this.max)
				if(this.min !== null && this.min !== undefined) val = Math.max(val, this.min)
			}

			event.target.value = val
			this.text = val
			this.$emit('change', val)
			this.$set(this.msgs, this.id, [])
		},
		isNumber(evt) {
			evt = (evt) ? evt : window.event
			var charCode = (evt.which) ? evt.which : evt.keyCode
			if(charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			}
			else {
				return true
			}
		}
	},
	emits: ['input', 'change']
}
</script>
