<template>
	<div id="ajaxOverlay">
		<font-awesome-icon icon="circle-notch" class="fa-spin fa-3x fa-fw" />
	</div>
</template>

<script>

export default {
	name: 'AjaxOverlay'
}
</script>

<style>
	@keyframes overlayFadeIn {
		0%   { opacity: 0; }
		30%   { opacity: 0; }
		100%   { opacity: 1; }
	}

	@-moz-keyframes overlayFadeIn {
		0%   { opacity: 0; }
		30%   { opacity: 0; }
		100%   { opacity: 1; }
	}

	@-webkit-keyframes overlayFadeIn {
		0%   { opacity: 0; }
		30%   { opacity: 0; }
		100%   { opacity: 1; }
	}

	@-ms-keyframes overlayFadeIn {
		0%   { opacity: 0; }
		30%   { opacity: 0; }
		100%   { opacity: 1; }
	}

	#ajaxOverlay {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2147483647;
		position: fixed;
		background-color: rgba(255, 255, 255, 0.5);
		text-align: center;
		cursor: wait;
		-webkit-animation: overlayFadeIn 5s;
		-moz-animation: overlayFadeIn 5s;
			-ms-animation: overlayFadeIn 5s;
			-o-animation: overlayFadeIn 5s;
				animation: overlayFadeIn 5s;	
	}

	#ajaxOverlay > .fa-3x {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -24px;
		margin-left: -24px;
		color: rgba(0, 0, 0, 0.5);
	}
</style>