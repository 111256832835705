<template>
	<Transition name="modal">
		<div v-if="show" class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header">
						<slot name="header">default header</slot>
					</div>

					<div class="modal-body" style="width: 100%">
						<div v-for="vehicle in vehicles" :key="vehicle.id" class="row">
							<div class="col-sm-9 form-group">{{ vehicle.name }}</div>
							<button
								class="btn-danger col-sm-1 modal-button"
								@click="$emit('removeFromList', vehicle.id)"
							>
								{{ $t('approved-vehicle.keep-custom-vehicle')}}</button>
							<button
								class="btn-primary fcol-sm-1 modal-button"
								@click="$emit('switchToVehicleFromList', vehicle.id)"
							>
								{{ $t('approved-vehicle.switch-to-vehicle-from-list')}}
							</button>
						</div>
					</div>

					<div class="modal-footer">
						<slot name="footer">
							<button
								class="btn-primary float-right ml-3"
								@click="$emit('close')"
							>{{ $t('approved-vehicle.skip')}}
							</button>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
export default {
	name: "ApprovedVehiclesModal",
	props: {
		show: Boolean,
		vehicles: Array
	}
}
</script>

<style scoped>
.modal-button {
  margin: 3px;
  max-width: 40%
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
