<template>
	<input
		:id="id"
		type="email"
		:value="email"
		@change="change"
	/>
</template>

<script>
const emailRegex = /[a-zA-Z0-9!#$%&'*+//=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+//=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

export default {
	name: 'EmailInput',
	data() {
		return {
			email: ''
		}
	},
	props: {
		id: String,
		value: String,
		msgs: Object,
		required: Boolean
	},
	mounted() {
		this.email = this.value
	},
	watch: {
		value(v) {
			this.email = v
		}
	},
	methods: {
		change(event) {
			var val = event.target.value.trim()

			if(!val && !this.required) {
				event.target.value = val
				this.phone = val
				this.$emit('input', val)
				this.$set(this.msgs, this.id, [])
				return
			}

			if(!val && this.required) {
				event.target.value = val
				this.text = val
				this.$emit('input', val)
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}])
				return
			}

			var match = val.match(emailRegex)
			if(match && val === match[0]) {
				event.target.value = val
				this.email = val
				this.$emit('input', val)
				this.$set(this.msgs, this.id, [])
				return
			}

			this.$emit('input', '')
			this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.email'), severity: 'error'}])
		}
	},
	emits: ['input']
}
</script>