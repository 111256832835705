<template>
	<transition leave-active-class="animate__animated animate__slideOutRight">
		<div class="location-filter-panel-outer" @click.self="hide">
			<div class="btn-close-poi animate__animated animate__bounceInRight">
				<button
					@click="hide"
					class="location-filter-panel-close mr-auto btn btn-danger rounded-circle"
				>
					<font-awesome-icon icon="times" size="lg" />
				</button>
			</div>
			<div class="location-filter-panel animate__animated animate__bounceInRight">

				<div>
					<h3 style="margin-right: 35px;">{{ location.name }}</h3>
					<p>{{ location.fullAddress }}</p>
					<div v-html="location.description" v-if="location.description" class="poi-description" />

					<table class="table poi-table poi-table-location" v-if="location.traits">
						<tbody>
							<tr v-for="tv in location.traits" :key="tv.trait.id">
								<td>{{ tv.trait.name }}:</td>
								<td>
									<TraitValue :tv="tv" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="col-12 text-right">
					<div v-if="!showNavigationButtons" class="text-center">

						<router-link :to="showOnMap(false)" tag="button" class="btn btn-primary btn-map">
							<font-awesome-icon icon="map"/>
							{{ $t('poi-panel.map') }}
						</router-link>

						<router-link :to="showOnMap(true)" tag="button" class="btn btn-primary btn-map">
							<font-awesome-icon icon="route"/>
							{{ $t('poi-panel.route') }}
						</router-link>

						<button @click="showNavigationButtons = true" tag="button" class="btn btn-primary btn-map" v-if="mobilPlatform">
							<font-awesome-icon icon="route" />
							{{ $t('poi-panel.navigation-other-app') }}
						</button>

					</div>

					<div v-if="showNavigationButtons" class="text-center">
						<div class="mb-2 font-weight-bold">
							{{ $t('poi-panel.start-in-other-app') }}
							<button @click="showNavigationButtons = false" tag="button" class="btn btn-danger ml-2 rounded-circle">
								<font-awesome-icon icon="times" class="m-0" />
							</button>
						</div>

						<button @click="showOnAppleMaps" tag="button" class="btn btn-primary btn-map" v-if="iosPlatform">
							<font-awesome-icon icon="route" />
							Apple Maps
						</button>

						<button @click="showOnGoogleMaps" tag="button" class="btn btn-primary btn-map">
							<font-awesome-icon icon="route" />
							Google Maps
						</button>

						<button @click="showOnWaze" tag="button" class="btn btn-primary btn-map">
							<font-awesome-icon icon="route" />
							Waze
						</button>

						<button @click="showOnGoogleSygic" tag="button" class="btn btn-primary btn-map">
							<font-awesome-icon icon="route" />
							Sygic
						</button>

					</div>
				</div>

				<div class="nav nav-tabs">
					<div class="nav-item">
						<div class="nav-link" :class="isChargerTabActive? 'active': ''" @click="changeTab('charger')">{{ $t('poi-panel.chargers') }}</div>
					</div>
					<div class="nav-item">
						<div class="nav-link" :class="isChargerTabActive? '': 'active'" @click="changeTab('poi')">{{ $t('poi-panel.services') }}</div>
					</div>
				</div>

				<div v-if="isChargerTabActive">
					<locationPanelCharger
						v-for="charger in chargers"
						:key="charger.id + charger.status"
						:charger="charger"
						@hideLocationPanel="hide"
						@showStartCharging="showStartCharging"
						class="location-panel-charger rounded"
					/>
				</div>

				<div v-if="!isChargerTabActive" class="poi-content my-4">
					<div class="poi-card-container">
						<div class="card poi-card" v-for="poi in location.pois" :key="poi.name">
							<img v-if="poi.pictureUrl" class="card-img-top poi-card-img" :src="poi.pictureUrl">
							<div class="card-body">
								<h3 class="card-title text-center poi-name">{{ poi.name }}</h3>

								<div v-html="poi.description" v-if="poi.description" class="poi-description" />

								<table class="table poi-table">
									<tbody>
										<tr v-if="poi.url">
											<td>{{ $t('poi-panel.website') }}</td>
											<td><a :href="poi.url" target="_blank" style="word-break: break-word;">{{ poi.url }}</a></td>
										</tr>
										<tr>
											<td>{{ $t('poi-panel.point-accepting') }}</td>
											<td>{{ poi.acceptPoint ? 'Igen' : 'Nem' }}</td>
										</tr>
										<tr v-if="poi.discountPercentage">
											<td>{{ $t('poi-panel.discount') }}</td>
											<td>{{ poi.discountPercentage }} %</td>
										</tr>
										<tr>
											<td>{{ $t('poi-panel.categories') }}</td>
											<td>
												<button
													class="poi-category-tag"
													v-for="category in poi.category"
													:key="category.id"
													@click="onCategoryClick(category)"
												>
													{{ category.name }}
												</button>
											</td>
										</tr>
										<tr v-for="tv in poi.traits" :key="tv.trait.id">
											<td>{{ tv.trait.name }}:</td>
											<td>
												<TraitValue :tv="tv" />
											</td>
										</tr>
									</tbody>
								</table>

							</div>
						</div>
						<div v-if="location.pois.length===0">
							<div class="text-center">{{ $t('poi-panel.no-result') }}</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</transition>
</template>

<script>
import LocationPanelCharger from '@/components/LocationPanelCharger.vue'
import TraitValue from '@/components/TraitValue.vue'

export default {
	name: 'PoiPanelView',
	components: {
		LocationPanelCharger, TraitValue
	},
	props: [
		'location', 'data', 'chargerActive'
	],
	data() {
		return {
			chargers: [],
			isChargerTabActive: true,
			mobilPlatform: 'ios' === window.platform || 'android'===window.platform,
			iosPlatform: 'ios' === window.platform,
			showNavigationButtons: false
		}
	},
	methods: {
		showOnMap(withRoute) {
			//console.log('on show map: ')
			const lat = this.location.lat
			const lng = this.location.lng
			if(lat && lng) {
				if(withRoute) {
					return `/map/${lat}/${lng}/findroute`
				}
				else {
					return `/map/${lat}/${lng}`
				}
			}
			return "/map"
		},
		showOnWaze() {
			const lat = this.location.lat
			const lng = this.location.lng
			if(lat && lng) {
				window.loadAppLink('freecharger://waze-open/ul?ll=' + lat + ',' + lng + '&navigate=yes')
			}
		},
		showOnGoogleMaps() {
			const lat = this.location.lat
			const lng = this.location.lng
			if(lat && lng) {
				window.loadAppLink('freecharger://google-map-open/&destination=' + lat + ',' + lng)
			}
		},
		showOnAppleMaps() {
			const lat = this.location.lat
			const lng = this.location.lng
			if(lat && lng) {
				window.loadAppLink('freecharger://apple-maps-open/' + lat + ',' + lng)
			}
		},
		showOnGoogleSygic() {
			const lat = this.location.lat
			const lng = this.location.lng
			if(lat && lng) {
				window.loadAppLink('freecharger://sygic-open/coordinate|' + lng + '|' + lat + '|drive')
			}
		},
		changeTab(tabName) {
			if(tabName === 'charger') {
				this.isChargerTabActive = true
			}
			else {
				this.isChargerTabActive = false
			}
		},
		onCategoryClick(category) {
			if(confirm(this.$t('poi-panel.category-filter-confirm'))) {
				this.$emit('category', category)
			}
		},
		hide() {
			this.$emit('hide')
		},
		showStartCharging(chargerId) {
			this.$router.push({name: 'charger', params: {chargerId: chargerId}, query: {showStartCharging: true}})
		},
		setStatus() {
			if(this.chargers) {
				this.chargers.forEach(c => {
					if(c.dynamicTraits && c.dynamicTraits.length>0) {
						c.status = c.dynamicTraits[0].listItem
					}
				})
			}
		}
	},
	beforeMount() {
		this.isChargerTabActive = this.chargerActive
		this.chargers = this.data.chargers
		this.setStatus()
	},
	watch: {
		data: function (newData) {
			this.chargers = newData.chargers
			this.setStatus()
			this.$forceUpdate()
		}
	},
	emits: ['hide', 'category'],
}
</script>

<style>
.location-filter-panel-outer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1021;
}

.btn-map {
  margin-bottom: 10px;
  margin-right: 10px;
}

.btn-close-poi {
  position: fixed;
  right: 2vw;
  top: 15px;
  z-index: 100000;
}

.location-filter-panel {
  background-color: rgba(8, 182, 71, 0.95);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  min-width: 320px;
  padding: 1rem;
  color: #ffffff;
  overflow: auto;
}

.location-filter-panel-close {
  width: 40px;
  height: 40px;
}

.location-filter-panel-close > svg {
  margin: auto;
}

.poi-card {
  overflow: hidden;
  color: black;
  margin: 1.5rem auto;
}

.poi-card-container {
  margin: auto;
}

.poi-name {
  color: #3370a3;
  margin-bottom: 1.5rem !important;
}

.poi-category-tag {
  padding: 5px 10px;
  border: none;
  background-color: #509cd5;
  color: white;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: inline-block;
}

.poi-category-tag:hover {
  background-color: #3370a3;
}

@media (max-width: 1200px) {
  .location-filter-panel {
    width: 100%;
  }
}

.poi-table td:first-child {
  font-weight: bold;
}

.poi-table-location td {
  color: #ffffff;
  border-bottom: 1px solid #dee2e6;
}

.poi-description {
  margin-bottom: 1rem;
}

.poi-description p {
  margin-top: 0;
  margin-bottom: 0;
}

.poi-card-img {
  padding: 10px;
}

</style>
