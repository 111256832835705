<template>
	<div>

		<div class="text-center"  v-if="parking">
			<h1 class="my-4">{{ $t('parking-history.current-parking') }}</h1>
			<ParkingDetailsPanel
				:shouldRoute=false
				:key="'asd'"
				:app="app"
				:msgs="msgs"
			/>
		</div>

		<div v-if="!parking">

			<div class="text-center">
				<h2 class="header-common">{{ $t('parking-history.last-parking') }}</h2>
				<parking-card v-if="latestParking" :data="latestParking" :app="app" />
				<div class="mt-3 text-center" v-if="loggedInUserData && loggedInUserData.casualUser">
					<button @click="logoutCasual" class="btn btn-success">
						<font-awesome-icon icon="sign-out-alt"/> {{ $t('parking-history.continueToMap') }}
					</button>
				</div>
			</div>

			<div class="text-center parking-history-header" v-if="loggedInUserData && !loggedInUserData.casualUser">
				<h1>{{ $t('parking-history.history') }}</h1>
			</div>


			<div class="filter-container" v-if="loggedInUserData && !loggedInUserData.casualUser">
				<div class="col-12 form-group">
					<label for="fromDate">{{ $t('parking-history.start-date') }}</label>
					<div class="input-group">
						<date-picker id="fromDate" v-model="fromDate" valueType="format" class="form-control date-input" v-if="!supportsDateInput">
						</date-picker>
						<input id="fromDate" v-model="fromDate" type="date" class="form-control" v-if="supportsDateInput" />
						<button class="btn btn-primary filter-input-button" @click="loadParkingHistory">
							<font-awesome-icon icon="filter"/>
							{{ $t('parking-history.search') }}
						</button>
					</div>
					<small class="d-block">{{ $t('parking-history.search-description') }}</small>
				</div>
				<NoResult v-if="notFoundParkings"></NoResult>
			</div>


			<div class="my-5 auto-margin text-center" v-if="parkings">
				<div v-for="parking in parkings" :key="parking.id" class="my-4">
					<parking-card
						:data="parking"
						:app="app"
					/>
				</div>
			</div>
		</div>

	</div>
</template>

<style>

.filter-input-button {
  border-radius: 0 !important;
  padding: 0 10px !important;
}

.filter-input-button > svg {
  margin-right: 0px !important;
}

.auto-margin {
  margin: auto !important;
}

.date-input {
  padding: 0 !important;
  border: none !important;
}

.date-input input {
  height: 38px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.parking-history-header {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.parking-history-header > h1 {
  font-size: 2rem;
}

.car-table > tbody > tr:nth-child(even) {
  background-color: lightblue;
}

</style>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hu'
import 'vue2-datepicker/index.css'
// import ParkingCard from "@/components/ParkingCard";
import ParkingDetailsPanel from '@/components/ParkingDetailsPanel.vue'
import NoResult from "../components/NoResult"
import ParkingCard from "@/components/ParkingCard.vue"

export default {
	name: 'ParkingHistoryView',
	components: {ParkingCard, NoResult, DatePicker, ParkingDetailsPanel},
	data() {
		return {
			latestParking: null,
			fromDate: null,
			parkings: [],
			notFoundParkings: false,
			supportsDateInput: false,
			refreshTimer: null,
		}
	},
	props: {
		msgs: Object,
		app: Object
	},
	methods: {
		refresh(count = 0) {
			this.$ws.command('getParkingSession')

			clearTimeout(this.refreshTimer)
			if(count > 10) return console.error('refresh timeout')

			this.refreshTimer = setTimeout(() => {
				if(this.parking) return
				this.refresh(count + 1)
			}, 1000)
		},
		async loadLatestParking() {
			this.latestParking = await this.$rest.loadLatestParking()
			//console.log(this.latestParking)
		},
		async loadParkingHistory() {
			this.parkings = await this.$rest.loadParkingHistory(this.fromDate)
			if(!this.parkings) return

			this.notFoundParkings = this.parkings.length < 1
		},
		checkDateInput() {
			var input = document.createElement('input')
			input.setAttribute('type','date')

			var notADateValue = 'not-a-date'
			input.setAttribute('value', notADateValue)

			return (input.value !== notADateValue)
		},
		logout() {
			this.$emit('logout')
			this.$router.push('/')
		},
		logoutCasual() {
			this.app.logoutCasualUserIfNeeded(true)
			this.$router.push('/')
		},
	},
	computed:{
		parking() {
			return this.$store.getters.getParking
		},
	},
	watch: {
		async parking() {
			if(this.parking) return

			if(!await this.loggedInUserData) {
				this.$router.push('/')
				return
			}
			this.loadLatestParking()
			this.loadParkingHistory()
		},
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	mounted() {
		this.$ws.command('getParkingSession')
		this.refresh()
	},
	beforeMount() {
		let date = new Date()
		date.setMonth(date.getMonth() - 1)
		date = new Date(date)

		let month = date.getMonth() + 1
		if(month < 10) {
			month = `0${month}`
		}
		let day = date.getDate()
		if(day < 10) {
			day = `0${day}`
		}
		this.fromDate = `${date.getFullYear()}-${month}-${day}`
		this.loadLatestParking()
		this.loadParkingHistory()

		this.supportsDateInput = ('ios' === window.platform || 'android' === window.platform) && this.checkDateInput()
	}
}
</script>
