<template>
	<div style="word-break: break-word;">
		<div v-if="tv.trait.type == 'BOOLEAN'">
			{{ tv.value === '1' ? 'Igen' : 'Nem' }}
		</div>
		<div v-else-if="tv.trait.type == 'TEXT'">
			<a :href="tv.value" target="_blank" v-if="tv.trait.identifier && tv.trait.identifier.indexOf('_WEB') > 0">
				{{ tv.value }}
			</a>
			<a :href="'mailto:' + tv.value.replace(/\s/g, '')" v-else-if="tv.trait.identifier && tv.trait.identifier.indexOf('_EMAIL') > 0">
				{{ tv.value }}
			</a>
			<a :href="'tel:' + tv.value.replace(/\s/g, '')" v-else-if="tv.trait.identifier && tv.trait.identifier.indexOf('_PHONE') > 0">
				{{ tv.value }}
			</a>
			<template v-else>
				{{ tv.value }}
			</template>
		</div>
		<div v-else-if="tv.trait.type == 'LIST_SINGLE'">
			<a :href="tv.listItem" target="_blank" v-if="tv.trait.identifier && tv.trait.identifier.indexOf('_WEB') > 0">
				{{ tv.listItem }}
			</a>
			<a :href="'mailto:' + tv.listItem.replace(/\s/g, '')" v-else-if="tv.listItem != null && tv.trait.identifier && tv.trait.identifier.indexOf('_EMAIL') > 0">
				{{ tv.listItem }}
			</a>
			<a :href="'tel:' + tv.listItem.replace(/\s/g, '')" v-else-if="tv.listItem != null && tv.trait.identifier && tv.trait.identifier.indexOf('_PHONE') > 0">
				{{ tv.listItem }}
			</a>
			<template v-else>
				{{ tv.listItem }}
			</template>
		</div>
		<div v-else-if="tv.trait.type == 'LIST_MULTIPLE'">
			<ul class="mb-0">
				<li v-for="tli in tv.listItems" :key="tli">{{ tli }}</li>
			</ul>
		</div>
		<div v-else-if="tv.trait.type == 'LIST_CUSTOM'">
			<ul class="mb-0">
				<li v-for="tli in tv.listStrings" :key="tli">{{ tli }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TraitValue',
	props: ['tv']
}
</script>