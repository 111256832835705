<template>
	<div class="payment-summary-card-container">
		<div class="card ">
			<div class="card-body">
				<div class="card-text">
					<div class="payment-summary-card-text-area text-left">
						<div class="row my-2 custom-row">
							<div class="col-12 col-sm-7"><b>{{ $t('paymentSummary.reservedEnergy') }}</b></div>
							<div class="col-12 col-sm-5 energy-col text-sm-right"> {{ data.reservedEnergy }} kWh</div>
						</div>
						<div class="row my-2 custom-row" v-if="data.time">
							<div class="col-12 col-sm-7"><b>{{ $t('paymentSummary.time') }}</b></div>
							<div class="col-12 col-sm-5 text-sm-right">{{ data.time }} {{ $t('chargerView.minutes') }}</div>
						</div>
						<div class="row my-2 custom-row" v-if="priceSettings.parkingEnabled">
							<div class="col-12 col-sm-7"><b>{{ $t('paymentSummary.amount') }}</b></div>
							<div class="col-12 col-sm-5 text-sm-right">{{ data.amount }} {{ $t('currency') }}</div>
						</div>
						<div class="row my-2 custom-row" v-if="priceSettings.parkingEnabled">
							<div class="col-12 col-sm-7"><b>{{ $t('paymentSummary.amountToHold') }}</b></div>
							<div class="col-12 col-sm-5 text-sm-right">{{ priceSettings.parkingToHoldGross }} {{ $t('currency') }}</div>
						</div>
						<div class="row my-2 custom-row">
							<div class="col-12 col-sm-7"><b>{{ $t('paymentSummary.moneyToHold') }}</b></div>
							<div class="col-12 col-sm-5 text-sm-right"> {{ moneyToHold }} {{ $t('currency') }}</div>
						</div>
					</div>
				</div>

			</div>
		</div>


	</div>

</template>

<script>
export default {
	name: "PaymentSummaryCard",
	props: {
		data: Object,
		priceSettings: Object
	},
	computed: {
		moneyToHold() {
			if(this.priceSettings.parkingEnabled) {
				return this.priceSettings.parkingToHoldGross + this.data.amount
			}
			return this.data.amount
		}
	}
}
</script>

<style scoped>

.payment-summary-card-container {
  max-width: 40rem;
  /*margin: auto;*/
  margin: 15px auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.payment-summary-card-text-area {
  display: inline;
  font-size: 1.2rem;
  max-width: 35rem;
  margin: auto;
  text-align: left;
}

@media (max-width: 680px) {
  .payment-summary-card-container {
    margin: 15px 7px 10px 7px;
  }
}

</style>

