import { render, staticRenderFns } from "./ChargeHistoryView.vue?vue&type=template&id=2f21b480"
import script from "./ChargeHistoryView.vue?vue&type=script&lang=js"
export * from "./ChargeHistoryView.vue?vue&type=script&lang=js"
import style0 from "./ChargeHistoryView.vue?vue&type=style&index=0&id=2f21b480&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports