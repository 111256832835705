<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="chevron-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1 v-if="!modal">
			General terms and conditions
		</h1>

		<p>
			The user (hereinafter referred to as "<b>User</b>") who registers in the <b>FreeCharger</b> smartphone application (hereinafter referred to as "<b>Application</b>") at <b>Ready 2 Grow Kft.</b> (hereinafter referred to as "<b>Service Provider</b>") and the user of the occasional charging available from the browser (hereinafter referred to as "<b>Occasional User</b>") accepts the terms and conditions of these General Terms and Conditions and acknowledges them as binding.
		</p>

		<p>
			If the User does not agree to the Application of these General Terms and Conditions (hereinafter referred to as "<b>GTC</b>"), the Application, the browser-based occasional charging and the related download and other services shall not be used and shall not be available.
		</p>

		<h2>
			Service provider details:
		</h2>

		<p>
			The <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> Website, where the occasional charging available from the browser, and the owner and operator of the Application is the Service Provider.
		</p>

		<p>
			Name: Ready 2 Grow Kft.<br/>
			Registered seat: 2100 Gödöllő, Remsey krt. 8.<br/>
			Company registration number: 13-09-177610<br/>
			Tax number: 25397130-2-13
		</p>

		<p>
			Telephone Customer Service Contact: <a href="tel:+36203678407">+36 20 367 84 07</a><br/>
			Website address of Ready 2 Grow Kft.: <a href="https://r2g.hu" target="_blank">https://r2g.hu</a><br/>
			E-mail address of Ready 2 Grow Kft.: <a href="mailto:info@r2g.hu">info@r2g.hu</a>
		</p>

		<p>
			Address of the occasional charging website: <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> (email address:: <a href="mailto:info@freecharger.hu">info@freecharger.hu</a>)
		</p>

		<h2>
			Description of the service
		</h2>

		<h3>
			General information
		</h3>

		<p>
			These GTC apply exclusively to the <b>FreeCharger</b> Website (hereinafter referred to as the Website), and the <b>FreeCharger Application</b> (hereinafter referred to as the <b>Application</b>) and the chargers operated by the Service Provider or its roaming partners. By accepting these GTC and registering in the Application, and subject to the other applicable provisions of the present GTC, the User may use the following services (hereinafter referred to as the "<b>Service</b>"):
		</p>

		<p>
			<b>Application information services:</b> charging map, payment and invoicing data, details of the User's vehicle, details of previous charges, charging receipts and summary invoices issued, points balance collected after charges, points redemption options.
		</p>

		<p>
			Charging electric vehicle (hereinafter "EV") at charging stations (hereinafter "<b>Charging</b>") operated by the <b>Service Provider or by the Service Provider's contracted partners under roaming agreements</b> and opened to traffic by Users; payment, invoicing services. The list of charging stations operated by the Service Provider or its roaming partners is constantly updated on the map of Application and the Website and on the charging list.
		</p>

		<p>
			<b>Occasional Charging:</b> Service Provider shall provide the availability of so-called "occasional charging" without registration in the Application at EV charging points where it offers electromobility services. If the Occasional User decides to make use of an occasional charging (hereinafter: <b>Occasional Charging</b>), he/she can do so by accessing the Website (<a href="https://freecharger.hu">https://freecharger.hu</a>).
		</p>

		<p>
			<b>Charging point features:</b> Chargers covered by the Service, which are not operated by a roaming partner, will be branded "<b>FreeCharger</b>" by the Service Provider to facilitate their identification. The charger is equipped with a simple user manual and a QR code for unique identification of the charger and (once introduced) the IDRO identifier.
		</p>

		<p>
			The Service will be available at least 90% of the opening hours of the location where the electrical charger is physically available, except in the case of force majeure.
		</p>

		<p>
			The Service, or any part thereof, may be provided by the Service Provider or one of its commercial partners. The Service, or any part thereof, may be provided by the Service Provider or its commercial partner in its own name and for its own benefit, or in its own name and for the benefit of a third party.
		</p>

		<p>
			The Service Provider reserves the right to change the Technical Conditions if, subject to the discretion of the Service Provider, this is necessary due to technical progress or for the Service or the security of the Service.
		</p>

		<h3>
			Financial service providers
		</h3>

		<p>
			The Service Provider shall provide payment services to the User within the framework of the Service through various banks, financial service providers, payment system operators, financial institutions and other financial intermediaries (hereinafter referred to as "<b>Financial Service Providers</b>"), which are independent of the Service Provider but have contractual relationship with the Service Provider.
		</p>

		<p>
			<b>Financial Service Providers that may be used in connection with the Service:</b>
		</p>

		<p>
			<ul>
				<li><b>Simple Application and System</b>:<br/>
					operated by <b>OTP Mobil Kft.</b>, registration number: 01-09-174466; registered seat: 1143 Budapest, Hungária krt. 17-19.; hereinafter referred to as: <b>Simple</b></li>
			</ul>
		</p>

		<p>
			The payment services enable the User to initiate payments for the Service Provider regarding the Services used by the User. In order to use these payment services, the User shall also accept the contracts of the respective Financial Service Providers, which are separate from these GTC, as defined by the respective Financial Service Providers.
		</p>

		<p>
			The services provided by the Financial Service Providers are provided solely for the convenience of the User and shall not be considered as represented, approved or endorsed by the Service Provider. User may use the services provided by the Financial Service Providers solely at its sole discretion and at its own risk. The User does hereby acknowledge that the use of the services provided by the Financial Service Providers is not covered by these GTC. If the User uses such services, it is the User's responsibility to review, understand and accept the terms and conditions of use and policies of the service.
		</p>

		<p>
			The Service Provider assumes no responsibility for the use of the services provided by the Financial Service Providers. The use of the services provided by the Financial Service Providers is not covered by the Privacy Policy of the Service Provider. The use of the services provided by the Financial Service Providers, including services available through a link on the Service Provider's website or Application to a website or platform operated by Financial Service Providers, is subject to the terms of use and privacy statement of the respective service.
		</p>

		<h2>
			Registration to use the service
		</h2>

		<h3>
			Service Provider Application
		</h3>

		<p>
			The services provided by the Service Provider are available via the FreeCharger application for iOS and Android operating systems (registration is required) and via the FreeCharger website (<a href="https://freecharger.hu">https://freecharger.hu</a>). The Application can be downloaded and registered by anyone. To start charging a vehicle, the User shall create a personal user account in the Application. Registration is possible by registering one or more invoice addresses. Registration is free of charge. The registration will be confirmed by e-mail. The Service Provider will endeavor to provide the User with a continuous and uninterrupted service, but cannot guarantee that the Service will be available, continuous, acute or error-free in all cases. The User may access the Application using his/her Google or Facebook account.
		</p>

		<h3>
			The contract between the User and the Service Provider is concluded
		</h3>

		<ul>
			<li>by the User downloading the Service Provider's Application on his/her mobile phone and successfully registering in the Application, or</li>
			<li>by means of an Occasional Charge, also subject to registration, initiated on the Website.</li>
		</ul>

		<h3>
			Technical steps for the conclusion of the contract:
		</h3>

		<h4>
			In the Application:
		</h4>

		<ul>
			<li>Download the Application from the App Store or Google Play Store</li>
			<li>Install the Application</li>
			<li>Click on the login button on the main screen</li>
			<li>Enter and confirm your email address and password. The password must be at least 8 characters long and contain upper- and lowercase letters and a number</li>
			<li>the login can also be done with an existing Facebook or Google account, in which case the previous step of the registration can be skipped</li>
			<li>download and read the privacy policy and the general terms and conditions;</li>
			<li>click on the button next to the general terms and conditions and the privacy policy to confirm that you understand and accept them;</li>
		</ul>

		<h4>
			In case of an occasional charging:
		</h4>

		<ul>
			<li>opening the website <a href="https://freecharger.hu">https://freecharger.hu</a></li>
			<li>click on the button to start charging</li>
			<li>click on the login button on the main screen</li>
			<li>enter and confirm your email address and password. The password must be at least 8 characters long and contain upper- and lowercase letters and a number</li>
			<li>the login can also be done with an existing Facebook or Google account, in which case the previous step of the registration can be skipped</li>
			<li>download and read the privacy policy and the general terms and conditions;</li>
			<li>click on the button next to the general terms and conditions and the privacy policy to confirm that you understand and accept them;</li>
		</ul>

		<p>
			By registering online, the User accepts that the Service Provider will provide the services specified in these GTC online and that any information related to these services will be sent by the Service Provider to the registration e-mail address provided by the User. If the Service Provider decides to modify the GTC, the content of the modification will be sent to the User's registered email address or the modification of the GTC will be indicated in the Application, with the insertion of the current version. By using the System, the User also accepts any amendments to the GTC.
		</p>

		<p>
			The Service Provider reserves the right to refuse registration without giving any specific reason or to withdraw it temporarily or permanently at any time, in particular in the following cases:
		</p>

		<ul>
			<li>in case of false or incomplete data provided during registration.</li>
			<li>if the User has previously registered and whose contract has been terminated by the Service Provider for breach of contract.</li>
		</ul>

		<p>
			The User is obliged to ensure the functioning (maintenance) of the online contact details (e-mail address) registered by him/her. The Service Provider shall not be liable for any damage resulting from the provision of incorrect or non-functional online contact details (e-mail address), and the User shall be liable for any obligations towards the Service Provider.
		</p>

		<p>
			The Service Provider informs and reminds the User to change his/her password regularly and not to give it to anyone or store it in a place accessible to others. The safe storage of the password and the provision of the current online contact information (e-mail address) suitable for notifying the User is mandatory even when using the Application and is an event in the User's interest, the Service Provider shall not be liable for any damages resulting from the failure to do so, and the failure to do so shall not relieve the User from the performance of its obligations towards the Service Provider.
		</p>

		<p>
			The User shall be obliged to modify and update the data stored in the Profile and Invoicing Data sections of the application and the website in the event of any changes to his/her data.
		</p>

		<h3>
			Forgotten password
		</h3>

		<p>
			Before logging into the Application, the User can request a new password using the "Forgot password" function. The system will automatically send a message to the e-mail address provided at registration, which will contain a link to the Website or interface. By clicking on this link, the User can give a new password. In the event of an unsuccessful password change, the User may contact the Service Provider's Customer Service at <a href="mailto:info@freecharger.hu">info@freecharger.hu</a> for assistance. 
		</p>

		<h3>
			Profile details
		</h3>

		<p>
			The User can change his/her telephone number, email address and password used to log in by going to Profile. In the Profile menu you can also delete your profile. By deleting the profile, the user account is terminated and all personal data is irrevocably deleted. If the User has charged and has successfully completed the payment in the application or via the website, the invoicing data of this User will continue to be stored for tax purposes in accordance with the legislation in force until the legally required deadline. Once deleted, the deleted User account will no longer be logged in.
		</p>

		<h3>
			Password change
		</h3>

		<p>
			If the User is intended to change his/her password, he/she may enter a new password after logging in to the Application or the Website. An automatic confirmation of a successful password change will be sent. In case of unsuccessful password change, the User may contact the Service Provider's Customer Service for assistance.
		</p>

		<p>
			During the registration process, the User is obliged to provide the real data relating to him/her. If the Service Provider becomes aware that the data provided by the User (also) contains elements that are not real, the Service Provider is entitled to restrict access to the Application in whole or in part or to exclude the User from using the Application.
		</p>

		<h2>
			IT and technical conditions for the use of the Vehicle Charging Station, Use of the Charging Station
		</h2>

		<h3>
			IT conditions
		</h3>

		<p>
			To start charging
		</p>

		<ul>
			<li>In the case of occasional charging, by scanning the QR code on the charging device or by opening the Website from a web browser, which requires a smartphone and an internet connection at the same time, or</li>
			<li>after identification of the User, which requires a smartphone, the Application, valid registration and an internet connection at the same time</li>
		</ul>

		<h3>
			Technical conditions of the vehicle
		</h3>

		<p>
			Charging is available for vehicles capable of electric propulsion, plug-in hybrid or fully electric vehicles. The User shall be equipped with a technically suitable charging cable (if the selected charging point does not have a built-in charging cable). The charging point shall have one or two Type 2 connectors that can be used independently of each other. The charging mode used is Mode 3.
		</p>

		<p>
			CCS Combo or CHAdeMO connection used on charging posts with fixed cable. The charging mode used is Mode 4.
		</p>

		<p>
			The User shall ensure that the technical condition of the vehicle is satisfactory, in particular with regard to the protection of life and property.
		</p>

		<p>
			The maximum charging power per connector shall be: 22 kW for fast chargers and 22 kW for rapid chargers, as published in the Application or on the Website. The actual charging power depends on the type of vehicle connected and the type of charging cable used and the temperature and charge of the battery.
		</p>

		<p>
			The User shall ensure that the electric vehicle complies with the relevant charging standards (IEC 62196, IEC 61851), that the charging cable is certified by the manufacturer and that it is undamaged. The User is liable for any damage caused by a defective charging cable.
		</p>

		<p>
			The User must also check that the vehicle Charging starts on the correct side and at the correct letter and that it is completed correctly. The start and completion of the charging process is always determined by the communication between the car and the charger, which may take a few seconds after pressing the start/start or stop/stop button.
		</p>

		<p>
			To start the charging, the User must check the information on the charger display or on the description otherwise displayed on the charger. Charging starts when the User has connected the vehicle to the charging station according to the instructions after identification. The User shall ensure that the vehicle is compatible with the charging stations and that it can be charged at the station.
		</p>

		<h2>
			Obligations of the User
		</h2>

		<p>
			The User shall keep his registration password and keep it confidential for his own benefit. The User shall inform the Service Provider immediately if he suspects that a third party has gained access to or has known his password.
		</p>

		<p>
			Parking at the charging station is subject to the applicable traffic and private or public road use regulations and rules. If the charging point is located in an area subject to parking charges under local regulations, for which no exemption for electric vehicles is granted under local regulations or specific regulations made by the owner or tenant of the charging point area, the User shall pay the parking charge directly to the parking company or to the owner or tenant of the location via the application. The Service Provider shall not be liable for any failure to do so.
		</p>

		<p>
			The Service Provider shall inform the User of the locations where a parking fee is due, typically after the charging process has been completed, before the charging process starts. The start of the parking period is individually regulated and typically takes place after a 15-30 minutes grace period following the completion of the charging. In such cases, the parking period starts after the expiry of the grace period and is charged in a unit of HUF/hour, but charged on a per-minute basis. In locations where the parking fee is payable after the end of the charging period, the maximized parking fee before the start of the charging period will be charged to the credit card used for payment, but only in proportion to the time spent in the parking space, the amount of which will be notified to the User in the Application or on the website, or on the invoice or receipt for the electromobility service after the charging cable has been disconnected.
		</p>

		<p>
			The IT content of the Service is protected by copyright, trademark and/or other intellectual property rights.
		</p>

		<p>
			The Service Provider is the owner of all individual and proprietary copyrights in the Application as a copyright work in the form of computer programs. By accepting these GTC, the Service Provider authorizes the User to make non-exclusive, limited use of the Application.
		</p>

		<p>
			The User shall be entitled to use the Service only to the minimum extent necessary to enforce its rights and obligations under the present GTC.
		</p>

		<p>
			The User is not entitled to revise, modify, copy, reproduce, sell, transfer by any means, or decrypt codes in the Application.
		</p>

		<p>
			In the event of unlawful use of the Program, the Service Provider shall be entitled to claim damages and to initiate the appropriate proceedings to enforce the civil and criminal law consequences provided for in Act LXXVI of 1999 on Copyright.
		</p>

		<h2>
			Obligations of the service provider
		</h2>

		<p>
			The Service Provider shall display the User's Charging Transactions in near real time in the User's User Account, which the Service Provider shall make available to the registered User in the Application and on the Website. Charging transaction reports for each period can be viewed at any time.
		</p>

		<p>
			The Service Provider shall not be liable for any damages resulting from the User's improper use of the Application, the Service or the means necessary to use them, or from the User's failure to exercise due care in selecting the appropriate secure environment in which to use the Services. The Service Provider shall not be liable for any damage resulting from viruses or other similar infections of the User's computer system (including any smartphone).
		</p>

		<p>
			The Service Provider shall not be liable for the legality of any activity carried out by the Financial Service Providers or any third party in the course of the provision of the Services and the performance of any contract or non-contractual relationship between the User, the Financial Service Providers and any other third party. The Financial Service Providers or any other third party shall be solely responsible for compliance with the law and other rules and standards applicable to its activities, obtaining the necessary permits and notifications from the authorities.
		</p>

		<p>
			The Service Provider shall not be liable for any damage caused by a cause beyond the Service Provider's control or for any cause not in the interest of the Service Provider, in particular for any failure of telecommunication lines, Internet connection, data transmission systems or postal services.
		</p>

		<p>
			The Service Provider shall not be liable for any damage which the Service Provider is unable to prevent or influence, in particular damage resulting from force majeure, the orders of a national or foreign authority or the disruption of the Service Provider's operations, nor shall the Service Provider be liable for any damage if the Service Provider temporarily suspends or limits the Application, the Service or the access to or provision of the Service.
		</p>

		<p>
			The Service Provider shall not be liable for any damage suffered by the User as a result of any error or omission by the Financial Service Providers or any third party in the provision or performance of the Service. In the event of any dispute between the User and the Financial Service Providers or any third party, the User's liability or other obligation to the Service Provider shall not be affected by such dispute or counterclaim or right of set-off, which right the User may exercise only against the Financial Service Providers or any third party.
		</p>

		<p>
			The Service Provider shall not be liable for any events, circumstances, damages or other consequences that may result from:
		</p>

		<ul>
			<li>the fault of the User;</li>
			<li>failure of the User's mobile device or inability to use the Application;</li>
			<li>the failure of the operating system on the User's mobile device or the communication link used by the User;</li>
			<li>improper use of the Application or use otherwise in breach of these GTC;</li>
			<li>the non-receipt by the User's mail system of automatic (informative) system messages which can be proven to have been sent by the Application.</li>
		</ul>

		<p>
			The Service Provider does not assume any responsibility for the safe storage of the User's password or for any damage resulting from this.
		</p>

		<p>
			In addition to the foregoing, the Service Provider shall have no other warranties or other obligations for the accuracy, reliability, operation, completeness, fitness for a particular purpose or expectations, suitability, and any direct or indirect damages or other legal consequences arising out of or resulting from the lack thereof, the use of the Application. Furthermore, the Service Provider shall not be liable for any errors and their consequences arising from causes beyond its control (such as technical failures or power failures in the Internet network).
		</p>

		<p>
			By accepting these GTC, the parties agree in accordance with the provisions of Section 6:152 of the Civil Code, the Service Provider shall be liable for damages caused to the User in connection with the use of the Website and the Application, except for damages caused intentionally or to human life, bodily injury or health, up to the amount of the consideration for the Service affected by the event. The Parties further agree that the Service Provider's liability shall be limited to damages directly related to the activity specified in the Contract, and shall not cover indirect, unforeseeable, consequential damages or damages arising from the use of software provided by third parties.
		</p>

		<h2>
			Complaints management and investigation of payment transactions by the Service Provider
		</h2>

		<p>
			If the User wishes to submit a complaint, he/she may do so within the general limitation period from the date of the relevant purchase transaction. The complaint shall describe precisely the reason for disputing the characteristics of the transaction.
		</p>

		<p>
			Complaints regarding incorrect Charging fees will be handled and investigated by the Service Provider. If the Service Provider considers the complaint to be justified, it shall reimburse the amount concerned to the User without delay. If the complaint is rejected, the Service Provider shall notify the User of the outcome of the investigation, stating the reasons.
		</p>

		<p>
			If the Service Provider is unable to resolve the reported problem to the User's satisfaction and the User has purchased the Service Provider's products or services online:
		</p>

		<p>
			The European Commission has set up an online dispute resolution platform (ODR platform) specifically designed to help consumers resident in the European Union to resolve their complaints when they are unable to settle their disagreement with a trader or service provider established in the European Union from whom they have purchased a product or service online. You can submit your complaint in any of the official languages of the European Union online via the ODR platform.
		</p>

		<p>
			You can access the ODR platform at: <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>
		</p>

		<p>
			To log in, you need the Service Provider's e-mail address (<a href="mailto:info@freecharger.hu">info@freecharger.hu</a>), the address of its website (<a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a>) and the address of its registered seat (2100 Gödöllő, Remsey krt. 8.).
		</p>

		<h2>
			Service fee
		</h2>

		<p>
			The current tariffs for the use of the chargers operated by the Service Provider are available on the Service Provider's website and in the Application. The Service Provider reserves the right to unilaterally modify the Service fees at any time, after prior notification of the Users via the Service Provider's website or the Application.
		</p>

		<p>
			It is the User's responsibility to check the current and applicable tariffs for the Charging Service and the parking fee at certain Charging Points at the time of commencing the Charging.
		</p>

		<h2>
			Payment and invoicing
		</h2>

		<p>
			A Charge can only be initiated if the User has associated a invoicing address and a vehicle with his/her account in the application or on the website for the occasional Charge.
		</p>

		<p>
			When starting a charge, after entering the above data, the User shall specify the maximum time or the maximum amount to be spent on the charge. At this step, the User can specify the amount of the charge in kWh and, if applicable, when the charge should be completed.
		</p>

		<p>
			Based on the data provided, the system calculates the maximum amount to be used for the charge, which is then booked on the User's card. After the charge is completed, the maximum card charge value is the amount set in this step.
		</p>

		<p>
			Basis for calculation: the charging power consumed by the electric vehicle
		</p>

		<p>
			By accepting the payment by Bank Card, the User agrees and consents to the Service Provider charging his Bank Card for the Service used at the end of the charge.
		</p>

		<p>
			If the booking of the Service Fee on the User's Bank Card is unsuccessful before the start of the charge, the charge service will not be able to be started.
		</p>

		<p>
			Upon completion of the charge, the User will receive an automatic charge summary e-mail and can check his previous charges in the "My Charges" section of the Website and the Application.
		</p>

		<p>
			On Monday of each week, the User will receive an electronic summary invoice on his/her registered e-mail address for the previous week's Charges (if any).
		</p>

		<p>
			If the User experiences any technical problems during the payment and/or invoicing process related to the Service, he/she may report them to the Service Provider without delay, but preferably within 5 days at the latest, in order to remedy the problem and to identify the circumstances as efficiently as possible.
		</p>

		<h2>
			Termination by the User
		</h2>

		<p>
			These GTC may be terminated by the User at any time, with immediate effect, by deleting the registration.
		</p>

		<p>
			Upon deleting the registration, the User's Application profile will be automatically deleted. The Service Provider shall be entitled to store the receipts and invoices of already completed Charging Transactions until the expiry of the general limitation period.
		</p>

		<h2>
			Termination by the Service Provider
		</h2>

		<p>
			The Service Provider shall be entitled to terminate this Agreement with immediate effect, subject to the obligation to state reasons, if the User (i) fails to meet its payment obligations despite being requested to do so or (ii) uses the Service in a manner that is contrary to this Agreement. The Service Provider may also terminate this Agreement if the Service Provider has reasonable grounds to suspect that the User is using the Service inappropriately.
		</p>

		<p>
			The Service Provider may terminate this Agreement by giving one (1) month's notice, effective upon expiry of the existing subscription (if applicable).
		</p>

		<h2>
			Processing of personal data, security
		</h2>

		<p>
			The Service Provider shall be entitled to store, process and use the personal data provided by the User during the Occasional Charging or during registration in the Application or at any time thereafter, in connection with the Service, as set out in the Service Provider's Privacy Policy. The User is obliged to make all reasonable efforts to protect his/her personal data, in particular to protect the User's name, e-mail address and, in the case of both online interfaces, the password, in order to use the services provided by the Service Provider. The User shall be liable for any event, activity or omission that occurs through the use of his/her username or password.
		</p>

		<p>
			The security of the data connection between the mobile phone used by the User to connect to the Application and the Service Provider's server is ensured by the standard SSL (Secure Socket Layer) technology provided by browsers. The Application used by the User encrypts the User's data before sending it using SSL. In this way, they are transmitted to the Service Provider in encrypted form and cannot be interpreted by unauthorized persons.
		</p>

		<p>
			By accepting these GTC, the User acknowledges that the following personal data stored by Ready 2 Grow Kft. (registered seat: 2100 Gödöllő, Remsey krt. 8.) as data controller in the user database of <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> or the <b>FreeCharger</b> smartphone Application will be transferred to KBOSS.hu Kft. (1031 Budapest, Záhony utca 7.) as data processor. The scope of data transferred by the data controller is as follows: email address and invoicing data.
		</p>

		<p>
			The nature and purpose of the data processing activities performed by the data processor can be found in the Data Processing Information Notice, at the following link: <a href="https://www.szamlazz.hu/adatvedelem/" target="_blank">https://www.szamlazz.hu/adatvedelem/</a>
		</p>

		<p>
			The recurring payment by credit card (hereinafter referred to as "Recurring Payment") is a function of credit card acceptance provided by SimplePay, operated by OTP Mobil Szolgáltató Kft., which means that in the future, with the credit card data provided by the User (customer) during the registration transaction, new payments can be initiated without having to provide the credit card data again. The so-called "case-by-case consent" type of recurring payment is subject to the User's (customer's) case-by-case approval for each transaction, i.e. the User (customer) must approve the transaction for all future payments.
		</p>

		<p>
			In all cases, the User (customer) will be notified of a successful payment through the same channels as a traditional credit card payment.
		</p>

		<p>
			<b>Please note(!):</b> credit card data are managed in accordance with the card company's rules. The Service Provider has no access to the credit card data.
		</p>

		<p>
			The Service Provider is directly responsible for any recurring payment transactions initiated by the Service Provider in error or illegally, and any claims against the Service Provider's payment service provider (Simplepay) are excluded.
		</p>

		<p>
			The User declares that he/she has read, acknowledges and accepts the contents of the information provided in this section.
		</p>

		<h2>
			Other provisions
		</h2>

		<p>
			The present contract is a written contract between the Service Provider and the User. The Service Provider shall record the present contract and any amendments thereto, which shall be available on the Service Provider's website. The official language of this contract is Hungarian. The present GTC may be amended unilaterally by the Service Provider. The Service Provider shall publish the amendment on its website 15 days prior to its entry into force. The Service Provider shall publish the current version of the GTC on its website.
		</p>

		<p>
			14.2 The invalidity of any provision of these GTC shall not affect the validity and effect of the other provisions, and in the event of severability, the other parts of the contract shall remain in force unchanged.
		</p>

		<p>
			14.3 In matters not regulated in these GTC, the laws of Hungary, in particular Act V of 2013 on the Civil Code, Act CXII of 2011 on the Right of Informational Self-Determination and on freedom of Information, and the provisions of the legislation in force on vehicle charging, in particular Act I of 1988 on Road Traffic and the provisions of Government Decree 243/2019 (X. 22.) on certain issues of electromobility services, shall apply.
		</p>
	</div>
</template>

<script>
export default {
	name: "GtcENView",
	data() {
		return {
			showBackLink: history.length > 1 && !this.modal
		}
	},
	props: {
		modal: Boolean
	},
	methods: { scrollTo(refName) {
		var element = this.$refs[refName]
		var top = element.offsetTop
		window.scrollTo(0, top) 
	}
	}
}
</script>

<style scoped>
h1 {
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 30px;
}

h2 {
	margin-top: 2rem;
	text-transform: uppercase;
}

h3 {
	margin-top: 1rem;
}

h4 {
	font-size: 1.2rem;
}

table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>