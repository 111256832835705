<template>
	<transition leave-active-class="animate__animated animate__slideOutRight">
		<div class="location-panel-outer" @click.self="hide">

			<div class="btn-close-location animate__animated animate__bounceInRight">
				<button
					@click="hide"
					class="location-panel-close mr-auto btn btn-danger rounded-circle d-flex align-items-center flex-clumn"
				>
					<font-awesome-icon icon="times" class="fa-lg mx-auto"/>
				</button>
			</div>

			<div
				class="location-panel animate__animated animate__bounceInRight"
				:class="{
					[tab]: true
				}"
			>
				<div style="">
					<img :src="logo" class="rounded" style="max-width: 150px;min-height: 50px">
				</div>
				<div class="media">
					<div class="media-body pr-3">
						<h3 style="margin-right: 35px;">{{ locationDataWithChargers.name }}</h3>
						<p v-if="locationDataWithChargers.address">{{ locationDataWithChargers.address.full }}</p>
					</div>
				</div>

				<div class="nav nav-tabs">
					<div class="nav-item">
						<div
							class="nav-link"
							:class="{
								'active': tab === 'parking',
							}"
							@click="changeTab('parking')"
							v-if=" hasParking"
						>{{ $t('location-panel.parking') }}</div>
					</div>
					<div class="nav-item">
						<div
							class="nav-link"
							:class="{
								'active': tab === 'charger',
							}"
							@click="changeTab('charger')"
							v-if="hasCharging"
						>{{ $t('location-panel.chargers') }}</div>
					</div>
					<div class="nav-item">
						<div
							class="nav-link"
							:class="{
								'active': tab === 'poi',
							}"
							@click="changeTab('poi')"
							v-if="hasPois"
						>{{ $t('location-panel.services') }}</div>
					</div>
				</div>

				<div v-if="tab === 'parking'">
					<LocationPanelParking
						v-if="locationParkingLots.length > 0"
						v-for="parkingLot in locationParkingLots"
						:key="parkingLot.id"
						:parkingLot="parkingLot"
						:loggedInUserData="loggedInUserData"
						@hideLocationPanel="hide"
						@showStartParking="showStartParking"
						class="location-panel-charger rounded"
					/>
					<div v-if="locationParkingLots === null || locationParkingLots.length === 0">
						<div class="text-center" style="margin-top: 10px">{{ $t('location-panel.no-parking') }}</div>
					</div>
				</div>

				<div v-if="tab === 'charger'">
					<locationPanelCharger
						v-if="locationDataWithChargers.chargers.length > 0"
						v-for="charger in locationDataWithChargers.chargers"
						:key="charger.id"
						:charger="charger"
						:loggedInUserData="loggedInUserData"
						@hideLocationPanel="hide"
						@showStartCharging="showStartCharging"
						class="location-panel-charger rounded"
					/>
					<div v-if="locationDataWithChargers === null || locationDataWithChargers.chargers.length === 0">
						<div class="text-center" style="margin-top: 10px">{{ $t('location-panel.no-charger') }}</div>
					</div>
				</div>

				<div v-if="tab === 'poi'" class="poi-content my-4">
					<div class="poi-card-container">
						<div class="card poi-card" v-for="poi in locationDataWithPois.pois" :key="poi.name"  style="background-color: #ffffff;">
							<img v-if="poi.pictureUrl" class="card-img-top poi-card-img" :src="poi.pictureUrl">
							<div class="card-body">
								<h3 class="card-title text-center poi-name">{{ poi.name }}</h3>

								<div v-html="poi.description" v-if="poi.description" class="poi-description" />

								<table class="table poi-table">
									<tbody>
										<tr v-if="poi.url">
											<td>{{ $t('location-panel.website') }}</td>
											<td><a :href="poi.url" target="_blank" style="word-break: break-word;">{{ poi.url }}</a></td>
										</tr>
										<tr>
											<td>{{ $t('location-panel.point-accepting') }}</td>
											<td>{{ poi.acceptPoint ? 'Igen' : 'Nem' }}</td>
										</tr>
										<tr v-if="poi.discountPercentage">
											<td>{{ $t('location-panel.discount') }}</td>
											<td>{{ poi.discountPercentage }} %</td>
										</tr>
										<tr>
											<td>{{ $t('location-panel.categories') }}</td>
											<td>
												<button
													class="poi-category-tag"
													v-for="category in poi.category"
													:key="category.id"
													@click="onCategoryClick(category)"
												>
													{{ category.name }}
												</button>
											</td>
										</tr>
										<tr v-for="tv in poi.traits" :key="tv.trait.id">
											<td>{{ tv.trait.name }}:</td>
											<td>
												<TraitValue :tv="tv" />
											</td>
										</tr>
									</tbody>
								</table>

							</div>
						</div>
						<div v-if="locationDataWithPois === null || locationDataWithPois.pois.length===0">
							<div class="text-center">{{ $t('location-panel.no-service') }}</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</transition>
</template>

<script>
import LocationPanelCharger from '@/components/LocationPanelCharger.vue'
import LocationPanelParking from "@/components/LocationPanelParking.vue"

export default {
	name: 'LocationPanel',
	data() {
		return {
			tab: 'parking',
		}
	},
	computed: {
		isParkingTabActive() {
			return this.tab === 'parking'
		},
		isChargerTabActive() {
			return this.tab === 'charger'
		},
		isPoiTabActive() {
			return this.tab === 'poi'
		},
		hasParking() {
			return this.locationParkingLots !== null && this.locationParkingLots.length !== 0
		},
		hasCharging() {
			return this.locationDataWithChargers.chargers.length > 0
		},
		hasPois() {
			return this.locationDataWithPois.pois.length > 0
		},
		logo() {
			if(this.tab === 'parking') {
				return require(`@/assets/parking_logo_white.svg`)
			}
			return require(`@/assets/logo_white.svg`)
		}
	},
	props: {
		'locationDataWithChargers': Object,
		'locationParkingLots': Array,
		'locationDataWithPois': Object,
		'loggedInUserData': Object,
		defaultTab: String
	},
	components: {
		LocationPanelParking,
		LocationPanelCharger
	},
	mounted() {
		this.tab = this.defaultTab || (this.hasParking ? 'parking' : this.hasCharging ? 'charger' : 'poi')
	},
	methods: {
		changeTab(tabName) {
			this.tab = tabName
		},
		hide() {
			this.$emit('hide')
		},
		showStartParking(parkingLot) {
			this.$router.push({name: 'parkingLot', params: {parkingLotId: parkingLot}})
		},
		showStartCharging(chargerId) {
			this.$router.push({name: 'charger', params: {chargerId: chargerId}})
		}
	},
	emits: ['hide'],
}
</script>

<style>
.location-panel-outer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1021;
}

.location-panel {
  background-color: rgba(8, 182, 71, 0.95);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  min-width: 320px;
  padding: 1rem;
  color: #ffffff;
  overflow: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.75);
}

.location-panel.parking {
  background-color: rgba(13, 146, 187, 0.95);
}

.location-panel.charger {
  background-color: rgb(8, 182, 71);
}

.location-panel.poi {
  background-color: rgba(38, 42, 171, 0.95);

}


.location-panel-close {
  width: 40px;
  height: 40px;
}

.location-panel-charger {
  padding: 1rem;
  background-color: #ffffff;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
}

.location-panel-charger:last-child {
  margin-bottom: 0;
}

.btn-close-location {
  position: fixed;
  right: 2vw;
  top: 15px;
  z-index: 100000;
}

</style>
