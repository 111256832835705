<template>
	<div class="">
		<div v-if="!showAuthorize && !errorText">
			<div class="d-flex justify-content-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					style="margin: auto; display: block; shape-rendering: auto;"
					width="100px"
					height="100px"
					viewBox="0 0 100 100"
					preserveAspectRatio="xMidYMid"
				>
					<circle
						cx="50"
						cy="50"
						fill="none"
						stroke="#06b647"
						stroke-width="8"
						r="29"
						stroke-dasharray="136.659280431156 47.553093477052"
					>
						<animateTransform
							attributeName="transform"
							type="rotate"
							repeatCount="indefinite"
							dur="0.7042253521126761s"
							values="0 50 50;360 50 50"
							keyTimes="0;1"
						></animateTransform>
					</circle>
				</svg>
			</div>
		</div>
		<div class="loginCard" v-if="showAuthorize && !errorText">
			<form>
				<div class="d-flex">
					<h6>{{$t('authorize.continue-to', {name: clientName})}}</h6>
				</div>
				<div class="d-flex flex-row align-items-center">
					<div class="text-center login--btn mr-2">
						<button @click="authorize" class="btn btn-success" type="button" v-on:keyup.enter="login" style="min-width: 280px;">
							<font-awesome-icon icon="check"/>
							{{ $t('authorize.authorize') }}
						</button>
					</div>
					<div class="text-center login--btn ml-2">
						<button @click="authorize" class="btn btn-danger" type="button" v-on:keyup.enter="login" style="min-width: 280px;">
							<font-awesome-icon icon="times"/>
							{{ $t('authorize.unauthorize') }}
						</button>
					</div>
				</div>

			</form>
		</div>
		<div v-if="errorText" class="text-center">
			{{$t('authorize.something-went-wrong')}}
		</div>
	</div>
</template>

<script>
import GoogleLogin from 'vue-google-login'
import { getParameterByName } from '../main'

export default {
	name: 'AuthorizeView',
	data() {
		return {
			clientName: "",
			showAuthorize: false,
			errorText: null,
			clientId: null,
			state: null
		}
	},
	methods: {
		authorize() {
			this.showAuthorize = false
			this.$rest.getOAuthCode(
				this.clientId, this.state,
				(data) => {
					if(data.result){
						window.location.replace(data.data)

						if('ios' === window.platform || 'android' === window.platform) {
							this.$router.replace('/')
						}
					}
				}, (error) => {
					this.errorText = "authorize.something-went-wrong"
				})
		}
	},
	mounted() {
		this.clientId = getParameterByName('clientId')
		this.state = getParameterByName('state')

		if(!this.clientId) {
			this.errorText = "authorize.something-went-wrong"
			return
		}

		this.$rest.getOAuthClient(
			this.clientId,
			(data) => {
				//console.log(data);
				if(data.result){
					this.clientName = data.data.name
					if(!data.data.showAuthorizePage) {
						return this.authorize()
					}
					this.showAuthorize = true
				}
				else {
					this.errorText = "authorize.something-went-wrong"
				}
			}, (error) =>{
				this.errorText = "authorize.something-went-wrong"

			})
	},
	components: {

	},
}
</script>

<style>
.loginCard {
	width: 100%;
	max-width: 640px;
	margin: 0 auto;
}

.login--btn{
  margin-top: 0.4rem;
}
</style>
