<template>
	<div class="wallet-outer">
		<div class="wallet cc">
			<div v-if="card.cardMask" class="wallet-header cc">{{ card.cardMask }}</div>
			<div v-if="!card.cardMask" class="wallet-header cc">{{ $t('cards.processing') }}</div>
			<div class="default-card-title">
				<span v-if="card.defaultCard">{{ $t('cards.default-card') }}</span>
			</div>
			<div class="wallet-bottom">
				<button class="card-delete2 btn btn-outline-danger" @click="deleteCard(card)">
					<font-awesome-icon icon="trash" />
				</button>
				<button v-if="!card.defaultCard && card.cardMask" class="card-default btn btn-outline-primary ml-2" @click="changeDefaultCard(card)">
					<font-awesome-icon icon="check" />
				</button>
				<font-awesome-icon v-if="!card.cardMask" icon="spinner" size="2x" class="fa-spin mx-auto mb-2 card-spinner" />
				<span v-if="card.cardMask" class="card-expiry-cv">{{ formatDate(card.expiry) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CreditCard',
	props: {
		card: Object
	},
	methods: {
		deleteCard(card) {
			let text
			if(card.cardMask) {
				text = this.$t('cards.confirmDelete') + '\n\n' + card.cardMask + '\n' + this.formatDate(card.expiry)
			}
			else {
				text = this.$t('cards.confirmDelete2')
			}

			if(confirm(text)) {
				this.$rest.removeCreditCard(card.cardId, resp => {
					this.$emit('changed')
				})
			}
		},
		
		changeDefaultCard(card) {
			this.$rest.changeDefaultCreditCard(card.cardId, resp => {
				this.$emit('changed')
			})
		},

		formatDate(dateStr) {
			const date = new Date(dateStr)
			const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}`
			return formattedDate
		}
	},
	emits: ['changed']
}
</script>