<template>
	<div class="alert alert-warning mt-2 mx-1" style="font-size: 1.1em; text-align: center">
		<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2"/>
		<span class="mx-1" style="font-size: 1.5em">{{ $t('noResult') }}</span>
	</div>
</template>

<script>
export default {
	name: 'NoResult'
}
</script>
