import Vue from 'vue'
import Router from 'vue-router'
import LoginView from './views/LoginView.vue'
import AuthorizeView from './views/AuthorizeView.vue'
import RegisterView from './views/RegisterView.vue'
import ForgottenPasswordView from './views/ForgottenPasswordView.vue'
import MapView from './views/MapView.vue'
import BillingDataView from './views/BillingDataView.vue'
import ReceiptView from "@/views/ReceiptView"
import PaymentResultView from "@/views/PaymentResultView"
import PrepaidPaymentResultView from "@/views/PrepaidPaymentResultView"
import ChargerView from "@/views/ChargerView"
import ProfileView from "@/views/ProfileView"
import CardsView from "@/views/CardsView"
import CarView from "@/views/CarView"
import PrivacyStatementView from "@/views/PrivacyStatementView"
import PrivacyStatementENView from "@/views/PrivacyStatementENView"
import GtcView from "@/views/GtcView"
import GtcENView from "@/views/GtcENView"
import ChargeHistoryView from "@/views/ChargeHistoryView"
import CouponView from "@/views/CouponView"
import ChargerSearch from "@/views/ChargerSearch"
import RegistrationResultView from "@/views/RegistrationResultView"
import AboutUsView from "./views/AboutUsView"
import OpenSourceSoftwareView from "./views/OpenSourceSoftwareView"
import SupportView from "@/views/SupportView"
import WelcomeWizardView from "./views/wizard/WelcomeWizardView"
import ProfileWizardView from "./views/wizard/ProfileWizardView"
import BillingWizardView from "./views/wizard/BillingWizardView"
import CarsWizardView from "./views/wizard/CarsWizardView"
import CreditCardsWizardView from "./views/wizard/CreditCardsWizardView"
import InvoicesView from "./views/InvoicesView"
import FontAwesomeLicence from "./views/licences/FontAwesomeLicenseView"
import ApacheLicenseView from "./views/licences/ApacheLicenseView"
import VueLicenseView from "./views/licences/VueLicenseView"
import PopperLicenseView from "./views/licences/PopperLicenseView"
import UrlSearchParamsPolyfillView from "./views/licences/UrlSearchParamsPolyfillView"
import AnimateCssView from "./views/licences/AnimatCssView"
import AxiosLicenseView from "./views/licences/AxiosLicenseView"
import FlickingPluginView from "./views/licences/FlickingPluginView"
import JqueryLicenseView from "./views/licences/JqueryLicenseView"
import VuexLicenseView from "./views/licences/VuexLicenseView"
import NewsView from './views/NewsView'
import NewsPostView from './views/NewsPostView'
import ParkingLotView from "@/views/ParkingLotView.vue"
import ParkingHistoryView from "@/views/ParkingHistoryView.vue"
import ParkingPaymentResultView from "@/views/ParkingPaymentResultView.vue"
import ParkingSurchargeView from "@/views/ParkingSurchargeView.vue"
import ParkingSurchargePaymentResultView from "@/views/ParkingSurchargePaymentResultView.vue"

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			name: 'index',
			component: MapView,
			meta: {
				title: 'pageTitle.index',
				fullscreen: true
			}
		},
		{
			path: '/authorize',
			name: 'authorize',
			component: AuthorizeView,
			meta: {
				title: 'pageTitle.authorize',
				loginRequired: 'true',
				canRedirectHereAfterLogin: true,
				disableMainMenu: true
			}
		},
		{
			path: '/login',
			name: 'login',
			component: LoginView,
			meta: {
				title: 'pageTitle.login'
			}
		},
		{
			path: '/register',
			name: 'register',
			component: RegisterView,
			meta: {
				title: 'pageTitle.register'
			}
		},
		{
			path: '/forgotten-password',
			name: 'forgotten-password',
			component: ForgottenPasswordView,
			meta: {
				title: 'pageTitle.forgottenPassword'
			}
		},
		{
			path: '/charger/:chargerId',
			name: 'charger',
			component: ChargerView,
			meta: {
				title: 'pageTitle.charger',
				canRedirectHereAfterLogin: true
			}
		},
		{
			path: '/parking-lot/:parkingLotId',
			name: 'parkingLot',
			component: ParkingLotView,
			meta: {
				title: 'pageTitle.parking',
				canRedirectHereAfterLogin: true
			}
		},
		{
			path: '/receipt',
			name: 'receipt',
			component: ReceiptView,
			meta: {
				title: 'pageTitle.receipt',
				loginRequired: 'true'
			}
		},
		{
			path: '/payment-result',
			name: 'payment-result',
			component: PaymentResultView,
			meta: {
				title: 'pageTitle.paymentresult',
				enabledAtCasualCharge: true
			}
		},
		{
			path: '/registration-result',
			name: 'registration-result',
			component: RegistrationResultView,
			meta: {
				title: 'pageTitle.registrationresult',
			}
		},
		{
			path: '/billing',
			name: 'billing',
			component: BillingDataView,
			meta: {
				title: 'pageTitle.billing',
				loginRequired: true
			}
		},
		{
			path: '/map/:lat/:lng/:findroute',
			name: 'map-lat-long-route',
			component: MapView,
			meta: {
				title: 'pageTitle.map',
				fullscreen: true
			}
		},
		{
			path: '/map/:lat/:lng',
			name: 'map-lat-long',
			component: MapView,
			meta: {
				title: 'pageTitle.map',
				fullscreen: true
			}
		},
		{
			path: '/about-us',
			name: 'aboutus',
			component: AboutUsView,
			meta: {
				title: 'pageTitle.aboutus',
				fullscreen: false
			}
		},
		{
			path: '/open-source',
			name: 'openSource',
			component: OpenSourceSoftwareView,
			meta: {
				title: 'pageTitle.aboutus',
				fullscreen: false
			}
		},
		{
			path: '/map',
			name: 'map',
			component: MapView,
			meta: {
				title: 'pageTitle.map',
				fullscreen: true
			}
		},
		{
			path: '/charger-search/charger',
			name: 'charger-search-charger',
			component: ChargerSearch,
			props: {
				default: true,
				type: 'charger'
			},
			meta: {
				title: 'pageTitle.chargersearch'
			}
		},
		{
			path: '/charger-search/service',
			name: 'charger-search-service',
			component: ChargerSearch,
			props: {
				default: true,
				type: 'service'
			},
			meta: {
				title: 'pageTitle.chargersearch'
			}
		},
		{
			path: '/charger-search',
			name: 'charger-serach',
			component: ChargerSearch,
			meta: {
				title: 'pageTitle.chargersearch'
			}
		},
		{
			path: '/me',
			name: 'me',
			component: ProfileView,
			meta: {
				title: 'pageTitle.me',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			path: '/charge-history',
			name: 'charge-history',
			component: ChargeHistoryView,
			meta: {
				title: 'pageTitle.chargeHistory',
				loginRequired: true,
				fullscreen: false,
				enabledAtCasualCharge: true
			}
		},
		{
			path: '/parking-history',
			name: 'parking-history',
			component: ParkingHistoryView,
			meta: {
				title: 'pageTitle.parkingHistory',
				loginRequired: false,
				fullscreen: false,
				enabledAtCasualCharge: true
			}
		},
		{
			path: '/coupons',
			name: 'coupons',
			component: CouponView,
			meta: {
				title: 'pageTitle.coupons',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			path: '/news',
			name: 'news',
			component: NewsView,
			meta: {
				title: 'pageTitle.news',
				fullscreen: false
			}
		},
		{
			name: 'newspost',
			path: '/news/:id',
			component: NewsPostView,
			meta: {
				title: 'pageTitle.news',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			path: '/cards',
			name: 'cards',
			component: CardsView,
			meta: {
				title: 'pageTitle.cards',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			path: '/gtc-hu',
			name: 'gtc-hu',
			component: GtcView,
			meta: {
				title: 'pageTitle.gtc',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			path: '/gtc',
			name: 'gtc',
			component: GtcENView,
			meta: {
				title: 'pageTitle.gtc',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			path: '/privacy-statement-hu',
			name: 'privacy-statement-hu',
			component: PrivacyStatementView,
			meta: {
				title: 'pageTitle.privacyStatement',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			path: '/privacy-statement',
			name: 'privacy-statement',
			component: PrivacyStatementENView,
			meta: {
				title: 'pageTitle.privacyStatement',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			path: '/parking-surcharge',
			name: 'parking-surcharge',
			component: ParkingSurchargeView,
			meta: {
				title: 'pageTitle.parkingSurcharge',
				loginRequired: false,
			}
		},
		{
			path: '/support',
			name: 'support',
			component: SupportView,
			meta: {
				title: 'pageTitle.support',
				loginRequired: false,
			}
		},
		{
			path: '/cars',
			name: 'cars',
			component: CarView,
			meta: {
				title: 'pageTitle.cars',
				loginRequired: true,
			}
		},
		{
			path: '/welcome',
			name: 'welcome',
			component: WelcomeWizardView,
			meta: {
				title: 'pageTitle.welcome',
				loginRequired: true,
				wizardView: true
			}
		},
		{
			path: '/profile-wizard',
			name: 'profileWizard',
			component: ProfileWizardView,
			meta: {
				title: 'pageTitle.profileWizard',
				loginRequired: true,
				wizardView: true
			}
		},
		{
			path: '/billing-wizard',
			name: 'billingWizard',
			component: BillingWizardView,
			meta: {
				title: 'pageTitle.billingWizard',
				loginRequired: true,
				wizardView: true
			}
		},
		{
			path: '/cars-wizard',
			name: 'carsWizard',
			component: CarsWizardView,
			meta: {
				title: 'pageTitle.carsWizard',
				loginRequired: true,
				wizardView: true
			}
		},
		{
			path: '/cards-wizard',
			name: 'creditCardsWizard',
			component: CreditCardsWizardView,
			meta: {
				title: 'pageTitle.creditCardsWizard',
				loginRequired: true,
				wizardView: true
			}
		},
		{
			path: '/privacy-statement-wizard',
			name: 'privacyStatementWizard',
			component: PrivacyStatementView,
			meta: {
				title: 'pageTitle.privacyStatement',
				wizardView: true
			}
		},
		{
			path: '/invoices',
			name: 'invoices',
			component: InvoicesView,
			meta: {
				title: 'pageTitle.invoices',
				loginRequired: 'true'
			}
		},
		{
			path: '/prepaid-payment-result',
			name: 'prepaid-result',
			component: PrepaidPaymentResultView,
			meta: {
				title: 'pageTitle.prepaidResult',
				loginRequired: 'true'
			}
		},
		{
			path: '/parking-payment-result',
			name: 'parking-result',
			component: ParkingPaymentResultView,
			meta: {
				title: 'pageTitle.parkingResult',
			}
		},
		{
			path: '/parking-surcharge-payment-result',
			name: 'parking-surcharge-result',
			component: ParkingSurchargePaymentResultView,
			meta: {
				title: 'pageTitle.parkingResult',
			}
		},
		{
			path: '/font-license',
			name: 'font-licence',
			component: FontAwesomeLicence,
			meta: {
				title: 'pageTitle.font-licence',
			}
		},
		{
			path: '/apache-license',
			name: 'apache-licence',
			component: ApacheLicenseView,
			meta: {
				title: 'pageTitle.apache-licence',
			}
		},
		{
			path: '/vue-license',
			name: 'vue-licence',
			component: VueLicenseView,
			meta: {
				title: 'pageTitle.vue-licence',
			}
		},
		{
			path: '/popper-license',
			name: 'popper-licence',
			component: PopperLicenseView,
			meta: {
				title: 'pageTitle.popper-licence',
			}
		},
		{
			path: '/url-search-params-polyfill-license',
			name: 'url-search-params-polyfill-licence',
			component: UrlSearchParamsPolyfillView,
			meta: {
				title: 'pageTitle.url-search-params-polyfill-licence',
			}
		},
		{
			path: '/animateCss-license',
			name: 'animateCss-licence',
			component: AnimateCssView,
			meta: {
				title: 'animateCss-licence',
			}
		},
		{
			path: '/axios-license',
			name: 'axios-licence',
			component: AxiosLicenseView,
			meta: {
				title: 'axios-licence',
			}
		},
		{
			path: '/flicking-plugins-license',
			name: 'flicking-plugins-licence',
			component: FlickingPluginView,
			meta: {
				title: 'flicking-plugins-licence',
			}
		},
		{
			path: '/jquery-license',
			name: 'jquery-licence',
			component: JqueryLicenseView,
			meta: {
				title: 'jquery-licence',
			}
		},
		{
			path: '/vuex-license',
			name: 'vuex-licence',
			component: VuexLicenseView,
			meta: {
				title: 'vuex-licence',
			}
		}
	]
})
