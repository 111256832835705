<template>
	<input
		:id="id"
		type="text"
		:value="text"
		@change="change"
		@input="$emit('input', $event.target.value)"
	/>
</template>

<script>

export default {
	name: 'TextInput',
	data() {
		return {
			text: ''
		}
	},	  
	props: {
		id: String,
		value: String,
		msgs: Object,
		required: Boolean
	},
	mounted() {
		this.text = this.value
	},
	watch: {
		value(v) {
			this.text = v
		}
	},
	methods: {
		input(event) {
			this.text = event.target.value
		},
		change(event) {
			var val = event.target.value.trim()

			if(!val && this.required) {
				event.target.value = val
				this.text = val
				this.$emit('change', val)
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}])
				return
			}

			event.target.value = val
			this.text = val
			this.$emit('change', val)
			this.$set(this.msgs, this.id, [])
		}
	},
	emits: ['input', 'change']
}
</script>