<template>
	<div>
		<div v-if="parkingLot">
			<div class="alert alert-info align-items-center" v-if="loggedInUserData && loggedInUserData.casualUser">
				<font-awesome-icon icon="circle-notch" class="fa-spin fa-lg fa-fw mr-2" />
				{{ $t('parking-view.casual-parking-info') }}
			</div>
			<template v-else>
				<div class="media align-items-center">
					<div class="media-body text-center">
						<div class="font-weight-bold">{{ parkingLot.name }}</div>
					</div>
				</div>

				<small v-if="parkingLot.location" class="text-center d-block">{{ parkingLot.location.address.full }}</small>

				<div v-if="parkingLot.tariff" class="text-center">
					<small v-if="parkingLot.tariff.freeParking" class="text-success">{{ $t('parkingView.free') }}</small>
					<small v-else class="text-danger">{{ $t('location-panel.price-parking', [parkingLot.tariff.grossUnitParking]) }}</small>
				</div>

				<button class="parking-info-toggle-button-2 mb-4 text-center d-block btn btn-outline-success" style="margin-top: -8px;" @click="toggleInfo(parkingLot.id)">
					<font-awesome-icon icon="chevron-down" class="mr-1 animated-flip" :class="{ 'do-flip': showInfo }" />
					<template v-if="showInfo">
						<small>{{ $t('location-panel.hide-details') }}</small>
					</template>
					<template v-else>
						<small>{{ $t('location-panel.more-details') }}</small>
					</template>
				</button>

				<div id="parkingDetails" class="collapse border p-2 mb-3">
					<div>
						<div v-if="this.tariffLines.length > 0" class="mt-2">{{ $t('location-panel.parking-tariff') }}:</div>
						<ul class="mb-0">
							<li v-for="tl in this.tariffLines" :key="tl">{{ tl }}</li>
						</ul>
					</div>

					<div class="row">
						<div class="col-md-6 col-xl-4" v-for="tv in traitDetails" :key="tv.trait.id">
							<hr />
							{{ tv.trait.name }}:
							<TraitValue :tv="tv" />
						</div>
					</div>
				</div>

				<div class="alert alert-warning" v-if="!parkingLot.tariff">
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('parking-view.error-no-tariff') }}
				</div>


				<Button v-else-if="canStart" @click="startParking" class="btn btn-success btn-block btn-lg">
					<font-awesome-icon icon="play" class="fa-lg mr-2" style="vertical-align: middle; margin-bottom: 2px;" />
					{{ $t('parkingView.startParking') }}
				</Button>

			</template>
		</div>
		<div v-else-if="showNoParkingInfo">
			<div class="alert alert-warning">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
				{{ $t('parking-view.no-parking-found') }}
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery'
import LocationPanelParking from '@/components/LocationPanelParking.vue'
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue'
import * as utils from '@/plugins/utils.js'
import ChargeFinishTimeTemplate from '../components/ChargeFinishTimeTemplate'
import ChargeMaxKwTemplate from '../components/ChargeMaxKwTemplate'
import { mapGetters } from 'vuex'
import TraitValue from '@/components/TraitValue.vue'
import ChargeStartArea from '@/components/ChargeStartArea.vue'
import CasualChargingSurvey from '@/components/CasualChargingSurvey.vue'
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue'
import CasualParkingSurvey from "@/components/CasualParkingSurvey.vue"

export default {
	name: 'GateView',
	data() {
		return {
			showNoParkingInfo: false,
			parkingLot: null,
			parkingLotId: null,
			parking: null,
			traitDetails: [],
			showInfo: false,
		}
	},
	props: {
		msgs: Object,
		app: Object
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	computed: {
		tariffLines() {
			let result = []
			if(!(this.parkingLot && this.parkingLot.tariffs)) {
				return result
			}
			for(let t of this.parkingLot.tariffs) {
				if(!t.descriptionLines) {
					continue
				}
				for(let dl of t.descriptionLines) {
					result.push(dl)
				}
			}
			return result
		},
		canStart() {
			return this.parkingLot.tariff && this.parkingLot.tariff.freeParking || this.parkingLot.tariff && this.parkingLot.tariff.grossUnitParking > 0
		},
	},
	methods: {
		async startParking() {
			if((this.canStart && !this.loggedInUserData) || (this.loggedInUserData || {}).casualUser) {
				const resp = await this.$rest.startCasualParking(this.parkingLotId, this.email)
				//console.log(resp);
				if(resp ==null) {
					alert(this.$t('casual-parking.errorStart'))
					return
				}

				if(!resp.result) {
					return
				}

				if(resp.extraData) {
					localStorage.setItem('loggedInAccessToken', resp.extraData.extraData)
					this.$ws.connect()
				}


				this.$router.push({name: 'parking-history'})
				return
			}


			this.parking = await this.$rest.startParking(this.parkingLotId)
			this.$router.push({name: 'parking-history'})
		},
		toggleInfo() {
			this.showInfo = !this.showInfo
			jQuery('#parkingDetails').collapse(this.showInfo ? 'show' : 'hide')
		},
		loadParkingId() {
			if(this.$route.query.parkingLotId) {
				this.parkingLotId = this.$route.query.parkingLotId
			}
			else {
				this.parkingLotId = this.$route.params.parkingLotId
			}
		},
		async onLoad() {
			if(!this.parkingLotId) {
				alert(this.$t('casualSurvey.somethingWentWrong'))
				this.$router.push({path: '/parking-search/parking'})
				return
			}


			this.parkingLot = await this.$rest.getParkingLot(this.parkingLotId)
			if(!this.parkingLot) {
				this.showNoParkingInfo = true
				return
			}
			this.getParkingProperties()

		},
		getParkingProperties() {
			this.traitDetails = []

			for(let trait of this.parkingLot.staticTraits) {
				if(trait.trait.major) {
					this.traitDetails.push(trait)
				}
			}

			this.traitDetails = this.traitDetails.filter(tv => !this.isTraitValueEmpty(tv))
			this.traitDetails.sort((tv1, tv2) => tv1.trait.orderNumber - tv2.trait.orderNumber)
		},
		isTraitValueEmpty(tv) {
			if(!tv || !tv.trait || !tv.trait.type) {
				return true
			}
			if(tv.trait.type === 'TEXT' && !tv.value) {
				return true
			}
			if(tv.trait.type === 'LIST_SINGLE' && !tv.listItem) {
				return true
			}
			if(tv.trait.type === 'LIST_MULTIPLE' && !tv.listItems) {
				return true
			}
			if(tv.trait.type === 'LIST_CUSTOM' && !tv.listStrings) {
				return true
			}
			return false
		},
	},
	async mounted() {
		this.loadParkingId()
		if(this.$route.query.autoStart === '1') {
			await this.startParking()
		}
		await this.onLoad()

	},
	watch: {
		//this seems to be not working
		$route(to, from) {
			if(to.name === 'parking') {
				this.onLoad()
			}
		},
		loggedInUserData(newValue, oldValue) {
			if(!newValue) {
				this.$router.push({ name: 'login' })
			}
		}
	},
	components: {
		CasualParkingSurvey,
		LocationPanelParking,
		TraitValue,
		ParkingAlertPanel
	}
}
</script>

<style>
.step-number {
	font-size: 1.8rem;
	font-weight: bold;
	margin-right: 1rem;
	border-radius: 50%;
	border: 1px solid #333333;
	color: #ffffff;
	background-color: #509cd5;
	width: 2.7rem;
	height: 2.7rem;
	line-height: 2.5rem;
	text-align: center;
	display: inline-block;
	pointer-events: none;
	vertical-align: middle;
	position: relative;
	padding-right: 0.2rem;
}

.step-number:after {
	content: '.';
	position: absolute;
}

.step-list {
	margin-left: 1rem;
}

.time-bubble-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: relative;
	margin: 0.25rem 0;
}

.time-bubble-row:before {
	content: '';
	display: block;
	border-top: 1px solid #333333;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transform: translateY(50%);
}

.time-bubble {
	display: inline-block;
	border: 1px solid #333333;
	min-width: 65px;
	height: 65px;
	text-align: center;
	border-radius: 50%;
	font-size: 0.9rem;
	line-height: 0.9rem;
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
}

.time-bubble-inner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
}

.time-bubble-value {
	display: block;
	margin: auto;
	padding-bottom: 2rem;
	font-size: 1.2rem;
	font-weight: bold;
	color: #509cd5;
}

.time-bubble-label {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 1.4rem;
	background-color: rgba(0, 0, 0, 0.75);
	color: #ffffff;
	font-size: 0.7rem;
}

.time-bubble-performance {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 0.3rem;
	background-color: white;
	color: black;
	font-weight: bold;
	font-size: 0.6rem;
}

.time-bubble:hover,
.time-bubble:active,
.time-bubble.active {
	background-color: #509cd5;
	transform: translateY(-2px);
}

.time-bubble:hover .time-bubble-value,
.time-bubble:active .time-bubble-value,
.time-bubble.active .time-bubble-value {
	color: #ffffff;
	transform: translateY(-4px);
	font-size: 1.4rem;
}

.parking-limit-type {
	border: 1px solid #333333;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 100%;
	color: #333333;
	height: 100%;
}

.parking-limit-type > * {
	margin-top: auto;
	margin-bottom: auto;
}

.parking-limit-type:hover,
.parking-limit-type:active,
.parking-limit-type.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #509cd5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	transform: translateY(-2px);
}

.parking-payment-stripe {
	padding: 15px;
}

.parking-payment {
	border: 1px solid #333333;
	padding: 15px;
	color: #333333;
	background-color: rgba(255, 255, 255, 0.1);
}

a.parking-payment:hover,
a.parking-payment:active,
a.parking-payment.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #509cd5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	transform: translateY(-2px);
}

.parking-payment > span.media-body {
	margin-left: 0.5rem;
}

.parking-payment > span.media-body > label {
	margin-bottom: 0;
	font-weight: bold;
	font-size: 1.1rem;
}

.parking-payment > span.media-body > span {
	display: block;
	font-size: 0.9rem;
}

.amount-to-pay {
	width: auto;
	display: inline-block;
	color: #ffffff;
	background-color: #28a745;
	font-size: 1.8rem;
	font-weight: bold;
	font-family: -apple-system;
	border-radius: 10px;
	margin: 10px 10px 20px 10px;
	text-align: center;
	padding: 0px 15px;
	height: 43px;
	box-shadow: grey 2px 2px;
}

.parking-info-toggle-button-2 {
	width: 100%;
	border: none;
	background: transparent;
	transform: translateY(8px);
}
</style>
