<template>
	<transition leave-active-class="animate__animated animate__slideOutRight">
		<div class="location-filter-panel-outer" @click.self="hide">
			<div class="location-filter-panel animate__animated animate__bounceInRight">
				<div class="media">
					<div class="media-body">
						<h3>{{ $t('charger-search.detailed-search') }}</h3>
					</div>
					<button 
						@click="hide"
						class="location-filter-panel-close mr-auto btn btn-danger rounded-circle d-flex align-items-center flex-column"
					>
						<font-awesome-icon icon="times" class="fa-lg mx-auto"/>
					</button>
				</div>

				<div class="form-group">

					<div class="row my-2">
						<div class="col-12 form-group">
							<label for="city">{{ $t('billingDataEditor.city') }}:</label>
							<input id="city" type="text" v-model="filters.city" @input="applyFilter" class="form-control">
						</div>
					</div>

					<div class="row my-2">
						<div class="col-12 text-center">
							<div class="btn-group w-100" role="group">
								<button 
									type="button"
									@click="changerFilterType('charger')"
									class="btn"
									style="width: 50%"
									:class="chargerSelectedClass"
								>
									{{ $t('location-filter-comp.search-for-charger') }}
								</button>
								<button 
									type="button"
									@click="changerFilterType('poi')"
									class="btn"
									style="width: 50%"
									:class="poiSelectedClass"
								>
									{{ $t('location-filter-comp.search-for-service') }}
								</button>
							</div>
						</div>
					</div>

					<div v-if="filters.searchCharger">

						<div class="row mb-2 mt-4">
							<div class="col-12">
								<label for="currentType">{{ $t('location-filter-comp.charge-mode') }}</label>
								<select id="currentType" @input="applyFilter" class="form-control" v-model="filters.currentType">
									<option :value="null">{{ $t('location-filter-comp.all') }}</option>
									<option v-for="currentType in currentTypeList" :key="currentType.key" :value="currentType.key">
										{{ currentType.value }}
									</option>
								</select>

							</div>
						</div>

						<div class="row mb-2 mt-4">
							<div class="col-4 text-center">
								<img 
									class="charger-head"
									src="../assets/connector/TYPE2_ico.png"
									@click="selectConnector('TYPE2')"
									:class="filters.outletType === 'TYPE2' ? 'charger-head-selected' : ''"
								>
								<div class="my-1">Type 2</div>
							</div>
							<div class="col-4 text-center">
								<img 
									class="charger-head"
									src="../assets/connector/CHADEMO_ico.png"
									@click="selectConnector('CHADEMO')"
									:class="filters.outletType === 'CHADEMO' ? 'charger-head-selected' : ''"
								>
								<div class="my-1">CHAdeMO</div>
							</div>
							<div class="col-4 text-center">
								<img 
									class="charger-head"
									src="../assets/connector/CCS_ico.png"
									@click="selectConnector('CCS')"
									:class="filters.outletType === 'CCS' ? 'charger-head-selected' : ''"
								>
								<div class="my-1">CCS</div>
							</div>

						</div>

						<div class="row mb-2 mt-4">
							<div class="col-12 form-group">
								<label for="performanceFrom">{{ $t('location-filter-comp.minimal-performance') }}</label>
								<output class="col-12 text-center performance-output">{{ filters.performanceFrom }} kW</output>
								<input 
									type="range" 
									@change="applyFilter" 
									id="performanceFrom" 
									min="0" 
									max="100"
									v-model="filters.performanceFrom"
									style="width: 100%; z-index: 99999999;"
								>
							</div>
						</div>
						<div class="row my-2">
							<div class="col-12 form-group text-center">
								<input 
									id="onlyAvailableChargers" 
									@change="applyFilter" 
									type="checkbox" 
									v-model="filters.onlyAvailableChargers"
									class="switch form-check-input"
								/>
								<label for="onlyAvailableChargers" class="form-check-label">{{ $t('location-filter-comp.only-available-chargers') }}</label>
							</div>
						</div>


					</div>
					<div v-if="filters.searchPoi">
						<!--            <div class="row my-2">-->
						<!--              <div class="col-12 form-group text-center">-->
						<!--                <input id="onlyPointAccepter" @change="applyFilter" type="checkbox" v-model="filters.onlyPointAccepter"-->
						<!--                       class="switch form-check-input"/>-->
						<!--                <label for="onlyPointAccepter" class="form-check-label">{{ $t('location-filter-comp.onlyPartners') }}</label>-->
						<!--              </div>-->
						<!--            </div>-->

						<label for="category">{{ $t('location-filter-comp.category') }}</label>
						<select 
							id="category" 
							class="form-control" 
							v-model="filters.selectedCategory"
							@change="selectTopCategory($event)"
						>
							<option :value="null">{{ $t('location-filter-comp.all') }}</option>
							<option v-for="topCategory in topCategories" :key="topCategory.id" :value="topCategory.id">
								{{ topCategory.name }}
							</option>
						</select>


						<select 
							class="form-control my-2" 
							v-for="(categoryId, index ) in filters.selectedSubCategories"
							@change="selectSubCategory($event, index)" 
							:key="categoryId"
							:value="getSubcategorySelectValue(index)"
						>
							<option :value="null">{{ $t('location-filter-comp.all') }}</option>
							<option v-for="category in getCategoryLevel(categoryId, index)" :key="category.id" :value="category.id">
								{{ category.name }}
							</option>
						</select>
					</div>

					<div class="col-12 text-center my-4 ">
						<button @click="hide" class="btn btn-primary mx-2 my-2">
							<font-awesome-icon icon="check"/>
							{{ $t('location-filter-comp.apply-and-close') }}
						</button>
						<button @click="resetFilter" class="btn btn-danger mx-2 my-2">
							<font-awesome-icon icon="times"/>
							{{ $t('location-filter-comp.filters-clean') }}
						</button>
					</div>
				</div>

			</div>
		</div>
	</transition>
</template>

<script>
import Vue2TouchEvents from 'vue2-touch-events'
import Vue from 'vue'

Vue.use(Vue2TouchEvents)
export default {
	name: 'AdvancedLocationFilter',

	props: [
		'outletTypeList',
		'filterValues',
		'topCategories'
	],
	data() {
		return {
			currentTypeList: [
				{
					key: 'AC',
					value: 'AC'
				},
				{
					key: 'DC',
					value: 'DC'
				}
			],
			filters: null
		}
	},
	computed: {
		chargerSelectedClass() {
			return this.filters.searchCharger ? 'btn-primary' : 'btn-secondary'
		},
		poiSelectedClass() {
			return this.filters.searchPoi ? 'btn-primary' : 'btn-secondary'
		}
	},
	methods: {
		selectConnector(connector) {
			if(this.filters.outletType === connector) {
				this.filters.outletType = null
			}
			else {
				this.filters.outletType = connector
			}
			this.applyFilter()
		},
		resetFilter() {
			this.$emit('deletefilter')
			this.filters = {
				city: null,
				outletType: null,
				currentType: null,
				performanceFrom: 0,
				searchCharger: false,
				searchPoi: false,
				onlyPointAccepter: false,
				onlyAvailableChargers: false,
				selectedCategory: null,
				subcategory: null,
				selectedSubCategories: [],
				latitude: null,
				longitude: null
			}
		},
		getSubcategorySelectValue(index) {
			const realIndex = index + 1
			if(this.filters.selectedSubCategories.length > realIndex) {
				return this.filters.selectedSubCategories[realIndex]
			}
			else {
				return ""
			}
		},
		getCategoryLevel(parentId, index) {
			for(let topCategory of this.topCategories) {
				const result = this.getCategoryRecursive(parentId, topCategory)
				if(result) {
					return result
				}
			}
			return []
		},
		getCategoryRecursive(parentId, parent) {
			if(parent.id == parentId) {
				return parent.subcategories
			}
			for(let child of parent.subcategories) {
				const selected = this.getCategoryRecursive(parentId, child)
				if(selected) {
					return selected
				}
			}
			return null
		},
		selectSubCategory(event, index) {
			this.filters.selectedSubCategories.length = index + 1
			const value = event.target.value
			if(value && value !== "") {
				this.addSubcategorySelect(event.target.value, index)
			}
			else {
				this.$forceUpdate()
			}
			this.filters.subcategory = this.filters.selectedSubCategories[this.filters.selectedSubCategories.length - 1]
			this.applyFilter()
		},
		selectTopCategory(event) {
			this.filters.selectedSubCategories = []
			this.filters.subcategory = null
			if(this.filters.selectedCategory) {
				this.addSubcategorySelect(this.filters.selectedCategory)
			}
			this.applyFilter()
		},
		addSubcategorySelect(parentId, index) {
			this.filters.selectedSubCategories.push(parentId)
		},
		changerFilterType(type) {
			if(type === "charger") {
				this.filters.searchCharger = true
				this.filters.searchPoi = false
			}
			else {
				this.filters.searchCharger = false
				this.filters.searchPoi = true
			}
			this.applyFilter()
		},
		applyFilter() {
			this.$emit('apply', this.filters)
		},
		hide() {
			this.$emit('hide')
		}
	},
	beforeMount() {
		this.filters = this.filterValues
	},
	emits: ['hide', 'apply', 'deletefilter'],
}
</script>

<style>
.performance-output {
  margin-bottom: 0.7rem;
  font-weight: bold;
  font-size: 1.3rem;
}

.location-filter-panel-outer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1021;
}

.charger-head {
  background-color: white;
  padding: 5px;
  border-radius: 30%;
  max-width: 60px;
  border: 2px solid #333333;
}

.charger-head:hover {
  transform: scale(1.1);
}

.charger-head-selected {
  border: 6px solid yellowgreen;
}

.location-filter-panel {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  min-width: 320px;
  padding: 1rem;
  color: #ffffff;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .location-filter-panel {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .location-filter-panel {
    width: 100%;
  }
}
</style>
