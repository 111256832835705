<template>
	<div id="app">
		<MainMenuView v-if="showMainMenu" @selectMode="selectMode" />
		<div class="sideMenuArea">
			<SideMenu :wizardView="wizardView" @logout="logout" @click="closeMenu" @showMainMenu="showMainMenu = true" />
		</div>

		<div class="d-flex flex-grow-1 flex-column mainArea" :class="{ mainAreaOpened: showMenu }">
			<div class="mainAreaOverlay" :class="{ mainAreaOverlayOpened: showMenu }" @click="closeMenu"></div>

			<nav class="site-nav navbar navbar-dark bg-dark sticky-top">
				<button class="navbar-toggler mr-3" type="button" :aria-label="$t('app.open-menu')" @click="toggleMenu">
					<span class="navbar-toggler-icon"></span>
				</button>
				<span class="navbar-brand mr-auto">
					<img alt="Ready2Grow logo" src="@/assets/logo_white_noicon.svg" class="header-logo charging" />
				</span>

				<template v-if="parking || charge">
					<div class="charge-in-progress" v-if="parking" @click="gotoParking" style="cursor: pointer">
						<img src="@/assets/parking_animated.gif" style="height: 40px; width: auto;" />
					</div>

					<div class="charge-in-progress" v-if="charge" @click="gotoCharge" style="cursor: pointer">
						<img src="@/assets/charger_anim.gif" style="height: 40px; width: auto;" />
					</div>
				</template>
				<div v-else>
					<a href="javascript:void(0)" @click="changeLangAndSave('en')" v-if="$i18n.locale !== 'en'">
						<img src="@/assets/flags/en.png" class="lang-flag" />
					</a>
					<a href="javascript:void(0)" @click="changeLangAndSave('hu')" v-if="$i18n.locale != 'hu'">
						<img src="@/assets/flags/hu.png" class="lang-flag" />
					</a>
				</div>
			</nav>

			<main class="flex-grow-1 container d-flex flex-column" :class="{ fullscreen: fullscreen }">
				<Messages :messages="messages" :fullscreen="fullscreen" />

				<GoogleMap @showLocationPanel="showLocationPanel" :mapDataToLoad="mapDataToLoad" ref="googleMapView" />

				<router-view
					:msgs="componentMessages"
					:onBoardingDetailsProp="onBoardingDetails"
					:loggedInUserData="loggedInUserData"
					ref="routerView"
					@login="login"
					@logoutNoRest="logoutNoRest"
					@fbLogin="fbLogin"
					@googleLogin="googleLogin"
					@appleWebLogin="appleWebLogin"
					@reloadUser="reloadUser"
					v-on:logoutWithRest="logoutWithRest"
				/>
			</main>

			<approved-vehicles-modal :show="showModal" :vehicles="approvedVehicles" @switchToVehicleFromList="switchToVehicleFromList" @removeFromList="removeFromApprovedVehicleList" @close="showModal = false">
				<template #header>
					<div>
						<h5>{{ $t('approved-vehicle.modal-header') }}</h5>
						<h6>{{ $t('approved-vehicle.modal-header-question') }}</h6>
					</div>
				</template>
			</approved-vehicles-modal>
		</div>
		<AjaxOverlay v-if="showAjaxOverlay" />

		<div
			class="modal fade"
			id="contentModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="contentModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg" role="document" style="z-index: 10000;">
				<div class="modal-content">
					<div class="modal-header" v-if="bsModalTitle || bsShowCloseButton">
						<h5 class="modal-title" id="contentModalLabel" style="font-size: 0.9rem;">{{ bsModalTitle }}</h5>
						<button type="button" class="close" data-dismiss="modal" :aria-label="$t('contentModal.close')" v-if="bsShowCloseButton">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body" style="overflow-x: auto;">
						<div class="col-12" v-for="c in $modalContent" :key="c.id">
							<component :is="c.component" :modal="true" :parent="bsModalParent" @event="modalEventHandler($event)" v-bind="c.props" />
						</div>
					</div>
					<div class="modal-footer" v-if="bsModalButtons && bsModalButtons.length > 0">
						<button
							type="button"
							:class="btn.styleClass"
							:aria-label="btn.title"
							v-for="btn in bsModalButtons"
							:key="btn.id"
							@click="btn.action"
							:disabled="btn.disabled"
						>
							<font-awesome-icon :icon="btn.icon" size="lg" />
							{{ btn.title }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import AjaxOverlay from '@/components/AjaxOverlay.vue'
import SideMenu from '@/components/SideMenu.vue'
import Messages from '@/components/Messages.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import ApprovedVehiclesModal from '@/components/ApprovedVehiclesModal.vue'

import $ from 'jquery'
import NewsPostModal from '@/components/NewsPostModal.vue'
import MainMenuView from "@/views/MainMenuView.vue"
import { loadSettings } from "@/store/store.js"

export default {
	name: 'App',
	data() {
		return {
			messages: [],
			componentMessages: {},
			loggedInUserData: null,
			onBoardingDetails: {},
			requestedRoute: null,
			fullscreen: false,
			wizardView: false,
			showAjaxOverlay: false,
			showMenu: false,
			charge: null,
			parking: null,
			activeChargeTimer: null,
			activeParkingTimer: null,
			nativeLocation: null,
			keepInWizard: false,
			showChargers: true,
			showPois: true,
			mapDataToLoad: {
				locations: [],
				latitude: null,
				longitude: null,
				findroute: false
			},
			networkError: false,
			bsModalTitle: null,
			bsShowCloseButton: false,
			bsModalButtons: [],
			bsModalEventHandler: null,
			bsModalParent: null,
			showModal: false,
			approvedVehicles: [],
			showMainMenu: false,
			appType: 'chargepark'
		}
	},
	computed: {
		pageName() {
			let t = this.$t('menu.siteName')
			if(this.$route && this.$route.meta && this.$route.meta.title) {
				t += ' - ' + this.$t(this.$route.meta.title)
			}
			return t
		},
		showChargerClass() {
			if(this.showChargers) {
				return 'btn-primary'
			}
			else {
				return 'btn-outline-secondary'
			}
		},
		showPoisClass() {
			if(this.showPois) {
				return 'btn-primary'
			}
			else {
				return 'btn-outline-secondary'
			}
		},
		mode() {
			return this.$store.getters.getMode
		}
	},
	methods: {
		selectMode(mode) {
			this.showMainMenu = false
			this.$store.dispatch('storeMode', mode)
			this.$refs.googleMapView.reloadFilterButtonsStates()
		},
		switchToVehicleFromList(vehicleId) {
			this.$rest.switchVehicleFromList(vehicleId, response => {
				if(response.result) {
					this.removeVehicleFromApprovedList(vehicleId)
				}
			})
		},
		removeFromApprovedVehicleList(vehicleId) {
			this.$rest.keepCustomVehicle(vehicleId)
			this.removeVehicleFromApprovedList(vehicleId)
		},
		removeVehicleFromApprovedList(vehicleId) {
			this.approvedVehicles = this.approvedVehicles.filter(v => v.id !== vehicleId)
			if(this.approvedVehicles.length < 1) {
				this.showModal = false
			}
		},
		gotoCharge() {
			if(this.$route.name != 'charge-history') {
				this.$router.push('/charge-history')
			}
		},
		gotoParking() {
			if(this.$route.name != 'parking-history') {
				this.$router.push('/parking-history')
			}
		},
		loadCharge() {
			this.$rest.getCurrentCharge(true, responseData => {
				this.charge = responseData
				this.$store.dispatch('storeCharge', this.charge)
				clearTimeout(this.activeChargeTimer)
				this.activeChargeTimer = setTimeout(() => {
					this.loadCharge()
				}, 5000)
			})
		},
		loadParking(parkingSession) {
			if((parkingSession || {}).parkingStatus === 'FINISHED') {
				parkingSession = null
			}
			this.parking = parkingSession
			this.$store.dispatch('storeParking', this.parking)
		},
		onWsMessage(message) {
			setTimeout(() => {
				this.addMessage({ messageKey: message, severity: 'error' }, false, 1000)
			}, 0)
		},
		toggleMenu() {
			if(this.showMenu) {
				this.closeMenu()
			}
			else {
				this.showMenu = true
				$('#app').addClass('menuOpened')
			}
		},
		closeMenu() {
			this.showMenu = false
			setTimeout(() => $('#app').removeClass('menuOpened'), 400)
		},
		navigateTo(to) {
			if(this.$router.currentRoute.name != to) {
				this.$router.push(to)
			}
		},
		changeLang(lang) {
			this.$i18n.locale = lang
			localStorage.setItem('Language', lang)

			if(this.$route.meta) {
				if(this.$route.meta.title) {
					document.title = this.$t('pageTitle.base') + ' - ' + this.$t(this.$route.meta.title)
				}
				else {
					document.title = this.$t('pageTitle.base')
				}
			}

			if(lang == 'hu') {
				if(this.$route.name == 'privacy-statement') {
					this.$router.replace('/privacy-statement-hu')
				}
				else if(this.$route.name == 'gtc') {
					this.$router.replace('/gtc-hu')
				}
			}
			else {
				if(this.$route.name == 'privacy-statement-hu') {
					this.$router.replace('/privacy-statement')
				}
				else if(this.$route.name == 'gtc-hu') {
					this.$router.replace('/gtc')
				}
			}
		},
		changeLangAndSave(lang) {
			this.changeLang(lang)

			if(this.loggedInUserData) {
				this.$rest.saveLang(lang)
			}
		},
		addMessage(messageObj, useAlert, timeout) {
			if(!messageObj.component) {
				if(useAlert) {
					alert(messageObj.messageKey ? this.$t(messageObj.messageKey) : messageObj.message)
				}
				else {
					const id = this.messages.length
					this.messages.push({
						id: id,
						text: messageObj.messageKey ? this.$t(messageObj.messageKey) : messageObj.message,
						severity: messageObj.severity || 'info'
					})

					if(timeout) {
						setTimeout(() => {
							this.messages = this.messages.filter(m => m.id !== id)
						}, timeout)
					}
				}
			}
			else {
				let l = this.componentMessages[messageObj.component]
				if(!l) {
					l = []
					this.$set(this.componentMessages, messageObj.component, l)
				}
				l.push({
					id: l.length,
					text: messageObj.messageKey ? this.$t(messageObj.messageKey) : messageObj.message,
					severity: messageObj.severity || 'info'
				})
			}
		},
		addMessages(messages, useAlert) {
			for(let i = 0; i < messages.length; i++) {
				this.addMessage(messages[i], useAlert)
			}
		},
		clearMessages() {
			this.messages = []
			this.componentMessages = {}
		},
		login(email, password, loginCompleted) {
			this.$rest.login(email, password, (resp, extra) => this.loginCallback(resp, extra, loginCompleted))
		},
		fbLogin(authResponse) {
			this.$rest.facebookLogin(authResponse, (resp, extra) => this.loginCallback(resp, extra))
		},
		fbLoginNative(accessToken, userID, name, email) {
			this.$rest.facebookLoginNative(accessToken, userID, name, email, (resp, extra) => this.loginCallback(resp, extra))
		},
		googleLogin(res) {
			this.$rest.googleLogin(res.credential, (resp, extra) => this.loginCallback(resp, extra))
		},
		appleWebLogin(response, extraData) {
			this.loginCallback(response, extraData)
		},
		deleteAppleStuff() {
			localStorage.removeItem('appleUser')
			localStorage.removeItem('appleName')
			localStorage.removeItem('appleGivenName')
			localStorage.removeItem('appleFamilyName')
			localStorage.removeItem('appleEmail')
		},
		appleLogin(jsonStr) {
			let json = $.parseJSON(jsonStr)

			if(json.email) {
				localStorage.setItem('appleUser', json.user)
				localStorage.setItem('appleName', json.name)
				localStorage.setItem('appleGivenName', json.given_name)
				localStorage.setItem('appleFamilyName', json.family_name)
				localStorage.setItem('appleEmail', json.email)
			}
			else {
				let savedAppleUserId = localStorage.getItem('appleUser')
				if(json.user == savedAppleUserId) {
					json.name = localStorage.getItem('appleName')
					json.given_name = localStorage.getItem('appleGivenName')
					json.family_name = localStorage.getItem('appleFamilyName')
					json.email = localStorage.getItem('appleEmail')
				}
			}

			this.$rest.appleLogin(json.user, json.name, json.given_name, json.family_name, json.email, (resp, extra) => {
				this.deleteAppleStuff()
				this.loginCallback(resp, extra)
			})
		},

		loginCallback(responseData, extraData, loginCompleted) {
			localStorage.setItem('loggedInUserId', responseData.user.id)
			localStorage.setItem('loggedInEmail', responseData.user.email)
			localStorage.setItem('loggedInUserToken', responseData.token)

			if(responseData.user.preferedLanguage) {
				this.changeLang(responseData.user.preferedLanguage)
			}

			this.setLoggedInUserData(responseData.user)
			this.setOnboardingDetails(extraData)

			if(typeof loginCompleted === 'function') {
				loginCompleted(this.loggedInUserData)
			}
			this.navigateForward()
		},
		setLoggedInUserData(loggedInUserData) {
			this.loggedInUserData = loggedInUserData
			this.$store.dispatch('storeLoggedInUserData', loggedInUserData)
		},
		cleanOnboardingDetails() {
			this.onBoardingDetails = null
			this.$store.dispatch('storeOnBoardingDetails', null)
		},
		setOnboardingDetails(extraData) {
			this.onBoardingDetails = extraData
			this.onBoardingDetails.userHasPhoneNumber = true // No need for asking for a phone number on Goole/Facebook/Apple logins.
			this.$store.dispatch('storeOnBoardingDetails', this.onBoardingDetails)
		},

		logoutCasualUserIfNeeded(force) {
			if(this.loggedInUserData && this.loggedInUserData.casualUser) {
				if(!force && (!this.$route.meta || !this.$route.meta.enabledAtCasualCharge)) {
					this.logoutNoRest()
					return true
				}
			}
			return false
		},

		redirectToWelcomeIfNeeded() {
			if(this.logoutCasualUserIfNeeded()) {
				return true
			}

			if(this.$router.currentRoute && this.$router.currentRoute.name && this.$router.currentRoute.name === 'creditCardsWizard') {
				return true
			}

			if(this.loggedInUserData && !this.loggedInUserData.casualUser && this.onBoardingDetails) {
				const obd = this.onBoardingDetails
				if(	!obd.userHasPhoneNumber ||
					!obd.userHasBillingData ||
					(!obd.userHasCar && !obd.carAdditionSkipped) ||
					(this.$store.getters.getSettings.wizardCards && !obd.userHasCreditCard && !obd.creditCardAdditionSkipped)
				) {
					if(this.$route.name != 'welcome') {
						this.$router.replace('/welcome')
					}
					return true
				}
			}
			return false
		},

		navigateForward() {
			if(!this.redirectToWelcomeIfNeeded()) {
				this._navigateToIndexOrLastRedirectPage()
			}
			this.$rest.getApprovedVehicles(responseData => {
				if(responseData.length > 0) {
					this.approvedVehicles = responseData
					this.showModal = true
				}
			})
		},

		_navigateToIndexOrLastRedirectPage() {
			if(this.requestedRoute && this.requestedRoute.meta && this.requestedRoute.meta.canRedirectHereAfterLogin) {
				this.$router.replace(this.requestedRoute)
				this.requestedRoute = null
			}
			else {
				this.$router.replace('/')
			}
		},

		onWizardFinished() {
			this.keepInWizard = false
			this._navigateToIndexOrLastRedirectPage()
		},

		async reloadUser(callback) {
			try {
				this.$ws.connect()
				const data = await this.$rest.getLoggedInUserAsync()
				this.setLoggedInUserData(data)
				this.setOnboardingDetails(data)
				if(data.extraData && data.extraData.settings) {
					loadSettings(data.extraData.settings)
				}
				if(typeof callback === 'function') {
					callback(this.loggedInUserData)
				}
			}
			catch(e) {
				console.log(e)
			}
		},
		logoutNoRest() {
			localStorage.removeItem('loggedInUserId')
			localStorage.removeItem('loggedInEmail')
			localStorage.removeItem('loggedInUserToken')
			localStorage.removeItem('Language')
			this.setLoggedInUserData(null)
			this.cleanOnboardingDetails()

			if('ios' === window.platform || 'android' === window.platform) {
				window.loadAppLink('freecharger://google-logout')
			}

			if('ios' === window.platform || 'android' === window.platform) {
				window.loadAppLink('freecharger://facebook-logout')
			}
		},

		logout() {
			if(confirm(this.$t('sureToLogOut'))) {
				this.logoutWithRest()
			}
		},
		logoutWithRest() {
			this.$rest.logout(responseData => {
				this.logoutNoRest()
				this.keepInWizard = false
				if(this.$router.currentRoute.meta && this.$router.currentRoute.meta.loginRequired) {
					this._setRequestedRoute(this.$router.currentRoute)
					if(!this.requestedRoute || this.requestedRoute.name != 'login') {
						this.$router.replace('login')
					}
				}
				this.keepInWizard = false
			})
		},
		_setRequestedRoute(route) {
			if(this.loggedInUserData && (!route.meta || !route.meta.wizardView)) {
				this.requestedRoute = null
			}
			else if(route && route.meta) {
				if(route.meta.canRedirectHereAfterLogin) {
					this.requestedRoute = route
				}
				else if(!route.meta.wizardView && route.name !== 'login') {
					this.requestedRoute = null
				}
			}
		},
		onRouteChangeStuff(to, from) {
			if(!to) {
				return
			}

			if(to.meta) {
				if(to.meta.title) {
					document.title = this.$t('pageTitle.base') + ' - ' + this.$t(to.meta.title)
				}
				else {
					document.title = this.$t('pageTitle.base')
				}

				this.fullscreen = to.meta.fullscreen === true
				this.wizardView = to.meta.wizardView === true
			}

			if(this.logoutCasualUserIfNeeded()) {
				return
			}

			this._setRequestedRoute(to)

			if(to.meta) {
				if(to.meta.loginRequired && !this.loggedInUserData && !to.meta.enabledAtCasualCharge) {
					this.$router.replace('login')
					setTimeout(() => {
						this.addMessage({ messageKey: 'error.needsLogin', severity: 'error' })
					}, 0)
					return
				}

				if(this.loggedInUserData && this.keepInWizard && !to.meta.wizardView) {
					this.$router.replace('welcome')
					return
				}

				/*if (to.meta.wizardView !== true && to.name != 'me' && this.loggedInUserData && !this.loggedInUserData.phone) {
					if (to.name !== 'me') {
						this.$router.replace('me');
					}
					setTimeout(() => {
						this.addMessage({ messageKey: 'error.needsPhone', severity: 'error' });
					}, 0);
					return;
				}*/
			}
		},
		handleCasualUserNavagationBackFromSimplepay(to) {
			if(to.name !== 'payment-result' && to.name !== '/charge-history') {
				this.$router.replace('/charge-history')
			}
		},
		showLocationPanel(locationWithPois, locationsWithChargers, locationParkingLots, tab) {
			if(typeof this.$refs.routerView.showLocationPanel === 'function') {
				this.$refs.routerView.showLocationPanel(locationWithPois, locationsWithChargers, locationParkingLots, tab)
			}
		},
		feedLocation(lat, lng) {
			this.nativeLocation = { coords: { latitude: lat, longitude: lng } }
			this.$refs.googleMapView.updateMyLocation(lat, lng)
		},
		feedFcmToken(fcmToken) {
			localStorage.setItem('fcmToken', fcmToken)
		},
		appleLoginNative(code) {
			//TODO provide user details from android
			this.$rest.loginWithApple(code, null, (response, extraData) => {
				this.loginCallback(response, extraData)
			})
		},
		getLocation(callback, callbackError) {
			if('ios' === window.platform || 'android' === window.platform) {
				if(this.nativeLocation != null) {
					callback(this.nativeLocation)
				}
				else {
					callbackError(false)
				}
			}
			else {
				let thereIsGeoLocationSupport = navigator.geolocation != null
				if(thereIsGeoLocationSupport) {
					navigator.geolocation.getCurrentPosition(
						async position => {
							this.$refs.googleMapView.updateMyLocation(position.coords.latitude, position.coords.longitude)
							callback(position)
							return
						},
						err => {
							callbackError(thereIsGeoLocationSupport)
						}
					)
				}
				else {
					callbackError(thereIsGeoLocationSupport)
				}
			}
		},
		scrollUp() {
			let scrollTop = $(document).scrollTop()
			if(scrollTop > 0) {
				$('html, body').animate({ scrollTop: 0 }, Math.min(Math.max(0, scrollTop * 10), 1500))
			}
		},
		showBSModal(components, title, buttons, showCloseButton, eventHandler, parent) {
			this.bsModalTitle = title
			this.bsShowCloseButton = showCloseButton
			this.bsModalEventHandler = eventHandler
			this.bsModalParent = parent

			if(components) {
				for(let i = 0; i < components.length; i++) {
					components[i].id = i
				}
			}

			Vue.set(Vue.prototype, '$modalContent', components)

			if(!buttons) {
				this.bsModalButtons = [
					{
						id: 1,
						title: this.$t('contentModal.close'),
						icon: 'times',
						styleClass: 'btn btn-secondary',
						closeModal: true,
						disabled: false
					}
				]
			}
			else {
				this.bsModalButtons = buttons
				for(let i = 0; i < this.bsModalButtons.length; i++) {
					let btn = this.bsModalButtons[i]
					btn.id = i

					if(!btn.disabled) {
						btn.disabled = () => {
							return false
						}
					}
				}
			}

			if(this.bsModalButtons) {
				for(let btn of this.bsModalButtons) {
					if(btn && btn.closeModal) {
						if(btn.action) {
							btn.originalAction = btn.action
							btn.action = () => {
								this.hideBSModal()
								btn.originalAction()
							}
						}
						else {
							btn.action = () => {
								this.hideBSModal()
							}
						}
					}
				}
			}

			$('#contentModal').modal()
		},
		hideBSModal() {
			$('#contentModal').modal('hide')
		},
		modalEventHandler(event) {
			if(this.bsModalEventHandler) {
				this.bsModalEventHandler(event)
			}
		},
		testGps() {
			let centerLat = 47.498362
			let centerLng = 19.04046
			setInterval(() => {
				let lat = centerLat + Math.random() * 0.01 - 0.005
				let lng = centerLng + Math.random() * 0.01 - 0.005

				this.feedLocation(lat, lng)
			}, 200)
		},
		async handleCommands(to, from) {
			if(to && to.params && to.params.command) {
				if(to.params.command == 'showLocation' && to.params.locationId) {
					await this.$refs.googleMapView.downloadAndShowLocationDetails(to.params.locationId, to.params.tab)
					this.$refs.googleMapView.moveToCurrentMarker()
				}
			}
		},
		showNewsModal() {
			//localStorage.removeItem('shownNews');

			this.$rest.loadNewsFiltered(news => {
				const shownNews = (localStorage.getItem('shownNews') || '').split(',')

				const shownNewsMap = {}
				const shownNewsList = []

				for(const nId of shownNews) {
					if(!shownNewsMap[nId]) {
						shownNewsMap[nId] = true
						shownNewsList.push(nId)
					}
				}

				for(const n of news) {
					if(!n.onlyDisappearsWhenClicked && !shownNewsMap[n.publicationId]) {
						shownNewsMap[n.publicationId] = true
						shownNewsList.push(n.publicationId)
					}
				}

				//console.log(shownNewsList);

				localStorage.setItem('shownNews', shownNewsList.join(','))
				if(news.length === 0) {
					return
				}

				this.showBSModal(
					[
						{
							component: NewsPostModal,
							props: {
								news: news
							}
						}
					],
					this.$t('newsView.newNews'),
					[],
					true,
					(event) => {
						if('newsListEmptied' === event) {
							this.hideBSModal()
						}
					}
				)
			})
		},
		checkIfMainMenuIsNeeded() {
			if(!this.$store.getters.getSettings.welcomeScreen) {
				return
			}

			const cookieMode = localStorage.getItem('mode')
			if(cookieMode) {
				//console.log('cookieMode', cookieMode)
				this.$store.dispatch('storeMode', cookieMode)
			}
			this.showMainMenu = !this.mode && !this.$route.meta.disableMainMenu
			//console.log('mode', this.showMainMenu)
		}
	},
	watch: {
		$route(to, from) {
			this.networkError = false
			this.clearMessages()
			this.onRouteChangeStuff(to, from)
			this.handleCommands(to, from)
		}
	},
	async created() {
		this.logoutCasualUserIfNeeded()

		this.$axios.interceptors.request.use(
			config => {
				if(!config.keepMessages) {
					this.clearMessages()
				}

				var loggedInUserId = localStorage.getItem('loggedInUserId')
				if(!loggedInUserId) {
					loggedInUserId = localStorage.getItem('loggedInEmail')
				}
				var loggedInUserToken = localStorage.getItem('loggedInUserToken')
				var loggedInAccessToken = localStorage.getItem('loggedInAccessToken')

				if(loggedInUserId && loggedInUserToken) {
					config['auth'] = {
						username: loggedInUserId,
						password: loggedInUserToken
					}
				}
				else if(loggedInAccessToken) {
					config.headers['Authorization'] = 'Bearer ' + loggedInAccessToken
				}

				config.headers['X-Lang'] = this.$i18n.locale

				if( // Background, timed queries should not open the overlay.
					config.url !== 'v1/charge/current' &&
					config.url !== 'v1/charge/last-finished' &&
					config.url !== 'v1/user/credit-cards' &&
					config.url !== 'v1/wallet/wallet-ballance' &&
					config.url !== 'v1/location/location'
				) {
					this.showAjaxOverlay = true
				}

				return config
			},
			error => {
				return Promise.reject(error)
			}
		)

		this.$axios.interceptors.response.use(
			response => {
				this.showAjaxOverlay = false
				if(response && response.data) {
					if(response.data.message) {
						setTimeout(() => {
							this.addMessage(response.data.message, response.data.useAlert), 0
						})
					}
					if(response.data.messages) {
						setTimeout(() => {
							this.addMessages(response.data.messages, response.data.useAlert), 0
						})
					}
				}
				return response
			},
			error => {
				this.showAjaxOverlay = false

				if(!error.config.keepMessages && !error.response) {
					if(this.networkError == false) {
						//alert(this.$t('server.down'))
					}
					this.networkError = true
				}

				if(error.response && error.response.status == 401) {
					localStorage.removeItem('loggedInUserId')
					localStorage.removeItem('loggedInEmail')
					localStorage.removeItem('loggedInUserToken')
					this.setLoggedInUserData(null)
					if(this.$router.currentRoute.name != 'login' && this.$router.currentRoute.meta && this.$router.currentRoute.meta.loginRequired) {
						this.$router.replace('login')
					}
				}
				return Promise.reject(error)
			}
		)

		if(!this.loggedInUserData) {
			try {
				const response = await this.$rest.getLoggedInUserAsync()
				const data = response.data
				const extraData = response.extraData

				if(extraData.settings) {
					loadSettings(extraData.settings)
				}

				this.checkIfMainMenuIsNeeded()

				this.setLoggedInUserData(data)
				this.setOnboardingDetails(extraData)

				if(!this.redirectToWelcomeIfNeeded()) {
					this.onRouteChangeStuff(this.$router.currentRoute)
				}
			}
			catch(e){
				const settings = await this.$rest.getSettingsAsync()
				if(settings) {
					loadSettings(settings)
				}

				this.checkIfMainMenuIsNeeded()

				this.setLoggedInUserData(null)
				this.onRouteChangeStuff(this.$router.currentRoute)
			}
		}

		const lang = localStorage.getItem('Language')
		if(lang && this.$i18n.messages[lang]) {
			if(this.$i18n.locale != lang) {
				this.$i18n.locale = lang
			}
		}
		else {
			let browserLang = navigator.language || navigator.userLanguage
			if(browserLang && browserLang.toLowerCase().indexOf('hu') == 0) {
				browserLang = 'hu'
			}
			else {
				browserLang = 'en'
			}
			this.$i18n.locale = browserLang
		}
	},
	mounted() {
		if(this.$route.meta) {
			this.fullscreen = this.$route.meta.fullscreen === true
			this.wizardView = this.$route.meta.wizardView === true
		}

		this.$ws.connect()
		this.$ws.subscribe("/user/parkingSession", this.loadParking)
		this.$ws.onOpen(() => {
			this.$ws.command('getParkingSession')
		})

		this.$ws.subscribe("error", this.onWsMessage)

		this.loadCharge()
		// this.loadParking();
		window.googleLogin = this.googleLogin
		window.fbLoginNative = this.fbLoginNative
		window.appleLogin = this.appleLogin
		window.feedLocation = this.feedLocation
		window.feedFcmToken = this.feedFcmToken
		window.appleLoginNative = this.appleLoginNative
		//this.testGps();

		this.showNewsModal()
	},
	beforeDestroy() {
		this.$ws.unsubscribe("/user/parkingSession", this.loadParking)
		this.$ws.unsubscribe("error", this.onWsMessage)
		this.$ws.disconnect()
	},
	components: {
		MainMenuView,
		AjaxOverlay,
		SideMenu,
		Messages,
		GoogleMap,
		ApprovedVehiclesModal
	}
}
</script>

<style>


:root {
  --mode-color: #08B647;
  --mode-color-darker: #08B647;
}

html {
	touch-action: manipulation;
}

html, body {
	height: 100%;
}

html #app {
	box-sizing: border-box;
	display: flex;
	min-height: 100%;
	min-height: 100vh;
	min-height: 100dvh;
	flex-direction: column;
}

#app,
.btn {
	font-size: 15px;
}

.charge-in-progress {
	height: 100%;
	color: white;
	margin-left: 10px;
	padding: 0 10px;
	transform: translateX(10px);
}

html .site-header,
html .site-nav,
html .site-footer {
	flex: 0 0 auto;
}



.site-nav.bg-dark {
	background-color: var(--mode-color) !important;
}

html .site-content {
	flex: 1;
}

body {
	overflow-x: hidden;
}

main {
	background-color: #ffffff;
	padding: 15px;
	position: relative;
}

main.fullscreen {
	padding: 0;
	max-width: 100%;
}

.sideMenuArea {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	max-width: 235px;
	border-radius: 0;
	background-color: #eeeeee;
}

.mainArea {
	transition: transform 0.5s;
	z-index: 1;
	position: relative;
}

.mainAreaOpened {
	transform: translateX(235px);
	box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
}

.mainAreaOverlay {
	transition: background 0.5s;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	pointer-events: none;
}

.mainAreaOverlayOpened {
	background-color: rgba(255, 255, 255, 0.75);
}

.menuOpened .mainAreaOverlay {
	display: block;
	pointer-events: all;
}

@media (max-width: 1199px) {
	.mainArea main.container {
		max-width: 100% !important;
	}

	.sideMenuArea {
		display: none;
	}

	.menuOpened .sideMenuArea {
		display: block;
	}
}

@media (min-width: 1200px) {
	#app {
		display: flex;
		flex-direction: row !important;
	}

	.sideMenuArea {
		position: static;
	}

	.mainArea {
		transition: none;
		transform: none;
		box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
	}

	.navbar .navbar-toggler {
		display: none;
	}

	.mainAreaOverlay {
		display: none;
	}
}

.site-nav.navbar .navbar-toggler {
	border-color: rgba(255, 255, 255, 0.9);
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.9);
	transition: box-shadow 0.25s;
}

.site-nav.navbar .navbar-toggler:hover {
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.9), inset 0 0 8px rgba(255, 255, 255, 0.9);
}

.site-nav.navbar .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header-logo {
	height: 30px;
}

.header-logo.parking{
  height: 22px;
}

.header-logo.both {
  height: 35px;
  margin-right: 10px;
}

.lang-flag {
	max-height: 35px;
	max-width: 100%;
	height: auto;
}
</style>
