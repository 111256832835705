<template>
	<div class="wallet-simple">

		<div class="wallet-header py-1 mt-4"> {{ $t('menu.wallet') }}</div>

		<div class="form-group">
			<label>{{ $t('walletView.balances') }}:</label>
			<span class="wallet-actual-wallet-balance-text-invert" :class="{ negative: walletBalance < 0 }">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
		</div>

		<div class="form-group">
			<label>{{ $t('walletView.pleaseAddAmount') }}:</label>
			<NumberInput id="chargeAmount" :msgs="msgs" v-model="chargeAmount" class="form-control" :required="true" />
			<ComponentMessage :msgs="msgs" forComponent="chargeAmount" />
		</div>

		<div class="form-group">
			<label>{{ $t('walletView.listHeader') }}:</label>
			<select class="form-control" v-model="selectedBillingData" v-if="billingDatas && billingDatas.length > 0">
				<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData"> {{ billingData.payerName }}, {{ billingData.address.full }} </option>
			</select>
		</div>

		<div class="text-center">
			<button @click="chargeWallet()" class="btn btn-success px-4 py-2 m-3" style="margin: auto">
				<font-awesome-icon icon="coins" size="lg" />
				{{ $t('walletView.sendPrepaidAmount') }}
			</button>

			<button @click="$emit('cancel')" class="btn btn-secondary px-4 py-2 m-3" style="margin: auto">
				<font-awesome-icon icon="times" size="lg" />
				{{ $t('walletView.cancel') }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WalletAdd',
	props: {
		msgs: Object
	},
	data() {
		return {
			walletBalance: null,
			chargeAmount: null,
			selectedBillingData: null,
			billingDatas: [],
			minAmountStatic: 100,
			minAmount: 100,
			maxAmount: 50000
		}
	},
	methods: {
		chargeWallet() {
			if(!this.chargeAmount || this.chargeAmount < this.minAmount) {
				alert(this.$t('walletView.minimumLimitAlert', [this.minAmount]))
				return
			}
			if(this.chargeAmount > this.maxAmount) {
				alert(this.$t('walletView.maximumLimitAlert', [this.maxAmount]))
				return
			}

			if(confirm(this.$t('walletView.confirmWalletCharge', [this.chargeAmount]))) {
				this.$rest.chargeMoneyToWallet(
					this.chargeAmount,
					this.selectedBillingData.id,
					responseData => {
						if(responseData.result) {
							window.location.href = responseData.data
						}
						else {
							alert(this.$t('walletView.prepaidFailed'))
						}
					},
					error => {
						this.showErrorMessage(error)
					}
				)
			}
		},
		getBillingDatas() {
			this.$rest.listBillingDataForCurrentUser(responseData => {
				this.billingDatas = responseData ? responseData : []
				this.selectedBillingData = null
				for(let billingData of this.billingDatas) {
					this.selectedBillingData = billingData
					if(this.selectedBillingData.defaultBillingData) {
						break
					}
				}
			})
		},
		getWalletBalance() {
			this.$rest.getWalletBalance(responseData => {
				if(responseData.result) {
					this.walletBalance = responseData.data.ballance

					if(this.walletBalance < 0) {
						this.chargeAmount = -this.walletBalance
						this.minAmount = Math.max(this.chargeAmount, this.minAmountStatic)
					}
				}
			})
		}
	},
	mounted() {
		this.getBillingDatas()
		this.getWalletBalance()
	}
}
</script>
