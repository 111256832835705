<template>
	<div class="wallet-outer2">
		<div class="wallet-outer">
			<div class="wallet">
				<div class="wallet-header">{{ $t('menu.wallet') }}</div>

				<div class="text-center text-danger" style="margin-top: -10px; margin-bottom: 8px;" v-if="countUnpayedWallets > 0">
					{{ $t('walletView.countUnpayedWallets', [countUnpayedWallets]) }}
				</div>

				<div class="text-center pt-0 mb-3">
					{{ $t('walletView.balances') }}:
					<span class="wallet-actual-wallet-balance-text" :class="{ negative: walletBalance < 0 }">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
				</div>

				<div class="wallet-bottom justify-content-center">
					<button @click="$emit('walletAdd')" class="btn btn-success">
						<font-awesome-icon icon="coins" />
						{{ $t('walletView.fillWallet') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WalletView',
	props: {
		msgs: Object
	},
	data() {
		return {
			walletBalance: null,
			countUnpayedWallets: 0,
			timer: null
		}
	},
	methods: {
		getWalletBalance() {
			this.$rest.getWalletBalance(responseData => {
				if(responseData.result) {
					this.walletBalance = responseData.data.ballance
					this.countUnpayedWallets = responseData.data.countUnpayedWallets
				}
			})
		},

		refresh() {
			this.getWalletBalance()

			this.killTimer()
			this.timer = setTimeout(() => {
				this.refresh()
			}, 5000)
		},

		killTimer() {
			if(this.timer) {
				clearTimeout(this.timer)
				this.timer = null
			}
		},
	},
	mounted() {
		this.refresh()
	},
	beforeDestroy() {
		this.killTimer()
	},
	emits: ['walletAdd']
}
</script>
