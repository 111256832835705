<template>
	<div>
		<div class="mx-auto text-center mb-5" style="max-width: 480px;">
			<img alt="FreeCharger" src="@/assets/logo.svg" class="img-fluid" />
		</div>

		<table>
			<tr>
				<td>
					{{ $t('support-view.phone') }}
				</td>
				<td>
					<a href="tel:+36203678407">
						<font-awesome-icon icon="phone" size="lg" class="mr-2" />
						+36 20 367 84 07
					</a>
				</td>
			</tr>

			<tr>
				<td>
					{{ $t('support-view.email') }}
				</td>
				<td>
					<a href="mailto:support@freecharger.hu">
						<font-awesome-icon icon="envelope" size="lg" class="mr-2"/>
						support@freecharger.hu
					</a>
				</td>
			</tr>
		</table>
	</div>
</template>

<style scoped>
	table {
		margin-left: auto;
		margin-right: auto;
	}

	td:first-child {
		padding-right: 1rem;
		font-weight: bold;
		text-align: right;
	}

	tr > td {
		padding-top: 1rem;
	}

	tr:first-child > td {
		padding-top: 0;
	}
</style>

<script>
export default {
	name: 'SupportView',
};
</script>
