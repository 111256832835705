<template>
	<div class="mt-3">

		<div class="alert alert-warning form-group text-center">
			<div>
				{{ $t('casual-charging.messageLogin') }}
			</div>
			<div class="mt-2">
				<router-link to="/login" class="btn btn-success">
					<font-awesome-icon icon="sign-in-alt"/>
					{{ $t('index.continueToLogin') }}
				</router-link>
			</div>
		</div>

		<h3 class="text-center">{{ $t('casual-parking.header') }}</h3>

		<div class="form-group text-center">
			{{ $t('casual-parking.messageProvideData') }}
		</div>

		<div class="form-group text-center">
			<div class="mb-1">
				<small>{{ $t('casual-parking.messagePoints') }}</small>
			</div>
			<label>{{ $t('casualSurvey.wantToCollectPoints') }}</label>
			<div>
				<button @click="setCollectPoints(true)" class="btn btn-success mr-2" :class="{'btn-inactive': collectPoints === false}">
					<font-awesome-icon icon="check"/>
					{{ $t('yes') }}
				</button>
				<button @click="setCollectPoints(false)" class="btn btn-danger" :class="{'btn-inactive': collectPoints === true}">
					<font-awesome-icon icon="times"/>
					{{ $t('no') }}
				</button>
			</div>
		</div>

		<div class="form-group text-center mt-2" v-if="collectPointsAnswered">
			<div class="mb-1">
				<small>{{ $t('casual-parking.messageInvoice') }}</small>
			</div>
			<label>{{ $t('casualSurvey.wantToGetInvoice') }}</label>
			<div>
				<button @click="setGettingInvoice(true)" class="btn btn-success	mr-2" :class="{'btn-inactive': gettingInvoice === false}">
					<font-awesome-icon icon="check"/>
					{{ $t('yes') }}
				</button>
				<button @click="setGettingInvoice(false)" class="btn btn-danger" :class="{'btn-inactive': gettingInvoice === true}">
					<font-awesome-icon icon="times"/>
					{{ $t('no') }}
				</button>
			</div>
			<a name="step2"></a>
		</div>

		<div class="form-group text-center mt-2" v-if="collectPointsAnswered && gettingInvoicePointsAnswered">
			<div v-if="!priceSettings.freeCharging">
				<div class="form-group">
					<div class="blockquote-footer step-list" v-if="priceSettings.convenienceFeeGross > 0">
						<b>{{ $t('charger-view.amount-disclaimer-5', [priceSettings.convenienceFeeGross]) }}</b>
					</div>
				</div>
			</div>

			<label>{{ $t('casualSurvey.addEmailAddressParking') }}</label>
			<EmailInput
				id="email"
				v-model="email"
				:msgs="msgs"
				class="form-control email-input"
				maxlength="255"
				:placeholder="$t('casualSurvey.email')"
				:required="true"
			/>
			<ComponentMessage :msgs="msgs" forComponent="email" />

			<a name="step3"></a>
		</div>

	</div>
</template>

<script>
import * as utils from '@/plugins/utils.js'
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue'
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue'

export default {
	name: "CasualParkingSurvey",
	emits: ['email', 'selectedAll'],
	props: {
		msgs: Object,
		priceSettings: Object,
		app: Object
	},
	data() {
		return {
			collectPointsAnswered: false,
			collectPoints: null,
			gettingInvoicePointsAnswered: false,
			gettingInvoice: null,
			gateId: null,
			email: null
		}
	},
	watch:{
		email: function (val) {
			this.$emit('email', val)
		},
	},
	components: {

	},
	methods: {
		setCollectPoints(wantsToCollect) {
			this.collectPointsAnswered = true
			this.collectPoints = wantsToCollect
			if(wantsToCollect) {
				this.$router.push({name: 'register', params: {gateId: this.gateId}})
			}
			else {
				utils.scrollToAnchor('step2')
			}
		},
		setGettingInvoice(wantToGetInvoice) {
			this.gettingInvoice = wantToGetInvoice
			this.gettingInvoicePointsAnswered = true
			if(wantToGetInvoice) {
				this.$router.push({name: 'register', arams: {gateId: this.gateId}})
			}
			else {
				utils.scrollToAnchor('step3')
				this.$emit('selectedAll', true)
			}
		},
	},
	created() {
		this.gateId = this.$route.params.gateId
	}
}
</script>

<style scoped>
	.btn-inactive {
		opacity: 0.5;
	}

	.email-input {
		border: 2px solid var(--mode-color);
		background-color: #c2edd2;
		max-width: 320px;
		margin: auto;
	}

	.email-input::placeholder {
		color: #0b6b2e;
		opacity: 0.75;
	}

	.email-input:-ms-input-placeholder {
		color: #0b6b2e;
	}

	.email-input::-ms-input-placeholder {
		color: #0b6b2e;
	}
</style>

<style>
	.card {
		background: transparent;
	}
</style>
