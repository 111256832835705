<template>
	<input
		:id="id"
		type="password"
		:value="password"
		@change="change"
	/>
</template>

<script>
const upperCasePattern = /[A-Z]/
const lowerCasePattern = /[a-z]/
const numberPattern = /\d/

export default {
	name: 'PasswordInput',
	data() {
		return {
			password: ''
		}
	},
	props: {
		id: String,
		value: String,
		msgs: Object,
		password1: String
	},
	mounted() {
		this.password = this.value
	},
	watch: {
		value(v) {
			this.password = v
		}
	},
	methods: {
		change(event) {
			const val = event.target.value.trim()

			if(this.password1) {
				if(val !== this.password1) {
					this.$emit('input', '')
					this.$set(this.msgs, this.id, [{id: 0, text: this.$t('forgottenPassword.passwordMismatch'), severity: 'error'}])
					return
				}
			}
			else {
				if(val !== event.target.value) {
					this.$emit('input', '')
					this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.passwordSpaces'), severity: 'error'}])
					return
				}

				if(val.length < 8 || !val.match(upperCasePattern) || !val.match(lowerCasePattern) || !val.match(numberPattern)) {
					this.$emit('input', '')
					this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.password'), severity: 'error'}])
					return
				}
			}

			this.password = val
			this.$emit('input', val)
			this.$set(this.msgs, this.id, [])
		}
	},
	emits: ['input']
}
</script>
