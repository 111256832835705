<template>
	<div class="alert-block" :class="{ 'fullscreen': fullscreen }">
		<Message
			v-for="message in messages"
			:message="message"
			:key="message.id"
			:fullscreen="fullscreen"
		/>
	</div>
</template>

<script>
import Message from '@/components/Message.vue'

export default {
	name: 'Messages',
	props:  {
		messages: Array,
		fullscreen: Boolean
	},
	components: {
		Message
	}
}
</script>

<style>
	.alert-block.fullscreen {
		position: absolute;
		z-index: 1021;
		left: 1rem;
		right: 1rem;
		bottom: 50px;
	}
	.alert-block.fullscreen .media {
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
	}
</style>
