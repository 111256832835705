<template>
	<router-link
		:to="to"
		tag="li"
		next="a"
		class="sidemenu-item"
		:class="$route.path == to || $route.path == other1 ? 'active' : ''"
		@click.native="$emit('click')"
	>
		<a class="">
			<font-awesome-icon :icon="icon" size="lg" :fixed-width="true" class="mr-2" v-if="icon" />
      <slot name="icon" v-else />
			{{ title }}
		</a>
	</router-link>
</template>

<script>
export default {
	name: 'SideMenuItem',
	props: {
		title: String,
		to: String,
		other1: String,
		icon: {
      type: String,
      default: null,
      optional: true
    }
	},
	emits: ['click']
}
</script>
